import {
  GET_PROFILE,
  SAVE_PROFILE,
  UPLOAD_PROFILE_PIC,
  GET_NOTIFICATIONS,
  GET_ALL_INTEGRATIONS,
  DISCONNECT_FROM_SLACK,
  ASSIGN_ADMIN,
  REMOVE_ADMIN,
  DISCONNECT_FROM_ZOOM,
  GET_FEATURE_FLAGS,
  GET_WORKSPACE_PLANS,
  UPGRADE_WORKSPACE_PLAN,
  CANCEL_SUBSCRIPTION,
  EXPORT_DATA,
  FEATURE_LOADED,
  CHANGE_PASSWORD,
  GOOGLE_URL,
  EMP_STATUS_CHANGE,
} from "./action-types";
import * as AccountService from "../../../services/AccountService";
import { successAction } from "../../../utilities/helpers";

export const getProfile = (data) => {
  return (dispatch) => {
    return AccountService.getProfile(data).then((response) => {
      return dispatch(successAction(response.data, GET_PROFILE));
    });
  };
};

export const getGoogleUrl = (data) => {
  return (dispatch) => {
    return AccountService.getGoogleUrl(data).then((response) => {
      return dispatch(successAction(response.data, GOOGLE_URL));
    });
  };
};

export const saveProfile = (data) => {
  return (dispatch) => {
    return AccountService.saveProfile(data).then((response) => {
      return dispatch(successAction(response.data, SAVE_PROFILE));
    });
  };
};

export const changePassword = (data) => {
  return (dispatch) => {
    return AccountService.changePassword(data).then((response) => {
      return dispatch(successAction(response.data, CHANGE_PASSWORD));
    });
  };
};

export const uploadProfilePic = (data) => {
  return (dispatch) => {
    return AccountService.uploadProfilePic(data, {
      "content-type": "multipart/form-data",
    }).then((response) => {
      return dispatch(successAction(response.data, UPLOAD_PROFILE_PIC));
    });
  };
};

export const getNotifications = (data) => {
  return (dispatch) => {
    return AccountService.getNotifications(data).then((response) => {
      let payload = response.data;
      payload.offset = data.get("offset");
      return dispatch(successAction(payload, GET_NOTIFICATIONS));
    });
  };
};

export const getAllIntegrations = (data) => {
  return (dispatch) => {
    return AccountService.getAllIntegrations(data).then((response) => {
      return dispatch(successAction(response.data, GET_ALL_INTEGRATIONS));
    });
  };
};

export const disconnectFromSlack = (data) => {
  return (dispatch) => {
    return AccountService.disconnectFromSlack(data).then((response) => {
      return dispatch(successAction(response.data, DISCONNECT_FROM_SLACK));
    });
  };
};

export const disconnectFromZoom = (data) => {
  return (dispatch) => {
    return AccountService.disconnectFromZoom(data).then((response) => {
      return dispatch(successAction(response.data, DISCONNECT_FROM_ZOOM));
    });
  };
};

export const empStatusChange = (data) => {
  return (dispatch) => {
    return AccountService.empStatusChange(data).then((response) => {
      return dispatch(successAction(response.data, EMP_STATUS_CHANGE));
    });
  };
};

export const assignAdmin = (data) => {
  return (dispatch) => {
    return AccountService.assignAdmin(data).then((response) => {
      return dispatch(successAction(response.data, ASSIGN_ADMIN));
    });
  };
};

export const removeAdmin = (data) => {
  return (dispatch) => {
    return AccountService.removeAdmin(data).then((response) => {
      return dispatch(successAction(response.data, REMOVE_ADMIN));
    });
  };
};

export const getFeatureFlags = (data) => {
  return (dispatch) => {
    dispatch(successAction({}, FEATURE_LOADED));
    return AccountService.getFeatureFlags(data).then((response) => {
      return dispatch(successAction(response.data, GET_FEATURE_FLAGS));
    });
  };
};

export const getWorkspacePlans = (data) => {
  return (dispatch) => {
    return AccountService.getWorkspacePlans(data).then((response) => {
      return dispatch(successAction(response.data, GET_WORKSPACE_PLANS));
    });
  };
};

export const upgradeWorkspacePlan = (data) => {
  return (dispatch) => {
    return AccountService.upgradeWorkspacePlan(data).then((response) => {
      return dispatch(successAction(response.data, UPGRADE_WORKSPACE_PLAN));
    });
  };
};

export const cancelSubscription = (data) => {
  return (dispatch) => {
    return AccountService.cancelSubscription(data).then((response) => {
      return dispatch(successAction(response.data, CANCEL_SUBSCRIPTION));
    });
  };
};

export const generateExportData = (data) => {
  return (dispatch) => {
    return AccountService.generateExportData(data).then((response) => {
      return dispatch(successAction(response.data, EXPORT_DATA));
    });
  };
};
