import { GET_DECISION_STATUS_COUNT, GET_DECISION_COUNT_BY_MONTH } from "./action-types";
import * as AnalyticsService from "../../../services/AnalyticsService";
import { successAction } from "../../../utilities/helpers";

export const getDecisionStatusCount = data => {
  return dispatch => {
    return AnalyticsService.getDecisionStatusCount(data).then(response => {
      return dispatch(successAction(response.data, GET_DECISION_STATUS_COUNT));
    });
  };
};

export const getDecisionCountByMonth = data => {
  return dispatch => {
    return AnalyticsService.getDecisionCountByMonth(data).then(response => {
      return dispatch(successAction(response.data, GET_DECISION_COUNT_BY_MONTH));
    });
  };
};
