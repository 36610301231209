import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Row,
  Button,
  Form,
  Col,
  FormGroup,
  Input,
  Label,
  Spinner,
} from "reactstrap";
import { Link } from "react-router-dom";
import ReeValidate from "ree-validate";
import { errorAlert, successAlert } from "../../web/store/alertActions";
import {
  getProfile,
  saveProfile,
  uploadProfilePic,
} from "../store/accountActions";
import { objectToQueryParameters } from "../../../utilities/helpers";
import { clientHost } from "../../../config/config";

class Profile extends Component {
  constructor(props) {
    super(props);
    this.timeout = null;
    this.validator = new ReeValidate({
      title: "",
      emailAddress: "required|email",
      phoneNumber: "numeric",
      fullName: "",
      displayName: "",
    });

    this.state = {
      form: {
        emailAddress: "",
        phoneNumber: "",
        fullName: "",
        displayName: "",
        title: "",
      },
      imagePreviewUrl: "",
      uploading: false,
      errors: this.validator.errors,
    };
  }

  componentDidMount() {
    if (this.props.token) {
      if (!this.props.profile.fullName) {
        this.loadProfile();
      } else {
        this.updateCurrentState(this.props);
      }
    } else {
      this.props.history.push("/");
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (props.profile.emailAddress) {
      this.updateCurrentState(props);
    }
  }

  loadProfile = () => {
    let data = objectToQueryParameters({
      workspaceId: this.props.workspaceId,
      employeeId: this.props.employeeId,
      token: this.props.token,
    });
    this.props.getProfile(data);
  };

  updateCurrentState = (props) => {
    this.setState({
      form: {
        emailAddress: props.profile.emailAddress || "",
        phoneNumber: props.profile.phoneNumber || "",
        fullName: props.profile.fullName || "",
        displayName: props.profile.displayName || "",
        title: props.profile.title || "",
      },
    });
  };

  handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    this.setState({
      form: { ...this.state.form, [name]: value },
    });
    this.validator.validate(name, value).then(() => {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.timeout = null;
        const { errors } = this.validator;
        this.setState({ errors });
      }, 1000);
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { form } = this.state;
    const { errors } = this.validator;
    this.validator.validateAll(form).then((success) => {
      if (success) {
        this.updateProfile();
      } else {
        this.setState({ errors });
      }
    });
  };

  updateProfile = () => {
    const payloadData = new FormData();
    payloadData.append("token", this.props.token);
    payloadData.append("employeeId", this.props.employeeId);
    payloadData.append("workspaceId", this.props.workspaceId);
    payloadData.append("emailAddress", this.state.form.emailAddress);
    payloadData.append("title", this.state.form.title);
    payloadData.append("phoneNumber", this.state.form.phoneNumber);
    payloadData.append("fullName", this.state.form.fullName);
    payloadData.append("displayName", this.state.form.displayName);
    this.props
      .saveProfile(payloadData)
      .then(() => {
        this.props.successAlert("Profile updated successfully.");
        this.loadProfile();
        this.props.history.push("/dashboard");
      })
      .catch((err) => {
        this.props.errorAlert("Something went wrong, please try again!");
      });
  };

  uploadProfilePic = (e) => {
    this.setState({
      uploading: true,
    });
    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        imagePreviewUrl: reader.result,
      });
    };
    reader.readAsDataURL(file);
    this.uploadNewPhoto(file, file.name);
  };

  uploadNewPhoto = (file, name) => {
    const payloadData = new FormData();
    payloadData.append("token", this.props.token);
    payloadData.append("employeeId", this.props.employeeId);
    payloadData.append("workspaceId", this.props.workspaceId);
    payloadData.append("image", file);
    payloadData.append("imageName", name);
    this.props
      .uploadProfilePic(payloadData)
      .then(() => {
        this.props.successAlert("Profile picture updated successfully.");
        this.setState({
          uploading: false,
        });
      })
      .catch((err) => {
        this.setState({
          uploading: false,
        });
        this.props.errorAlert("Something went wrong, please try again!");
      });
  };

  render() {
    if (!this.props.profile.emailAddress) {
      return (
        <main className="create-main">
          <div className="loader"></div>
        </main>
      );
    }

    let imgClasses = this.state.uploading ? "uploading-profile-img " : "";
    imgClasses +=
      this.state.imagePreviewUrl || this.props.profile.photoUrl
        ? " header-profile-image-cust image"
        : " header-profile-image-cust-default image";

    return (
      <main className="create-main">
        <br />
        <div className="create-decision container">
          <br />
          <h5 className="page-title">My Profile Settings</h5>
          <br />
          <Form onSubmit={this.handleSubmit} className="form-custom">
            <Row>
              <Col md={8}>
                <FormGroup>
                  <div className="profile-container">
                    <img
                      src={
                        this.state.imagePreviewUrl
                          ? this.state.imagePreviewUrl
                          : this.props.profile.photoUrl
                          ? this.props.profile.photoUrl
                          : "/assets/no-image.png"
                      }
                      className={imgClasses}
                      alt="user"
                      onClick={(e) => this.upload.click()}
                    />
                    {this.state.uploading && (
                      <Spinner className="upload-spin" size="md" />
                    )}
                  </div>
                  <input
                    style={{ display: "none" }}
                    accept="image/png, image/ico, image/jpeg"
                    ref={(ref) => (this.upload = ref)}
                    type="file"
                    onChange={this.uploadProfilePic}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <FormGroup>
                  <Label>Your Full Name</Label>
                  <Input
                    type="text"
                    name="fullName"
                    onChange={this.handleChange}
                    value={this.state.form.fullName}
                  />
                  {this.state.errors.has("fullName") && this.timeout === null && (
                    <label className="error" htmlFor="fullName">
                      {this.state.errors.first("fullName")}
                    </label>
                  )}
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label>Your Display Name</Label>
                  <Input
                    type="text"
                    name="displayName"
                    onChange={this.handleChange}
                    value={this.state.form.displayName}
                  />
                  {this.state.errors.has("displayName") &&
                    this.timeout === null && (
                      <label className="error" htmlFor="displayName">
                        {this.state.errors.first("displayName")}
                      </label>
                    )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <FormGroup>
                  <Label>Email Address</Label>
                  <Input
                    type="text"
                    name="emailAddress"
                    onChange={this.handleChange}
                    value={this.state.form.emailAddress}
                    readOnly
                  />
                  {this.state.errors.has("emailAddress") &&
                    this.timeout === null && (
                      <label className="error" htmlFor="emailAddress">
                        {this.state.errors.first("emailAddress")}
                      </label>
                    )}
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label>Phone Number</Label>
                  <Input
                    type="number"
                    name="phoneNumber"
                    onChange={this.handleChange}
                    value={this.state.form.phoneNumber}
                  />
                  {this.state.errors.has("phoneNumber") &&
                    this.timeout === null && (
                      <label className="error" htmlFor="phoneNumber">
                        {this.state.errors.first("phoneNumber")}
                      </label>
                    )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <FormGroup>
                  <Label>Title</Label>
                  <Input
                    type="text"
                    name="title"
                    onChange={this.handleChange}
                    value={this.state.form.title}
                  />
                  {this.state.errors.has("title") && this.timeout === null && (
                    <label id="name-error" className="error" htmlFor="title">
                      {this.state.errors.first("title")}
                    </label>
                  )}
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label>Workspace ID</Label>
                  <Input
                    type="text"
                    name="workspaceId"
                    value={this.props.workspaceId}
                    readOnly
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <FormGroup>
                  <Label>Workspace URL</Label>
                  <Input
                    type="text"
                    name="workspace"
                    value={this.props.workspaceName + "." + clientHost}
                    readOnly
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label>Workspace Name</Label>
                  <Input
                    type="text"
                    name="workspace"
                    value={this.props.originalWorkspaceName}
                    readOnly
                  />
                </FormGroup>
              </Col>
            </Row>
            <br />
            <Row>
              <Col md={6}></Col>
              <Col md={1}>
                <FormGroup>
                  <Button className="btn-custom btn-custom-create btn-custom-width">
                    Save
                  </Button>
                </FormGroup>
              </Col>
              <Col md={1}>
                <FormGroup>
                  <Link to={"/dashboard"}>
                    <Button className="btn-custom btn-custom-create btn-custom-width">
                      Close
                    </Button>
                  </Link>
                </FormGroup>
              </Col>
            </Row>
            <br />
          </Form>
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    workspaceId: state.auth.workspaceId,
    workspaceName: state.auth.workspaceName,
    employeeId: state.auth.employeeId,
    token: state.auth.token,
    profile: state.account.profile,
    originalWorkspaceName: state.auth.originalWorkspaceName,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProfile: (data) => {
      return dispatch(getProfile(data));
    },
    saveProfile: (data) => {
      return dispatch(saveProfile(data));
    },
    errorAlert: (data) => {
      return dispatch(errorAlert(data));
    },
    successAlert: (data) => {
      return dispatch(successAlert(data));
    },
    uploadProfilePic: (data) => {
      return dispatch(uploadProfilePic(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
