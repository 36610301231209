import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Navbar, Nav, NavItem, NavLink } from "reactstrap";

class Footer extends Component {
  render() {
    return (
      !this.props.isAuthenticated &&
      false && (
        <footer>
          {" "}
          <Row>
            <Col sm="3"></Col>
            <Col sm="6">
              <Navbar expand="md">
                <Nav className="ml-auto footer-custom" navbar>
                  <NavItem>
                    <NavLink href="#">About Us</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="#">Leadership</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="#">Blog</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="#">News</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="#">Media Kit</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="#">Careers</NavLink>
                  </NavItem>
                </Nav>
              </Navbar>
            </Col>
            <Col sm="3"></Col>
          </Row>
        </footer>
      )
    );
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.isAuthenticated
  };
};

export default connect(mapStateToProps, null)(Footer);
