import { postRequest } from "./RequestService";

export const loadDepartments = data => {
  return postRequest("getAllDepartmentsByWorkspaceId.php", data);
};

export const getDepartmentsAndEmployees = data => {
  return postRequest("getDepartmentsAndEmployees.php", data);
};

export const createDepartment = data => {
  return postRequest("createDepartment.php", data);
};

export const deleteDepartment = data => {
  return postRequest("deleteDepartment.php", data);
};

export const addEmployeesToDepartment = data => {
  return postRequest("addEmployeesToDepartment.php", data);
};

export const deleteEmployeesFromDepartment = data => {
  return postRequest("deleteEmployeesFromDepartment.php", data);
};
