import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Button,
  FormGroup,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Row,
  Col,
  Input,
  Spinner,
} from "reactstrap";
import ReeValidate from "ree-validate";
import {
  errorAlert,
  successAlert,
  warningAlert,
} from "../../../web/store/alertActions";
import { forgetPassword } from "../../store/loginActions";
import { websiteUrl } from "../../../../config/config";
import { forgotPasswordUserNotFound } from "../../../../constants";

class RegisterEmail extends Component {
  constructor(props) {
    super(props);

    this.validator = new ReeValidate({
      email: "required|email",
    });
    this.timeout = null;
    this.state = {
      formData: {
        email: "",
      },
      errors: this.validator.errors,
      isDisable: true,
      isSubmiting: false,
    };
  }

  handleChange = (e) => {
    this.setErrors();
    setTimeout(() => {
      this.setDisabled();
    }, 10);
    const name = e.target.name;
    const value = e.target.value;
    this.setState({
      formData: { ...this.state.formData, [name]: value },
    });
    this.validator.validate(name, value).then(() => {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.timeout = null;
        this.setErrors();
      }, 2000);
    });
  };

  setErrors = () => {
    const { errors } = this.validator;
    this.setState({ errors });
  };

  setDisabled = () => {
    this.setState({
      isDisable:
        this.state.errors.items.length > 0 || this.state.formData.email === "",
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { formData } = this.state;
    const { errors } = this.validator;
    this.validator.validateAll(formData).then((success) => {
      if (success) {
        this.setState({ isDisable: true, isSubmiting: true });
        const payloadData = new FormData();
        payloadData.append("emailAddress", this.state.formData.email);
        payloadData.append("workspaceId", this.props.workspaceId);
        this.props
          .forgetPassword(payloadData)
          .then(() => {
            let message =
              "Check your " +
              this.state.formData.email +
              " inbox for instructions from us on how to reset your password.";
            this.props.successAlert(message);
            this.props.history.push("/login");
          })
          .catch((err) => {
            this.setState({ isDisable: false, isSubmiting: false });
            if (parseInt(err.data.status) === forgotPasswordUserNotFound) {
              this.props.warningAlert(
                "Sorry this user does not exist in current workspace."
              );
            } else {
              this.props.errorAlert("Please enter a valid email address.");
            }
          });
      } else {
        this.setState({ errors });
      }
    });
  };

  render() {
    return (
      <main>
        <Row className="split-screen"></Row>
        <Row>
          <Col sm="4"></Col>
          <Col sm="4" className="pd-10">
            <Row>
              <Col sm="4"></Col>
              <Col sm="4">
                <a href={websiteUrl} alt="website">
                  <img
                    src={"/assets/consolve-icon.png"}
                    alt="workspace"
                    className="logo-img w-50"
                  />
                </a>
              </Col>
              <Col sm="4"></Col>
            </Row>
          </Col>
          <Col sm="4"></Col>
        </Row>
        <br />
        <Row>
          <Col sm="4"></Col>
          <Col sm="4" className="pd-10">
            <Card className="color-bg">
              <CardBody>
                <CardTitle>Password Reset</CardTitle>
                <CardSubtitle>
                  To reset your password, enter the email address you use to
                  sign in to workspace.
                </CardSubtitle>
                <div className="main-card">
                  <form onSubmit={this.handleSubmit}>
                    <FormGroup>
                      <Input
                        type="email"
                        name="email"
                        id="email"
                        onChange={this.handleChange}
                        placeholder="you@example.com"
                      />
                    </FormGroup>
                    <Button
                      className="btn-custom"
                      color="primary"
                      size="lg"
                      block
                      disabled={this.state.isDisable}
                    >
                      Get Reset Link{" "}
                      {this.state.isSubmiting && <Spinner size="sm" />}
                    </Button>
                  </form>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col sm="4"></Col>
        </Row>
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    workspaceId: state.auth.workspaceId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    forgetPassword: (data) => {
      return dispatch(forgetPassword(data));
    },
    errorAlert: (data) => {
      return dispatch(errorAlert(data));
    },
    successAlert: (data) => {
      return dispatch(successAlert(data));
    },
    warningAlert: (data) => {
      return dispatch(warningAlert(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterEmail);
