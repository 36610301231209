import {
  REGISTER_EMAIL,
  VALIDATE_VERIFICATION_CODE,
  CREATE_WORKSPACE,
  INVITE_MEMBERS_FOR_WORKSPACE,
  SET_DATA_TO_LOCALSTORAGE,
} from "./action-types";
import * as SignupService from "../../../services/SignupService";
import { successAction } from "../../../utilities/helpers";

export const registerEmail = (data) => {
  return (dispatch) => {
    return SignupService.registerEmail(data).then((response) => {
      let payload = response.data;
      payload.email = data.get("emailAddress");
      return dispatch(successAction(payload, REGISTER_EMAIL));
    });
  };
};

export const validateVerificationCode = (data) => {
  return (dispatch) => {
    return SignupService.validateVerificationCode(data).then((response) => {
      return dispatch(successAction(response.data, VALIDATE_VERIFICATION_CODE));
    });
  };
};

export const createWorkspace = (data) => {
  return (dispatch) => {
    return SignupService.createWorkspace(data).then((response) => {
      return dispatch(successAction(response.data, CREATE_WORKSPACE));
    });
  };
};

export const inviteMembersForWorkspace = (data) => {
  return (dispatch) => {
    return SignupService.inviteMembersForWorkspace(data).then((response) => {
      return dispatch(
        successAction(response.data, INVITE_MEMBERS_FOR_WORKSPACE)
      );
    });
  };
};

export const setDataToLocalstorage = (data) => {
  Object.keys(data).map((key) => {
    return localStorage.setItem(key, data[key]);
  });
  return (dispatch) => {
    return dispatch(successAction(data, SET_DATA_TO_LOCALSTORAGE));
  };
};
