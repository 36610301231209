import axios from "axios";
import { apiHost } from "../config/config";
import { isTokenExpire, isNotAccessible } from "../utilities/helpers";

delete axios.defaults.headers.common["Accept"];

export const getRequest = api => {
  return new Promise((resolve, reject) => {
    return axios
      .get(apiHost + api)
      .then(resp => {
        if (resp.data.status) {
          isTokenExpire(resp.data.status.toString());
          isNotAccessible(+resp.data.status);
        } else {
          reject(resp);
        }
        if (resp.data.status.toString() !== "1") {
          reject(resp);
        } else {
          resolve(resp);
        }
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const postRequest = (api, data = {}, header = {}) => {
  return new Promise((resolve, reject) => {
    return axios
      .post(apiHost + api, data, header)
      .then(resp => {
        if (resp.data.status) {
          isTokenExpire(resp.data.status.toString());
          isNotAccessible(+resp.data.status);
        } else {
          reject(resp);
        }
        if (resp.data.status.toString() !== "1") {
          reject(resp);
        } else {
          resolve(resp);
        }
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const putRequest = (api, data = {}) => {
  return new Promise((resolve, reject) => {
    axios
      .put(apiHost + api, data)
      .then(resp => {
        if (resp.data.status) {
          isTokenExpire(resp.data.status.toString());
          isNotAccessible(+resp.data.status);
        } else {
          reject(resp);
        }
        if (resp.data.status.toString() !== "1") {
          reject(resp);
        } else {
          resolve(resp);
        }
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const deleteRequest = api => {
  return new Promise((resolve, reject) => {
    axios
      .delete(apiHost + api)
      .then(resp => {
        if (resp.data.status) {
          isTokenExpire(resp.data.status.toString());
          isNotAccessible(+resp.data.status);
        } else {
          reject(resp);
        }
        if (resp.data.status.toString() !== "1") {
          reject(resp);
        } else {
          resolve(resp);
        }
      })
      .catch(err => {
        reject(err);
      });
  });
};
