import {
  LOAD_DECISIONS_COUNT,
  LOAD_DECISIONS,
  SEARCH_DECISIONS,
  CREATE_DECISION_TEMPLATE,
  GET_DECISION_DETAILS,
  GET_COORDINATOR_DECISION_DETAILS,
  UPDATE_DECISION_DETAILS_MEMBER,
  UPDATE_DECISION_DETAILS_COORDINATOR,
  GET_DECISION,
  UPDATE_DECISION_TEMPLATE,
  GET_COMMENTS_BY_ROW,
  ADD_COMMENT,
  DELETE_COMMENT,
  ADD_DECISION_ROW,
  ADD_VOTE,
  UPDATE_DECISION_ROW,
  DELTE_DECISION_ROW,
  CREATE_ZOOM_MEETING,
  DELETE_DECISION_TEMPLATE,
  UPDATE_DECISION_DETAILS_APPROVER,
  UPDATE_ONBOARDING,
  REJECT_DECISION,
  SWIPE_ROW_DECISION,
} from "./action-types";
import * as DecisionService from "../../../services/DecisionService";
import { successAction } from "../../../utilities/helpers";

export const swipeRowDecision = (data) => {
  return (dispatch) => {
    return DecisionService.swipeRowDecision(data).then((response) => {
      let payload = response.data;
      return dispatch(successAction(payload, SWIPE_ROW_DECISION));
    });
  };
};

export const loadDecisions = (data) => {
  return (dispatch) => {
    return DecisionService.loadDecisions(data).then((response) => {
      let payload = response.data;
      payload.sorted = data.get("orderBy") ? true : false;
      payload.offset = data.get("offset");
      return dispatch(successAction(payload, LOAD_DECISIONS));
    });
  };
};

export const loadDecisionsCount = (data) => {
  return (dispatch) => {
    return DecisionService.loadDecisionsCount(data).then((response) => {
      let payload = response.data;
      return dispatch(successAction(payload, LOAD_DECISIONS_COUNT));
    });
  };
};

export const searchDecisions = (data) => {
  return (dispatch) => {
    return DecisionService.searchDecisions(data).then((response) => {
      return dispatch(successAction(response.data, SEARCH_DECISIONS));
    });
  };
};

export const updateOnboarding = (data) => {
  return (dispatch) => {
    return DecisionService.updateOnboarding(data).then((response) => {
      return dispatch(successAction(response.data, UPDATE_ONBOARDING));
    });
  };
};

export const loadSearchDecisions = (data) => {
  return (dispatch) => {
    return DecisionService.searchDecisions(data).then((response) => {
      return dispatch(successAction(response.data, LOAD_DECISIONS));
    });
  };
};

export const createDecisionTemplate = (data) => {
  return (dispatch) => {
    return DecisionService.createDecisionTemplate(data).then((response) => {
      return dispatch(successAction(response.data, CREATE_DECISION_TEMPLATE));
    });
  };
};

export const getDecision = (data) => {
  return (dispatch) => {
    return DecisionService.getDecision(data).then((response) => {
      let payload = response.data;
      payload.decisionId = data.get("decisionId");
      return dispatch(successAction(payload, GET_DECISION));
    });
  };
};

export const getDecisionDetails = (data) => {
  return (dispatch) => {
    return DecisionService.getDecisionDetails(data).then((response) => {
      let payload = response.data;
      payload.decisionId = data.get("decisionId");
      return dispatch(successAction(payload, GET_DECISION_DETAILS));
    });
  };
};

export const updateDecisionDetailsMember = (data) => {
  return (dispatch) => {
    return DecisionService.updateDecisionDetailsMember(data).then(
      (response) => {
        return dispatch(
          successAction(response.data, UPDATE_DECISION_DETAILS_MEMBER)
        );
      }
    );
  };
};

export const getCoordinatorDecisionDetails = (data) => {
  return (dispatch) => {
    return DecisionService.getCoordinatorDecisionDetails(data).then(
      (response) => {
        let payload = response.data;
        payload.decisionId = data.get("decisionId");
        return dispatch(
          successAction(payload, GET_COORDINATOR_DECISION_DETAILS)
        );
      }
    );
  };
};

export const updateDecisionDetailsCoordinator = (data) => {
  return (dispatch) => {
    return DecisionService.updateDecisionDetailsCoordinator(data).then(
      (response) => {
        return dispatch(
          successAction(response.data, UPDATE_DECISION_DETAILS_COORDINATOR)
        );
      }
    );
  };
};

export const updateDecisionDetailsApprover = (data) => {
  return (dispatch) => {
    return DecisionService.updateDecisionDetailsApprover(data).then(
      (response) => {
        return dispatch(
          successAction(response.data, UPDATE_DECISION_DETAILS_APPROVER)
        );
      }
    );
  };
};

export const updateDecisionTemplate = (data) => {
  return (dispatch) => {
    return DecisionService.updateDecisionTemplate(data).then((response) => {
      return dispatch(successAction(response.data, UPDATE_DECISION_TEMPLATE));
    });
  };
};

export const getCommentsByRow = (data) => {
  return (dispatch) => {
    return DecisionService.getCommentsByRow(data).then((response) => {
      let payload = response.data;
      payload.decisionId = data.get("decisionId");
      return dispatch(successAction(payload, GET_COMMENTS_BY_ROW));
    });
  };
};

export const addComment = (data) => {
  return (dispatch) => {
    return DecisionService.addComment(data).then((response) => {
      return dispatch(successAction(response.data, ADD_COMMENT));
    });
  };
};

export const deleteComment = (data) => {
  return (dispatch) => {
    return DecisionService.deleteComment(data).then((response) => {
      return dispatch(successAction(response.data, DELETE_COMMENT));
    });
  };
};

export const addDecisionRow = (data) => {
  return (dispatch) => {
    return DecisionService.addDecisionRow(data).then((response) => {
      return dispatch(successAction(response.data, ADD_DECISION_ROW));
    });
  };
};

export const addVote = (data) => {
  return (dispatch) => {
    return DecisionService.addVote(data).then((response) => {
      return dispatch(successAction(response.data, ADD_VOTE));
    });
  };
};

export const updateDecisionRow = (data) => {
  return (dispatch) => {
    return DecisionService.updateDecisionRow(data).then((response) => {
      return dispatch(successAction(response.data, UPDATE_DECISION_ROW));
    });
  };
};

export const deleteDecisionRow = (data) => {
  return (dispatch) => {
    return DecisionService.deleteDecisionRow(data).then((response) => {
      return dispatch(successAction(response.data, DELTE_DECISION_ROW));
    });
  };
};

export const createZoomMeeting = (data) => {
  return (dispatch) => {
    return DecisionService.createZoomMeeting(data).then((response) => {
      return dispatch(successAction(response.data, CREATE_ZOOM_MEETING));
    });
  };
};

export const deleteDecisionTemplate = (data) => {
  return (dispatch) => {
    return DecisionService.deleteDecisionTemplate(data).then((response) => {
      let payload = response.data;
      payload.decisionId = data.get("decisionId");
      return dispatch(successAction(payload, DELETE_DECISION_TEMPLATE));
    });
  };
};

export const rejectDecision = (data) => {
  return (dispatch) => {
    return DecisionService.rejectDecision(data).then((response) => {
      return dispatch(successAction(response.data, REJECT_DECISION));
    });
  };
};
