import React, { Component } from "react";
import { connect } from "react-redux";
import ReeValidate from "ree-validate";
import {
  Col,
  Table,
  Button,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
} from "reactstrap";
import SelectRow from "../../../../common/select";
import {
  addEmployeesToDepartment,
  deleteEmployeesFromDepartment,
} from "../../../departments/store/departmentActions";
import { successAlert, errorAlert } from "../../../web/store/alertActions";
import { findElementByKey } from "../../../../utilities/helpers";
import { contextLabel, defaultDepartment } from "../../../../constants";
import AvatarLabel from "../../../../common/avatar-label";

class Employee extends Component {
  constructor(props) {
    super(props);

    this.validator = new ReeValidate({
      employees: "required",
    });

    this.state = {
      addModal: false,
      employees: [],
      form: {
        employees: "",
      },
      errors: this.validator.errors,
      allEmployess: this.props.employees,
    };
  }

  addToggle = () => {
    this.setState({
      addModal: !this.state.addModal,
      employees: [],
      form: {
        employees: "",
      },
    });
    this.validator.reset();

    const employees = this.props.employees.filter(
      ({ employeeId: id1 }) =>
        !this.props.department.employees.some(
          ({ employeeId: id2 }) => id2 === id1
        )
    );
    this.setState({
      allEmployess: employees,
    });
  };

  handleChange = async (name, value, valueKey) => {
    let values = [];
    if (value) {
      await value.map((item) => {
        return values.push(item[valueKey]);
      });
    }
    await this.setState({
      form: { ...this.state.form, [name]: values },
      [name]: value,
    });
    this.validator.validate(name, value).then(() => {
      const { errors } = this.validator;
      this.setState({ errors });
    });
  };

  handleSubmit = () => {
    const { form } = this.state;
    const { errors } = this.validator;
    this.validator.validateAll(form).then((success) => {
      if (success) {
        const payloadData = new FormData();
        payloadData.append("token", this.props.token);
        payloadData.append("employeeId", this.props.employeeId);
        payloadData.append("workspaceId", this.props.workspaceId);
        payloadData.append("departmentId", this.props.department.departmentId);
        payloadData.append(
          "employees",
          JSON.stringify(this.state.form.employees)
        );
        this.props
          .addEmployeesToDepartment(payloadData)
          .then((success) => {
            this.props.successAlert("Employees added successfully.");
            this.addToggle();
            this.props.getDepartmentsAndEmployees();
          })
          .catch((e) => {
            this.props.errorAlert("Something went wrong, please try again!");
          });
      } else {
        this.setState({ errors });
      }
    });
  };

  removeEmployeesFromDepartment = (id) => {
    const payloadData = new FormData();
    payloadData.append("token", this.props.token);
    payloadData.append("employeeId", this.props.employeeId);
    payloadData.append("workspaceId", this.props.workspaceId);
    payloadData.append("departmentId", this.props.department.departmentId);
    let employees = [];
    employees.push(id);
    payloadData.append("employees", JSON.stringify(employees));
    this.props
      .deleteEmployeesFromDepartment(payloadData)
      .then((success) => {
        this.props.successAlert("Employee removed from department.");
        this.props.getDepartmentsAndEmployees();
      })
      .catch((e) => {
        this.props.errorAlert("Something went wrong, please try again!");
      });
  };

  render() {
    return (
      <Col md={6}>
        <h6 className="page-sub-title">Employees</h6>
        <div className="settings-div">
          <Table className="table-custom table-custom-new" responsive>
            <tbody>
              {this.props.department &&
                this.props.department.employees.map((employee) => {
                  return (
                    <tr key={employee.employeeId}>
                      <td>
                        <span
                          className={
                            parseInt(employee.isActive) === 1 ? "" : "iEmp"
                          }
                        >
                          <AvatarLabel
                            label={employee.displayName}
                            avatar={employee.photoUrl}
                          />
                        </span>
                        {this.props.department.name !== defaultDepartment && (
                          <span
                            className="btn-cust-close cursor-pointer float-right"
                            onClick={() =>
                              this.removeEmployeesFromDepartment(
                                employee.employeeId
                              )
                            }
                          >
                            <img src={"/assets/cross.svg"} alt="X" />
                          </span>
                        )}

                        <span
                          className="cursor-pointer float-right"
                          onClick={(e) =>
                            this.props.onContextMenuChange(
                              e,
                              employee.employeeId,
                              employee.isAdmin,
                              employee.isActive,
                              false
                            )
                          }
                        >
                          {contextLabel}
                        </span>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
          <br />
          <Row>
            <Col md={4}></Col>
            <Col md={5}>
              <Button
                onClick={this.addToggle}
                className="btn-custom btn-custom-create btn-custom-width"
              >
                Add Employees
              </Button>
              <Col md={3}></Col>
            </Col>
          </Row>
          <br />
        </div>
        <Modal isOpen={this.state.addModal} toggle={this.addToggle}>
          <ModalHeader toggle={this.addToggle}>
            Add employees to Department
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label>Employees</Label>
              <SelectRow
                name="employees"
                items={this.state.allEmployess}
                nameField={"displayName"}
                valueField={"employeeId"}
                multi={true}
                avatar={true}
                value={this.state.employees}
                onChange={this.handleChange}
              />
              {this.state.errors.has("employees") && (
                <label id="name-error" className="error" htmlFor="employees">
                  {this.state.errors.first("employees")}
                </label>
              )}
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              className="btn-custom btn-custom-create"
              onClick={this.handleSubmit}
            >
              Add
            </Button>{" "}
            <Button
              className="btn-custom btn-custom-create"
              onClick={this.addToggle}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </Col>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    token: state.auth.token,
    employeeId: state.auth.employeeId,
    workspaceId: state.auth.workspaceId,
    employees: state.employees,
    department: findElementByKey(
      state.departments,
      "departmentId",
      props.departmentId
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addEmployeesToDepartment: (data) => {
      return dispatch(addEmployeesToDepartment(data));
    },
    deleteEmployeesFromDepartment: (data) => {
      return dispatch(deleteEmployeesFromDepartment(data));
    },
    successAlert: (data) => {
      return dispatch(successAlert(data));
    },
    errorAlert: (data) => {
      return dispatch(errorAlert(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Employee);
