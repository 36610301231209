import React from "react";
import { connect } from "react-redux";

const LoaderComponent = props => {
  return (
    <main>
      <div className="loader"></div>
    </main>
  );
};

const mapStateToProps = state => {
  return {
    loader: state.loader
  };
};

export default connect(mapStateToProps)(LoaderComponent);
