import React from "react";
import { connect } from "react-redux";
import Header from "./common/header/index";
import Footer from "./common/footer/index";
import Routes from "./routes/index.jsx";
import AlertComponent from "./common/alert";
import LoaderComponent from "./common/loader";

const App = props => {
  return (
    <div className="app">
      <AlertComponent />
      <Header />
      {props.loader ? <LoaderComponent /> : <Routes />}
      <Footer />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    loader: state.loader
  };
};

export default connect(
  mapStateToProps,
  null
)(App);
