import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, CardBody, CardTitle, CardSubtitle, Row, Col } from "reactstrap";
import OtpInput from "react-otp-input";
import { validateVerificationCode } from "../../store/signupActions";
import { errorAlert } from "../../../web/store/alertActions";
import { websiteUrl } from "../../../../config/config";

class ValidateVerificationCode extends Component {
  constructor(props) {
    super(props);

    this.state = {
      code: "",
      submited: false,
    };
  }

  componentDidMount() {
    if (!this.props.employeeId || this.props.email === "") {
      this.props.history.push("/register-email");
    }
    if (this.props.isAuthenticated) {
      this.props.history.push("/dashboard");
    }
  }

  handleChange = (code) => {
    const re = /^[0-9\b]+$/;
    if (code.length && !re.test(code[code.length - 1])) {
      return false;
    }
    this.setState({ code: code });
    if (code.toString().length === 6 && !this.state.submited) {
      this.setState({ submited: true });
      const payloadData = new FormData();
      payloadData.append("employeeId", this.props.employeeId);
      payloadData.append("verificationCode", code);
      this.props
        .validateVerificationCode(payloadData)
        .then(() => {
          this.setState({ submited: false });
          this.props.history.push("/create-workspace");
        })
        .catch((err) => {
          this.setState({ submited: false });
          this.props.errorAlert("Please enter valid code!");
        });
    }
  };

  render() {
    return (
      <main>
        <Row className="split-screen"></Row>
        <Row>
          <Col sm="4"></Col>
          <Col sm="4" className="pd-10">
            <Row>
              <Col sm="4"></Col>
              <Col sm="4">
                <a href={websiteUrl} alt="website">
                  <img
                    src={"/assets/consolve-icon.png"}
                    alt="workspace"
                    className="logo-img w-50"
                  />
                </a>
              </Col>
              <Col sm="4"></Col>
            </Row>
          </Col>
          <Col sm="4"></Col>
        </Row>
        <br />
        <Row>
          <Col sm="4"></Col>
          <Col sm="4" className="pd-10">
            <Card className="color-bg">
              <CardBody>
                <CardTitle>Check your email</CardTitle>
                <CardSubtitle>
                  We've sent a 6-digit confirmation code to {this.props.email}.{" "}
                  <br /> It will expire shortly, so enter it soon.
                </CardSubtitle>
                <div className="main-card">
                  <form>
                    <OtpInput
                      name="code"
                      containerStyle="row justify-content-center"
                      inputStyle="otp-input col-lg"
                      onChange={this.handleChange}
                      numInputs={6}
                      value={this.state.code}
                      isInputNum={false}
                      separator={<span>&nbsp;&nbsp;</span>}
                    />
                  </form>
                  <br />
                  <img
                    className="image-code w-50"
                    alt="workspace"
                    src={"/assets/signup_3.svg"}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col sm="4"></Col>
        </Row>
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    employeeId: state.auth.employeeId,
    email: state.auth.email,
    isAuthenticated: state.auth.isAuthenticated,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    validateVerificationCode: (data) => {
      return dispatch(validateVerificationCode(data));
    },
    errorAlert: (data) => {
      return dispatch(errorAlert(data));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ValidateVerificationCode);
