import React from "react";
import { Card, CardBody, CardTitle, CardSubtitle, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { websiteUrl } from "../../../../config/config";

function SignupStart(props) {
  return (
    <main>
      <Row className="split-screen"></Row>
      <Row>
        <Col sm="4"></Col>
        <Col sm="4" className="pd-10">
          <Row>
            <Col sm="4"></Col>
            <Col sm="4">
              <a href={websiteUrl} alt="website">
                <img
                  src={"/assets/consolve-icon.png"}
                  alt="workspace"
                  className="logo-img w-50"
                />
              </a>
            </Col>
            <Col sm="4"></Col>
          </Row>
        </Col>
        <Col sm="4"></Col>
      </Row>
      <br />
      <Row>
        <Col sm="4"></Col>
        <Col sm="4" className="pd-10">
          <Card className="color-bg">
            <CardBody>
              <CardTitle>Start with a Consolve Workspace</CardTitle>
              <CardSubtitle>
                {" "}
                In Consolve, everything happens in a Workspace. A Workspace can
                be a teamor Department or a Company.
              </CardSubtitle>
              <div className="main-card">
                <hr />
                <Link to={"/register-email"} className="decoration-none">
                  <div className="signup1">
                    <div className="first-icon">
                      <img
                        alt="arrow"
                        className="w-100"
                        src={"/assets/plus-thin.svg"}
                      />{" "}
                    </div>
                    <span>Create a Workspace</span>{" "}
                    <div className="second-icon">
                      <img
                        alt="arrow"
                        className="w-100"
                        src={"/assets/arrow-right-2.svg"}
                      />
                    </div>
                  </div>
                </Link>
                <hr />
                <Link to={"/find-workspace"} className="decoration-none">
                  <div className="signup1">
                    <div className="first-icon">
                      <img
                        className="search-img w-100"
                        alt="arrow"
                        src={"/assets/search-thin.svg"}
                      />{" "}
                    </div>
                    <span>
                      Find a Consolve Workspace
                      <br />
                      Join or Sign In an existing workspace
                    </span>{" "}
                    <div className="second-icon">
                      <img
                        className="arrow-img2 w-100"
                        alt="arrow"
                        src={"/assets/arrow-right-2.svg"}
                      />
                    </div>
                  </div>
                </Link>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col sm="4"></Col>
      </Row>
      <br />
    </main>
  );
}
export default SignupStart;
