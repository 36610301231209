import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Card,
  CardText,
  CardBody,
  CardHeader,
  Input
} from "reactstrap";
import { errorAlert, successAlert } from "../../../web/store/alertActions";
import {
  getWorkspacePlans,
  upgradeWorkspacePlan
} from "../../store/accountActions";
import { StripeProvider, Elements } from "react-stripe-elements";
import CardForm from "./Card";
import { loadEmployees } from "../../../employees/store/employeeActions";
import { findElementByKey } from "../../../../utilities/helpers";
import { stripeApiKey } from "../../../../config/config";

class Plans extends Component {
  constructor(props) {
    super(props);

    this.state = {
      member: this.props.employees.length,
      token: "",
      payable: true,
      loading: true
    };
  }

  componentDidMount() {
    if (!this.props.workspaceId) {
      this.props.history.push("/");
    }
    if (!this.props.plans.length) {
      this.loadPlans();
    }
    if (!this.props.employees.length) {
      this.loadEmployees();
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (props.employees.length) {
      this.setState({ member: props.employees.length });
    }
  }

  handleChange = async e => {
    const re = /^[0-9\b]+$/;
    let member = e.target.value;
    if (member.length && !re.test(member[member.length - 1])) {
      return false;
    }
    await this.setState({ member });
    if (this.state.member < this.props.employees.length) {
      this.setState({ payable: false });
    } else {
      this.setState({ payable: true });
    }
  };

  loadPlans = () => {
    const payloadData = new FormData();
    payloadData.append("token", this.props.token);
    payloadData.append("employeeId", this.props.employeeId);
    payloadData.append("workspaceId", this.props.workspaceId);
    this.props.getWorkspacePlans(payloadData);
  };

  loadEmployees = () => {
    const payloadData = new FormData();
    payloadData.append("token", this.props.token);
    payloadData.append("employeeId", this.props.employeeId);
    payloadData.append("workspaceId", this.props.workspaceId);
    this.props.loadEmployees(payloadData);
  };

  handleSubmit = token => {
    if (this.state.member < this.props.employees.length) {
      this.setState({ loading: false });
      return true;
    }
    const payloadData = new FormData();
    payloadData.append("token", this.props.token);
    payloadData.append("employeeId", this.props.employeeId);
    payloadData.append("workspaceId", this.props.workspaceId);
    payloadData.append("stripeToken", token);
    payloadData.append("totalEmployees", this.state.member);
    let total = this.state.member * parseInt(this.props.plan.monthlyPrice);
    if (this.state.member < this.props.employees.length) {
      total =
        this.props.employees.length * parseInt(this.props.plan.monthlyPrice);
    }
    payloadData.append("monthlyTotalPrice", total);
    payloadData.append("planType", this.props.plan.planType);

    this.props
      .upgradeWorkspacePlan(payloadData)
      .then(() => {
        this.props.successAlert("Plan upgraded successfully.");
        this.props.history.push("/dashboard");
        window.location.reload();
      })
      .catch(() => {
        this.setState({ loading: false });
        this.props.errorAlert("Something went wrong, please try again!");
      });
  };

  render() {
    if (!this.props.plans.length || !this.props.employees.length) {
      return (
        <main className="create-main">
          <div className="loader"></div>
        </main>
      );
    }
    let total = this.state.member * parseInt(this.props.plan.monthlyPrice);
    if (this.state.member < this.props.employees.length) {
      total =
        this.props.employees.length * parseInt(this.props.plan.monthlyPrice);
    }

    return (
      <main className="create-main">
        <br />
        <div className="create-decision container">
          <br />
          <div className="decision-detail-heading">
            <h5 className="page-title">
              Upgrade Plan to {this.props.plan.plan}
            </h5>
          </div>
          <br />
          <Row>
            <Col md={1}></Col>
            <Col md={5}>
              <StripeProvider apiKey={stripeApiKey}>
                <Elements>
                  <CardForm
                    payable={this.state.payable}
                    loading={this.state.loading}
                    handleSubmit={this.handleSubmit}
                  />
                </Elements>
              </StripeProvider>
            </Col>
            <Col md={5}>
              <br />
              <Card>
                <CardHeader className="page-title">Consolve Premium</CardHeader>
                <CardBody>
                  <CardText>
                    <span className="payment-card">
                      ${this.props.plan.monthlyPrice} USD x &nbsp;{" "}
                      <Input
                        type="number"
                        onChange={this.handleChange}
                        value={this.state.member}
                        className="th-lg-3 member-count-input"
                      />
                      &nbsp; active members x 1 month
                    </span>
                    <span className="float-right">${total} USD</span>
                  </CardText>
                  {this.state.member < this.props.employees.length && (
                    <span className="error">
                      Please enter more than {this.props.employees.length}{" "}
                      members
                    </span>
                  )}
                  <hr />
                  <CardText className="payment-card-total">
                    <span>Due today</span>
                    <span className="float-right">${total} USD</span>
                  </CardText>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <br />
        </div>
        <br />
      </main>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    token: state.auth.token,
    employeeId: state.auth.employeeId,
    workspaceId: state.auth.workspaceId,
    employees: state.employees,
    plans: state.account.plans,
    plan: findElementByKey(
      state.account.plans,
      "planType",
      ownProps.match.params.id
    )
  };
};

const mapDispatchToProps = dispatch => {
  return {
    successAlert: data => {
      return dispatch(successAlert(data));
    },
    errorAlert: data => {
      return dispatch(errorAlert(data));
    },
    getWorkspacePlans: data => {
      return dispatch(getWorkspacePlans(data));
    },
    loadEmployees: data => {
      return dispatch(loadEmployees(data));
    },
    upgradeWorkspacePlan: data => {
      return dispatch(upgradeWorkspacePlan(data));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Plans);
