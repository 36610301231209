import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Button,
  FormGroup,
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Input,
  Spinner,
} from "reactstrap";
import ReeValidate from "ree-validate";
import { createWorkspace } from "../../store/signupActions";
import { successAlert, errorAlert } from "../../../web/store/alertActions";
import { websiteUrl } from "../../../../config/config";

class CreateWorkspace extends Component {
  constructor(props) {
    super(props);

    this.validator = new ReeValidate({
      name: "required",
    });

    this.state = {
      isSubmiting: false,
      formData: {
        name: "",
      },
      errors: this.validator.errors,
      isDisable: true,
    };
  }

  componentDidMount() {
    if (!this.props.employeeId) {
      this.props.history.push("/register-email");
    }
    if (this.props.isAuthenticated) {
      this.props.history.push("/dashboard");
    }
  }

  handleChange = (e) => {
    this.setErrors();
    setTimeout(() => {
      this.setDisabled();
    }, 10);
    const name = e.target.name;
    const value = e.target.value;
    this.setState({
      formData: { ...this.state.formData, [name]: value },
    });
    this.validator.validate(name, value).then(() => {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.timeout = null;
        this.setErrors();
      }, 1000);
    });
  };

  setErrors = () => {
    const { errors } = this.validator;
    this.setState({ errors });
  };

  setDisabled = () => {
    this.setState({ isDisable: this.state.errors.items.length > 0 });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { formData } = this.state;
    const { errors } = this.validator;

    this.validator.validateAll(formData).then((success) => {
      if (success) {
        this.setState({ isSubmiting: true });
        const payloadData = new FormData();
        payloadData.append("name", this.state.formData.name);
        payloadData.append("employeeId", this.props.employeeId);
        this.props
          .createWorkspace(payloadData)
          .then(() => {
            this.props.history.push("/invite-members-for-workspace");
          })
          .catch((err) => {
            this.setState({ isSubmiting: false });
            this.props.errorAlert("Something went wrong, please try again!");
          });
      } else {
        this.setState({ errors });
      }
    });
  };

  render() {
    return (
      <main>
        <Row className="split-screen"></Row>
        <Row>
          <Col sm="4"></Col>
          <Col sm="4" className="pd-10">
            <Row>
              <Col sm="4"></Col>
              <Col sm="4">
                <a href={websiteUrl} alt="website">
                  <img
                    src={"/assets/consolve-icon.png"}
                    alt="workspace"
                    className="logo-img w-50"
                  />
                </a>
              </Col>
              <Col sm="4"></Col>
            </Row>
          </Col>
          <Col sm="4"></Col>
        </Row>
        <br />
        <Row>
          <Col sm="4"></Col>
          <Col sm="4" className="pd-10">
            <Card className="color-bg">
              <CardBody>
                <CardTitle>
                  What's the name of your company or Group or Team or
                  Department?
                </CardTitle>
                <div className="main-card">
                  <form onSubmit={this.handleSubmit}>
                    <FormGroup>
                      <Input
                        type="text"
                        name="name"
                        id="name"
                        onChange={this.handleChange}
                        placeholder="Workspace name"
                      />
                    </FormGroup>
                    <Button
                      className="btn-custom"
                      color="primary"
                      size="lg"
                      block
                      disabled={this.state.isDisable}
                    >
                      Next {this.state.isSubmiting && <Spinner size="sm" />}
                    </Button>
                    {this.state.errors.has("name") && this.timeout === null && (
                      <label id="name-error" className="error" htmlFor="name">
                        {this.state.errors.first("name")}
                      </label>
                    )}
                  </form>
                  <br />
                  <img
                    alt="workspace"
                    src={"/assets/signup_4.svg"}
                    className="w-50"
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col sm="4"></Col>
        </Row>
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    employeeId: state.auth.employeeId,
    isAuthenticated: state.auth.isAuthenticated,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createWorkspace: (data) => {
      return dispatch(createWorkspace(data));
    },
    successAlert: (data) => {
      return dispatch(successAlert(data));
    },
    errorAlert: (data) => {
      return dispatch(errorAlert(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateWorkspace);
