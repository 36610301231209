import { postRequest } from "./RequestService";

export const swipeRowDecision = (data) => {
  return postRequest("swipeRowDecision.php", data);
};

export const loadDecisions = (data) => {
  return postRequest("getDecisionsByWorkspaceId.php", data);
};

export const loadDecisionsCount = (data) => {
  return postRequest("getDecisionscountByWorkspaceId.php", data);
};

export const searchDecisions = (data) => {
  return postRequest("searchDecisions.php", data);
};

export const updateOnboarding = (data) => {
  return postRequest("updateOnboarding.php", data);
};

export const createDecisionTemplate = (data) => {
  return postRequest("createDecisionTemplate.php", data);
};

export const getDecisionDetails = (data) => {
  return postRequest("getDecisionDetails.php", data);
};

export const getDecision = (data) => {
  return postRequest("getDecisionTemplate.php", data);
};

export const updateDecisionDetailsMember = (data) => {
  return postRequest("updateDecisionDetailsMember.php", data);
};

export const getCoordinatorDecisionDetails = (data) => {
  return postRequest("getCoordinatorDecisionDetails.php", data);
};

export const updateDecisionDetailsCoordinator = (data) => {
  return postRequest("updateDecisionDetailsCoordinator.php", data);
};

export const updateDecisionDetailsApprover = (data) => {
  return postRequest("requestApproval.php", data);
};

export const updateDecisionTemplate = (data) => {
  return postRequest("updateDecisionTemplate.php", data);
};

export const getCommentsByRow = (data) => {
  return postRequest("getCommentsByRow.php", data);
};

export const addComment = (data) => {
  return postRequest("addComment.php", data);
};

export const deleteComment = (data) => {
  return postRequest("deleteComment.php", data);
};

export const addDecisionRow = (data) => {
  return postRequest("addDecisionRow.php", data);
};

export const addVote = (data) => {
  return postRequest("addVote.php", data);
};

export const updateDecisionRow = (data) => {
  return postRequest("updateDecisionRow.php", data);
};

export const deleteDecisionRow = (data) => {
  return postRequest("deleteDecisionRow.php", data);
};

export const createZoomMeeting = (data) => {
  return postRequest("createZoomMeeting.php", data);
};

export const deleteDecisionTemplate = (data) => {
  return postRequest("deleteDecisionTemplate.php", data);
};

export const rejectDecision = (data) => {
  return postRequest("rejectDecision.php", data);
};
