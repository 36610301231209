import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Label,
  Button,
  Table,
  Input,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Alert,
  Spinner,
} from "reactstrap";
import Editor from "../../../common/editor";
import HtmlRendor from "../../../common/html-rendor";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import {
  swipeRowDecision,
  getCoordinatorDecisionDetails,
  updateDecisionDetailsCoordinator,
  updateDecisionDetailsApprover,
  getCommentsByRow,
  addComment,
  deleteComment,
  addDecisionRow,
  addVote,
  updateDecisionRow,
  deleteDecisionRow,
  createZoomMeeting,
  rejectDecision,
} from "../store/decisionActions";
import ReeValidate from "ree-validate";
import {
  errorAlert,
  successAlert,
  warningAlert,
} from "../../web/store/alertActions";
import {
  findElementByKey,
  formatedDate,
  updateObjectByDoubleKey,
  updateObjectByKey,
  isNumber,
  getStatusIdByName,
  getDecisionTypesIdByName,
  mergeAndFormateTimestamp,
  formateTimeUTCToLocal,
} from "../../../utilities/helpers";
import {
  decisionTypes,
  decisionStatus,
  decisionRiskTypes,
  datePickerFormate,
  timePickerFormate,
  timePickerInterval,
  notIntegratedResponseStatus,
  expirePlanAlertMessage,
  expirePlanType,
  expirePlanStatus,
  autoSaveTimeout,
} from "../../../constants";
import { loadEmployees } from "../../employees/store/employeeActions";
import SelectRow from "../../../common/select";
import Comments from "./Comments";
import moment from "moment";
import ColumnResizer from "column-resizer";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import AvatarLabel from "../../../common/avatar-label";
import Avatar from "react-avatar";
import ReactDragListView from "react-drag-listview/lib/index.js";
import Tooltip from "react-simple-tooltip";
import {css} from "styled-components";
import FirePadEditor from '../../../common/firepadeditor/Editor'

class CreateDecisionDetailsCoordinator extends Component {
  constructor(props) {
    super(props);

    this.validator = new ReeValidate({
      comment: "required",
      decisionTypeId: "",
      decisionRiskTypeId: "",
      description: "",
      decisionStatusId: "",
      collabNoteText: "",
      finalDecisionText: "",
      finalDecisionReason: "",
    });

    this.vote = React.createRef();

    this.autoSaveRows = [];
    this.autoUpdateRows = [];

    this.state = {
      commentAddbtn: true,
      approvalSent: false,
      isApprover: false,
      isCoordinator: false,
      btnCoordinator: false,
      displayBlock: false,
      isSubmiting: false,
      columnLength: 100,
      columns: [],
      addRows: [],
      updateRows: [],
      deleteRows: [],
      totalPoints: [],
      membersVotes: [],
      voteMembers: [],
      editText: [],
      addText: [],
      tableRows: [],
      dragRows: [],
      decisionTypes: decisionTypes,
      decisionTypeId: null,
      decisionRiskTypeId: null,
      decisionStatusId: null,
      finalDecisionText: null,
      collabNoteText: "",
      finalDecisionReason: null,
      copyText: "Copy",
      editInput: 0,
      details: {},
      rowComment: 0,
      comment: "",
      description: "",
      commentModal: false,
      voteModal: false,
      showAddComment: false,
      shareDecision: false,
      zoomMeeting: false,
      zoomUrl: "",
      columnVote: 0,
      votes: [],
      meetingDate: "",
      meetingTime: new Date(),
      meetingEndTime: new Date(),
      updateRowsNo: [],
      autoSaveRows: [],
      autoUpdateRows: [],
      errors: this.validator.errors,
      approverId: 0,
      coordinatorId: 0,
      tooltipOpen: false,
      quetooltipopen: false,
    };
  }

  componentDidMount() {
    if (this.props.token) {
      if (this.props.isValidate) this.getDecisionDetails();
    } else {
      this.props.history.push("/");
    }
    this.enableResize();
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  async UNSAFE_componentWillReceiveProps(props) {
    if (props.decision) {
      if (
        (parseInt(props.decision.approverId) === parseInt(props.employeeId) &&
          parseInt(props.decision.approvalSent) === 1) ||
        parseInt(props.decision.approverId) ===
          parseInt(props.decision.coordinatorId)
      ) {
        this.setState({ displayBlock: true });
      }

      if (parseInt(props.decision.approverId) === parseInt(props.employeeId)) {
        this.setState({ isApprover: true });
      }

      if (
        parseInt(props.decision.coordinatorId) === parseInt(props.employeeId)
      ) {
        this.setState({ isCoordinator: true });
      }

      if (parseInt(props.decision.approvalSent) === 1) {
        this.setState({ approvalSent: true });
      }

      if (
        parseInt(props.decision.coordinatorId) === parseInt(props.employeeId)
      ) {
        this.setState({ btnCoordinator: true });
      }

      if (props.decision.decisionColumns) {
        if (
          parseInt(props.decision.coordinatorId) === parseInt(props.employeeId)
        ) {
          if (!props.decision.comments) {
            this.updateCurrentState(props);
            this.updateTotalPoints(props);
          }
        } else if (
          props.decision.approvers
            .map((approver) => approver.approverId)
            .includes(props.employeeId)
        ) {
          if (!props.decision.comments) {
            this.updateCurrentState(props);
            this.updateTotalPoints(props);
          }
        } else if (
          parseInt(props.decision.decisionStatusId) ===
          getStatusIdByName("Finalized")
        ) {
          this.props.history.push(
            "/decision/view/" + props.decision.decisionId
          );
        } else {
          this.props.history.push(
            "/decision/member/" + props.decision.decisionId
          );
        }
      }
    }
  }

  componentWillUnmount() {
    for (let i = 0; i < this.autoSaveRows.length; i++) {
      clearTimeout(this.autoSaveRows[i]);
    }
    for (let i = 0; i < this.autoUpdateRows.length; i++) {
      clearTimeout(this.autoUpdateRows[i]);
    }
    this.disableResize();
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  componentDidUpdate() {
    this.enableResize();
  }

  componentWillUpdate() {
    this.disableResize();
  }

  handleClickOutside = (event) => {
    if (this.table && !this.table.contains(event.target)) {
      this.setEditInput(0, "");
    }
    return false;
  };

  enableResize = () => {
    const options = {
      liveDrag: true,
      disabledColumns: [this.state.columnLength - 1, this.state.columnLength],
      flush: true,
      removePadding: false,
      draggingClass: "rangeDrag",
      resizeMode: "overflow",
    };
    if (!this.resizer) {
      if (this.table) {
        this.resizer = new ColumnResizer(this.table, options);
      }
    } else {
      this.resizer.reset(options);
    }
  };

  disableResize() {
    if (this.resizer) {
      this.resizer.reset({ disable: true });
    }
  }

  updateCurrentState = async (props) => {
    let updateRow = [];
    props.decision.decisionColumnDetails.map((column) => {
      if (column.columnDetailId !== "") {
        return updateRow.push({
          columnDetailId: column.columnDetailId,
          columnId: column.decisionColumnId,
          detailText: column.detailText,
          points: column.points,
          rowNo: column.rowNo,
        });
      } else {
        return true;
      }
    });
    let decisionTypes = this.state.decisionTypes;
    props.decision.decisionColumns.map((column) => {
      return (decisionTypes = updateObjectByKey(
        decisionTypes,
        { id: column.decisionColumnId, name: column.title },
        "id"
      ));
    });
    let membersVotes = [];
    props.decision.membersVotes.map((column) => {
      return (membersVotes = updateObjectByDoubleKey(
        membersVotes,
        {
          columnId: column.columnId,
          employeeId: column.employeeId,
          name: column.displayName,
          photoUrl: column.photoUrl,
        },
        "columnId",
        "employeeId"
      ));
    });

    this.setTableRows(props);
    await this.setState({
      columnLength: props.decision.decisionColumns.length * 2,
      decisionTypeId:
        parseInt(props.decision.decisionTypeId) === 0
          ? -1
          : props.decision.decisionTypeId,
      decisionRiskTypeId: props.decision.decisionRiskTypeId || 1,
      decisionStatusId: props.decision.decisionStatusId || 1,
      finalDecisionText: props.decision.finalDecisionText || "",
      collabNoteText: props.decision.collabNoteText || "",
      description: props.decision.description || "",
      finalDecisionReason: props.decision.finalDecisionReason || "",
      meetingDate: new Date(props.decision.meetingDate) || "",
      meetingTime: props.decision.meetingTime
        ? new Date(formateTimeUTCToLocal(props.decision.meetingTime))
        : new Date(),
      meetingEndTime: props.decision.meetingEndTime
        ? new Date(formateTimeUTCToLocal(props.decision.meetingEndTime))
        : new Date(),
      zoomMeeting: props.decision.zoomUrl ? true : false,
      zoomUrl: props.decision.zoomUrl,
      updateRows: updateRow,
      decisionTypes: decisionTypes,
      membersVotes: membersVotes,
    });

    this.filterVoteMembers();
  };

  filterVoteMembers = () => {
    let results = this.props.employees.filter(
      ({ employeeId: id1 }) =>
        !this.state.membersVotes.some(({ employeeId: id2 }) => id2 === id1)
    );
    this.setState({
      voteMembers: results,
    });
  };

  getDecisionDetails = () => {
    const requestData = new FormData();
    requestData.append("workspaceId", this.props.workspaceId);
    requestData.append("employeeId", this.props.employeeId);
    requestData.append("token", this.props.token);
    this.props.loadEmployees(requestData);
    requestData.append("decisionId", this.props.match.params.id);
    this.props.getDecisionDetails(requestData);
  };

  setTableRows = (props) => {
    let array = [];
    let dataArray = [];
    let idrag = 0;
    props.decision.decisionColumnDetails.map((detail) => {
      if (typeof array[detail.rowNo] !== "undefined") {
        return array[detail.rowNo].push({
          text: detail.detailText || "",
          points: detail.points,
          rowNo: detail.rowNo,
          columnDetailId: detail.columnDetailId,
          columnId: detail.decisionColumnId,
          employeeName: detail.employeeName,
          employeePhoto: detail.employeePhoto,
          vote: detail.vote,
          votername: detail.voterName,
        });
      } else {
        dataArray.push(idrag);
        idrag = parseInt(idrag) + 1;
        return (array[detail.rowNo] = [
          {
            text: detail.detailText || "",
            points: detail.points,
            rowNo: detail.rowNo,
            columnDetailId: detail.columnDetailId,
            columnId: detail.decisionColumnId,
            employeeName: detail.employeeName,
            employeePhoto: detail.employeePhoto,
            vote: detail.vote,
            votername: detail.voterName,
          },
        ]);
      }
    });

    this.setState({ tableRows: array, dragRows: dataArray });
  };

  setEditInput = (id, type) => {
    this.setState({
      editInput: { id: id, type: type },
    });
  };

  deleteVote = async (vote) => {
    let membersVotes = this.state.membersVotes.filter((column) => {
      return (
        vote.columnId !== column.columnId ||
        vote.employeeId !== column.employeeId
      );
    });
    await this.setState({ membersVotes });
    this.filterVoteMembers();
  };

  onSelectChange = async (name, value) => {
    await this.setState({
      [name]: value,
    });
  };

  handleCheckboxChange = (name) => {
    this.setState({
      [name]: !this.state[name],
    });

    if (
      !this.state[name] &&
      name === "zoomMeeting" &&
      this.state.zoomUrl === this.props.decision.zoomUrl
    ) {
      this.createZoomMeeting();
    }
  };

  handleEditorChange = (content, name) => {
    const value = content;
    const { errors } = this.validator;
    errors.remove(name);
    this.setState({ [name]: value });
    this.validator.validate(name, value).then(() => {
      this.setState({ errors });
    });
    if (value.trim() !== "") {
      this.setState({ commentAddbtn: false });
    } else {
      this.setState({ commentAddbtn: true });
    }
  };

  createZoomMeeting = () => {
    const payloadData = new FormData();
    payloadData.append("token", this.props.token);
    payloadData.append("employeeId", this.props.employeeId);
    payloadData.append("workspaceId", this.props.workspaceId);
    payloadData.append("title", this.props.decision.title);
    this.props
      .createZoomMeeting(payloadData)
      .then((success) => {
        this.setState({
          zoomUrl: success.payload.url,
        });
      })
      .catch((err) => {
        if (parseInt(err.data.status) === notIntegratedResponseStatus) {
          this.props.warningAlert(
            "Please connect Zoom account using Integrations!"
          );
        } else {
          this.props.errorAlert(
            "Something went wrong with create zoom meeting!"
          );
        }
      });
  };

  setVoteMembers = async () => {
    let membersVotes = this.state.membersVotes;
    this.state.votes.map((column) => {
      return (membersVotes = updateObjectByDoubleKey(
        membersVotes,
        {
          columnId: this.state.columnVote,
          employeeId: column.employeeId,
          name: column.displayName,
          photoUrl: column.photoUrl,
        },
        "columnId",
        "employeeId"
      ));
    });
    await this.setState({ membersVotes });
    this.filterVoteMembers();
    this.voteToggle();
  };

  updateTotalPoints = (props = null) => {
    let totalPoints = [];
    let editText = [];
    if (props) {
      props.decision.decisionColumnDetails.map((detail) => {
        editText[detail.columnDetailId] = {
          text: detail.detailText,
          points: detail.points,
        };
        if (typeof totalPoints[detail.decisionColumnId] !== "undefined") {
          return (totalPoints[detail.decisionColumnId] += parseInt(
            detail.points
          ));
        } else {
          return (totalPoints[detail.decisionColumnId] = parseInt(
            detail.points
          ));
        }
      });
      this.setState({ editText });
    } else {
      this.state.addRows.map((row) => {
        if (typeof totalPoints[row.columnId] !== "undefined") {
          return (totalPoints[row.columnId] += isNumber(row.points)
            ? parseInt(row.points)
            : 0);
        } else {
          return (totalPoints[row.columnId] = isNumber(row.points)
            ? parseInt(row.points)
            : 0);
        }
      });
      this.state.updateRows.map((row) => {
        if (typeof totalPoints[row.columnId] !== "undefined") {
          return (totalPoints[row.columnId] += isNumber(row.points)
            ? parseInt(row.points)
            : 0);
        } else {
          return (totalPoints[row.columnId] = isNumber(row.points)
            ? parseInt(row.points)
            : 0);
        }
      });
    }
    this.setState({ totalPoints });
  };

  commentToggle = () => {
    this.setState({
      commentModal: !this.state.commentModal,
      comment: "",
    });
    this.validator.reset();
    this.setState({
      commentAddbtn: true,
    });
  };

  voteToggle = (id = 0) => {
    if (this.state.voteModal) {
      document.addEventListener("mousedown", this.handleClickOutside);
    } else {
      document.removeEventListener("mousedown", this.handleClickOutside);
    }
    this.setState({
      voteModal: !this.state.voteModal,
      votes: [],
      columnVote: id,
    });
  };

  viewComment = (id) => {
    if (id === this.state.rowComment) {
      return true;
    }

    this.setState({
      rowComment: id,
    });

    this.getComments(id);
  };

  getComments = (id) => {
    this.setState({
      rowComment: id,
    });

    const payloadData = new FormData();
    payloadData.append("token", this.props.token);
    payloadData.append("employeeId", this.props.employeeId);
    payloadData.append("workspaceId", this.props.workspaceId);
    payloadData.append("rowNo", id);
    payloadData.append("decisionId", this.props.decision.decisionId);
    this.props.getCommentsByRow(payloadData);
  };

  addComment = () => {
    if (parseInt(this.props.currentPlan) === expirePlanType) {
      this.props.warningAlert(expirePlanAlertMessage);
      return true;
    }
    const form = { comment: this.state.comment };
    const { errors } = this.validator;

    this.validator.validateAll(form).then((success) => {
      if (success) {
        const payloadData = new FormData();
        payloadData.append("token", this.props.token);
        payloadData.append("employeeId", this.props.employeeId);
        payloadData.append("workspaceId", this.props.workspaceId);
        payloadData.append("rowNo", this.state.rowComment);
        payloadData.append("comment", this.state.comment);
        payloadData.append("decisionId", this.props.decision.decisionId);
        this.props
          .addComment(payloadData)
          .then(() => {
            this.getComments(this.state.rowComment);
            this.commentToggle();
            this.props.successAlert("Comment added.");
          })
          .catch(() => {
            this.props.errorAlert("Something went wrong, please try again!");
          });
      } else {
        this.setState({ errors });
      }
    });
  };

  deleteComment = (id) => {
    if (parseInt(this.props.currentPlan) === expirePlanType) {
      this.props.warningAlert(expirePlanAlertMessage);
      return true;
    }

    const payloadData = new FormData();
    payloadData.append("token", this.props.token);
    payloadData.append("employeeId", this.props.employeeId);
    payloadData.append("workspaceId", this.props.workspaceId);
    payloadData.append("commentId", id);
    payloadData.append("decisionId", this.props.decision.decisionId);
    this.props
      .deleteComment(payloadData)
      .then(() => {
        this.getComments(this.state.rowComment);
        this.props.successAlert("Comment deleted successfully.");
      })
      .catch(() => {
        this.props.errorAlert("Something went wrong, please try again!");
      });
  };

  add = async () => {
    let columns = await this.state.columns.filter((row) => {
      return row || row === "";
    });

    await this.setState({ columns: [...columns, ""] });
    this.updateRow();
  };

  handleDetailsChange = async (value, columnId, index, param) => {
    let obj = {};
    if (param === "text") {
      obj = {
        columnId: columnId,
        index: index + 1,
        value: value,
      };
    } else {
      if (value > 10 || value < 0) {
        return true;
      }
      let addText = this.state.addText;
      addText[columnId + "_" + index] = { points: value };
      this.setState({ addText });

      obj = {
        columnId: columnId,
        index: index + 1,
        points: value > 10 ? 10 : value < 0 ? 0 : value,
      };
    }
    this.setState({
      addRows: updateObjectByDoubleKey(
        this.state.addRows,
        obj,
        "columnId",
        "index"
      ),
    });
    if (param !== "text") {
      this.updateTotalPoints();
    }
    this.autoSave(index);
  };

  autoSave = (pindex) => {
    let autoSaveRows = this.state.autoSaveRows;
    if (!autoSaveRows.includes(pindex)) {
      autoSaveRows.push(pindex);
      this.setState({ autoSaveRows });
    } else {
      clearTimeout(this.autoSaveRows[pindex]);
    }
    this.autoSaveRows[pindex] = setTimeout(() => {
      clearTimeout(this.autoSaveRows[pindex]);
      this.addDecisionRow(pindex);
    }, autoSaveTimeout);
  };

  handleUpdateDetailsChange = async (
    value,
    detailsId,
    columnId,
    rowNo,
    other,
    param,
    detail
  ) => {
    let obj = {};
    if (param === "text") {
      obj = {
        columnDetailId: detailsId,
        columnId: columnId,
        detailText: value,
        points: other,
        rowNo: rowNo,
      };
      let editText = this.state.editText;
      editText[detailsId].text = value;
      this.setState({ editText });
    } else {
      if (value > 10) {
        return true;
      }
      if (value < 0) {
        return true;
      }
      let editText = this.state.editText;
      editText[detailsId].points = value;
      this.setState({ editText });
      obj = {
        columnDetailId: detailsId,
        columnId: columnId,
        detailText: other,
        points: value,
        rowNo: rowNo,
      };

      if (Object.keys(this.props.decision.decisionColumnDetails).length !== 0) {
        for (
          var i = 0;
          i < this.props.decision.decisionColumnDetails.length;
          i++
        ) {
          if (
            this.props.decision.decisionColumnDetails[i].columnDetailId ===
            obj.columnDetailId
          ) {
            this.props.decision.decisionColumnDetails[i].points = obj.points;
          }
        }
      }
    }

    let updateRowsNo = this.state.updateRows;
    if (!updateRowsNo.includes(rowNo)) {
      updateRowsNo.push(rowNo);
    }
    await this.setState({
      updateRows: updateObjectByKey(
        this.state.updateRows,
        obj,
        "columnDetailId"
      ),
      updateRowsNo,
    });
    if (param !== "text") {
      await this.updateTotalPoints();
    }
    this.autoUpdate(rowNo, detail);
  };

  autoUpdate = (pindex, details) => {
    let autoUpdateRows = this.state.autoUpdateRows;
    if (!autoUpdateRows.includes(pindex)) {
      autoUpdateRows.push(pindex);
      this.setState({ autoUpdateRows });
    } else {
      clearTimeout(this.autoUpdateRows[pindex]);
    }
    let autoUpdateDetails = this.state.autoUpdateRows;
    autoUpdateDetails[pindex] = details;
    this.setState({ autoUpdateRows: autoUpdateDetails });
    this.autoUpdateRows[pindex] = setTimeout(() => {
      clearTimeout(this.autoUpdateRows[pindex]);
      this.updateDecisionRow(pindex, this.state.autoUpdateRows[pindex]);
    }, autoSaveTimeout);
  };

  handleChange = async (e) => {
    let name = e.target.name;
    let value = e.target.value;

    if (name === "decisionTypeId") {
      if (parseInt(value) === getDecisionTypesIdByName("Cancel")) {
        await this.setState({
          decisionStatusId: getStatusIdByName("Cancelled"),
        });
      } else if (
        parseInt(this.state.decisionTypeId) ===
          getDecisionTypesIdByName("Cancel") &&
        parseInt(this.props.decision.decisionTypeId) !==
          getDecisionTypesIdByName("Cancel")
      ) {
        await this.setState({
          decisionStatusId: this.props.decision.decisionStatusId,
        });
      }
    }
    this.setState({
      [name]: value,
    });
    this.validator.validate(name, value).then(() => {
      const { errors } = this.validator;
      this.setState({ errors });
    });
  };

  onDateChange = async (name, value) => {
    await this.setState({ [name]: value });

    if (
      this.state.zoomUrl === this.props.decision.zoomUrl &&
      this.state.zoomMeeting
    ) {
      this.createZoomMeeting();
    }
  };

  updateRow = () => {
    let rowNo = 0,
      columns = this.state.addRows;
    if (columns.length) {
      rowNo = parseInt(columns[columns.length - 1].rowNo) + 1;
    } else {
      columns = this.props.decision.decisionColumnDetails;
      if (columns.length) {
        rowNo = parseInt(columns[columns.length - 1].rowNo) + 1;
      }
    }

    this.props.decision.decisionColumns.map((column) => {
      setTimeout(() => {
        this.setState({
          addRows: updateObjectByDoubleKey(
            this.state.addRows,
            {
              columnId: column.decisionColumnId,
              index: this.state.columns.length,
              value: "",
              points: 0,
              rowNo: rowNo,
            },
            "columnId",
            "index"
          ),
        });
        this.updateTotalPoints();
      }, 50);
      return true;
    });
  };

  handleApproveClick = () => {
    //if current user is the only approver and and driver
    if (
      this.state.isApprover === true &&
      this.props.decision.approvers.length === 1
    ) {
      this.handleSubmitClick();
    } else {
      confirmAlert({
        title: "Approval Request",
        message: "Approval request will be sent to approvers",
        buttons: [
          {
            label: "Yes",
            onClick: () => this.handleSubmitClick(),
          },
          {
            label: "No",
          },
        ],
      });
    }
  };

  finalizeSubmit = (isApprove) => {
    confirmAlert({
      title: "Finalize decision",
      message:
        "Once you finalize a decision, you would not be able to make any further changes, and decision will be read only. Are you sure?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.handleSubmit(false, isApprove),
        },
        {
          label: "No",
        },
      ],
    });
  };

  handleSubmitClick = async (isApprove = false) => {
    if (
      getStatusIdByName("Finalized") ===
        parseInt(this.state.decisionStatusId) &&
      this.state.isApprover === true &&
      this.props.decision.creatorId === this.props.decision.approverId &&
      this.props.decision.approvers.length === 1
    ) {
      this.finalizeSubmit(isApprove);
    } else {
      this.handleSubmit(false, isApprove);
    }
  };

  handleSubmit = async (inline = false, isApprove = false) => {
    if (parseInt(this.props.currentPlan) === expirePlanType) {
      this.props.warningAlert(expirePlanAlertMessage);
      return true;
    }
    for (let i = 0; i < this.autoSaveRows.length; i++) {
      clearTimeout(this.autoSaveRows[i]);
    }
    for (let i = 0; i < this.autoUpdateRows.length; i++) {
      clearTimeout(this.autoUpdateRows[i]);
    }
    let addRows = [],
      updateRows = [],
      rowNo = 0,
      decisionColumnDetails = this.props.decision.decisionColumnDetails;
    if (decisionColumnDetails.length) {
      rowNo = decisionColumnDetails[decisionColumnDetails.length - 1].rowNo;
    }

    await this.state.addRows.map((row) => {
      let index = addRows.findIndex((arr) => arr.columnId === row.columnId);
      if (index > -1) {
        return addRows[index].rows.push({
          rowNo: parseInt(rowNo) + row.index,
          detailText: row.value,
          points: row.points,
        });
      } else {
        return addRows.push({
          columnId: row.columnId,
          rows: [
            {
              rowNo: parseInt(rowNo) + row.index,
              detailText: row.value,
              points: row.points,
            },
          ],
        });
      }
    });

    addRows.sort((a, b) =>
      parseInt(a.columnId) > parseInt(b.columnId) ? 1 : -1
    );

    await this.state.updateRows.map((row) => {
      let index = updateRows.findIndex((arr) => arr.columnId === row.columnId);
      if (index > -1) {
        return updateRows[index].rows.push({
          rowNo: row.rowNo,
          detailText: row.detailText,
          columnDetailId: row.columnDetailId,
          points: row.points,
        });
      } else {
        return updateRows.push({
          columnId: row.columnId,
          rows: [
            {
              rowNo: row.rowNo,
              detailText: row.detailText,
              columnDetailId: row.columnDetailId,
              points: row.points,
            },
          ],
        });
      }
    });

    const payloadData = new FormData();
    payloadData.append("token", this.props.token);
    payloadData.append("employeeId", this.props.employeeId);
    payloadData.append("workspaceId", this.props.workspaceId);
    payloadData.append("coordinatorId", this.props.decision.coordinatorId);
    payloadData.append("decisionId", this.props.decision.decisionId);
    payloadData.append("collabNoteText", this.state.collabNoteText);
    payloadData.append("finalDecisionText", this.state.finalDecisionText);
    payloadData.append("finalDecisionReason", this.state.finalDecisionReason);
    payloadData.append("description", this.state.description);
    payloadData.append("decisionTypeId", this.state.decisionTypeId);
    payloadData.append("decisionRiskTypeId", this.state.decisionRiskTypeId);
    payloadData.append("columnVotes", JSON.stringify(this.state.membersVotes));
    payloadData.append("decisionStatusId", this.state.decisionStatusId);

    if (
      this.state.isApprover === true &&
      this.props.decision.creatorId === this.props.decision.approverId &&
      this.props.decision.approvers.length === 1
    ) {
      payloadData.append("requestApproval", 1);
    } else {
      payloadData.append("requestApproval", 0);
    }

    if (this.state.zoomMeeting) {
      payloadData.append("zoomUrl", this.state.zoomUrl);
    }
    if (
      parseInt(this.state.decisionStatusId) === getStatusIdByName("Finalized")
    ) {
      payloadData.append("shareDecision", this.state.shareDecision ? 1 : 0);
    }
    if (
      parseInt(this.state.decisionTypeId) ===
        getDecisionTypesIdByName("Postponed") ||
      parseInt(this.state.decisionTypeId) ===
        getDecisionTypesIdByName("Followup")
    ) {
      payloadData.append(
        "meetingDate",
        mergeAndFormateTimestamp(this.state.meetingDate, this.state.meetingTime)
      );
      payloadData.append(
        "meetingTime",
        mergeAndFormateTimestamp(this.state.meetingDate, this.state.meetingTime)
      );
      payloadData.append(
        "meetingEndTime",
        mergeAndFormateTimestamp(
          this.state.meetingDate,
          this.state.meetingEndTime
        )
      );
    }
    let array = {};
    array.addColumns = JSON.stringify(addRows);
    array.updateColumns = JSON.stringify(updateRows);
    array.deleteColumns = JSON.stringify(this.state.deleteRows);
    await payloadData.append("decisionColumnsDetails", JSON.stringify(array));

    this.setState({ isSubmiting: true });
    this.props
      .updateDecisionDetailsCoordinator(payloadData)
      .then((success) => {
        this.props.successAlert("Decision details updated successfully.");
        this.setState({
          columns: [],
          addRows: [],
          updateRows: [],
          deleteRows: [],
        });
        if (inline === true) {
          this.getDecisionDetails();
        } else {
          this.props.history.push("/dashboard");
        }
      })
      .catch((err) => {
        this.setState({ isSubmiting: false });
        if (parseInt(err.data.status) === expirePlanStatus) {
          this.props.warningAlert(expirePlanAlertMessage);
        } else {
          this.props.errorAlert("Something went wrong, please try again!");
        }
      });
  };

  handleCopy = () => {
    this.setState({
      copyText: "Copied",
    });
    setTimeout(() => {
      this.setState({
        copyText: "Copy",
      });
    }, 2000);
  };

  resetState = () => {
    this.setState({
      columns: [],
      addRows: [],
      deleteRows: [],
      anonymous: "",
    });
  };

  setFormData = () => {
    const payloadData = new FormData();
    payloadData.append("token", this.props.token);
    payloadData.append("employeeId", this.props.employeeId);
    payloadData.append("workspaceId", this.props.workspaceId);
    return payloadData;
  };

  deleteDetails = (details) => {
    if (parseInt(this.props.currentPlan) === expirePlanType) {
      this.props.warningAlert(expirePlanAlertMessage);
      return true;
    }
    const payloadData = this.setFormData();
    let deleteRows = details.map((detail) => detail.columnDetailId);
    payloadData.append("rowNo", details[0].rowNo);
    payloadData.append("decisionId", this.props.decision.decisionId);
    payloadData.append("deleteRows", JSON.stringify(deleteRows));
    this.props
      .deleteDecisionRow(payloadData)
      .then(() => {
        this.props.successAlert("Row deleted successfully.");
        this.getDecisionDetails();
        this.resetState();
      })
      .catch((err) => {
        if (parseInt(err.data.status) === expirePlanStatus) {
          this.props.warningAlert(expirePlanAlertMessage);
        } else {
          this.props.errorAlert("Something went wrong, please try again!");
        }
      });
  };

  addVote = (pindex) => {
    const payloadData = new FormData();
    payloadData.append("decisionId", this.props.decision.decisionId);
    payloadData.append("rowNo", pindex);
    payloadData.append("employeeId", this.props.employeeId);

    this.props.addVote(payloadData).then(() => {
      this.getDecisionDetails();
      this.resetState();
    });
  };

  addDecisionRow = async (pindex) => {
    if (parseInt(this.props.currentPlan) === expirePlanType) {
      this.props.warningAlert(expirePlanAlertMessage);
      return true;
    }
    if (this.autoSaveRows[pindex]) {
      clearTimeout(this.autoSaveRows[pindex]);
    }

    let addRows = [];
    let emptyFields = true;
    let genRow = pindex;

    await this.state.addRows.map((row) => {
      if (row.index === pindex + 1) {
        if (row.value) emptyFields = false;
        let index = addRows.findIndex((arr) => arr.columnId === row.columnId);
        if (index > -1) {
          return addRows[index].rows.push({
            rowNo: row.rowNo,
            detailText: row.value,
            points: row.points,
          });
        } else {
          return addRows.push({
            columnId: row.columnId,
            rows: [
              {
                rowNo: row.rowNo,
                detailText: row.value,
                points: row.points,
              },
            ],
          });
        }
      } else {
        return true;
      }
    });
    if (emptyFields) {
      return true;
    }
    const payloadData = this.setFormData();
    addRows.sort((a, b) =>
      parseInt(a.columnId) > parseInt(b.columnId) ? 1 : -1
    );

    let updateRows = [];

    await this.state.updateRows.map((row) => {
      let index = updateRows.findIndex((arr) => arr.columnId === row.columnId);
      if (index > -1) {
        return updateRows[index].rows.push({
          rowNo: row.rowNo,
          detailText: row.detailText,
          columnDetailId: row.columnDetailId,
          points: row.points,
        });
      } else {
        return updateRows.push({
          columnId: row.columnId,
          rows: [
            {
              rowNo: row.rowNo,
              detailText: row.detailText,
              columnDetailId: row.columnDetailId,
              points: row.points,
            },
          ],
        });
      }
    });

    payloadData.append("addRows", JSON.stringify(addRows));
    payloadData.append("updateRows", JSON.stringify(updateRows));
    this.props
      .addDecisionRow(payloadData)
      .then(() => {
        this.props.successAlert("Row added successfully.");
        this.getDecisionDetails();
        this.setState({
          updateRowsNo: [],
        });
        this.deleteAddColumns(genRow);
      })
      .catch((err) => {
        if (parseInt(err.data.status) === expirePlanStatus) {
          this.props.warningAlert(expirePlanAlertMessage);
        } else {
          this.props.errorAlert("Something went wrong, please try again!");
        }
      });
  };

  updateDecisionRow = async (e, details) => {
    if (parseInt(this.props.currentPlan) === expirePlanType) {
      this.props.warningAlert(expirePlanAlertMessage);
      return true;
    }
    let addRows = [];
    let rowNo = 0;
    let decisionColumnDetails = this.props.decision.decisionColumnDetails;

    if (decisionColumnDetails !== undefined) {
      if (decisionColumnDetails.length > 0) {
        rowNo = decisionColumnDetails[decisionColumnDetails.length - 1].rowNo;
      }
    }
    await this.state.addRows.map((row) => {
      let index = addRows.findIndex((arr) => arr.columnId === row.columnId);
      if (index > -1) {
        return addRows[index].rows.push({
          rowNo: parseInt(rowNo) + row.index,
          detailText: row.value,
          points: row.points != undefined ? row.points : 0,
        });
      } else {
        return addRows.push({
          columnId: row.columnId,
          rows: [
            {
              rowNo: parseInt(rowNo) + row.index,
              detailText: row.value,
              points: row.points != undefined ? row.points : 0,
            },
          ],
        });
      }
    });

    let updateRows = [];
    await this.state.updateRows.map((row) => {
      let index = updateRows.findIndex((arr) => arr.columnId === row.columnId);
      if (index > -1) {
        return updateRows[index].rows.push({
          rowNo: row.rowNo,
          detailText: row.detailText,
          columnDetailId: row.columnDetailId,
          points: row.points,
        });
      } else {
        return updateRows.push({
          columnId: row.columnId,
          rows: [
            {
              rowNo: row.rowNo,
              detailText: row.detailText,
              columnDetailId: row.columnDetailId,
              points: row.points,
            },
          ],
        });
      }
    });
    const payloadData = this.setFormData();
    payloadData.append("updateRows", JSON.stringify(updateRows));
    payloadData.append("addRows", JSON.stringify(addRows));
    this.props
      .updateDecisionRow(payloadData)
      .then(() => {
        this.props.successAlert("Row updated successfully.");
        this.setState({
          columns: [],
          addRows: [],
          updateRows: [],
          deleteRows: [],
          updateRowsNo: [],
        });
        this.deleteRowFromUpdate(rowNo);
        this.getDecisionDetails();
      })
      .catch((err) => {
        if (parseInt(err.data.status) === expirePlanStatus) {
          this.props.warningAlert(expirePlanAlertMessage);
        } else {
          this.props.errorAlert("Something went wrong, please try again!");
        }
        this.deleteRowFromUpdate(rowNo);
      });
  };

  deleteRowFromUpdate = (rowNo) => {
    let updateRowsNo = this.state.updateRowsNo;
    var index = updateRowsNo.indexOf(rowNo);
    if (index > -1) {
      updateRowsNo.splice(index, 1);
    }
    this.setState({ updateRowsNo });
  };

  deleteAddColumns = async (index) => {
    let columns = this.state.columns;
    delete columns[index];

    let addRows = this.state.addRows.filter((row) => row.index !== index + 1);
    this.setState({ columns, addRows });
  };

  tooltipOpenHandle = (id = "") => {
    this.setState({ tooltipOpen: id });
  };

  quetooltipOpenHandle = () => {
    this.setState({ quetooltipopen: true });
  };

  quetooltipCloseHandle = () => {
    this.setState({ quetooltipopen: false });
  };

  mouseOut = async (e, details, rowNumber) => {
    if (this.state.updateRowsNo.includes(rowNumber)) {
      this.setEditInput(0, "");
      this.updateDecisionRow(e, details);
    }
  };

  stripHtml = (html) => {
    let temporalDivElement = document.createElement("div");
    // Set the HTML content with the providen
    temporalDivElement.innerHTML = html;
    // Retrieve the text property of the element (cross-browser support)
    return temporalDivElement.textContent || temporalDivElement.innerText || "";
  };

  createTable = () => {
    let table = [];

    this.state.tableRows.map((detail, pindex) => {
      let children = [];
      let rowNo = 0;
      let vote = 0;
      let votername = "";
      let employeeName = "";
      let employeePhoto = "";
      detail.map((text, index) => {
        rowNo = text.rowNo;
        vote = text.vote;
        employeeName = text.employeeName;
        employeePhoto = text.employeePhoto;
        votername = text.votername;
        votername = votername.split(",");
        return children.push(
          <React.Fragment key={index}>
            <td
              className="th-lg-9"
              onClick={() => this.setEditInput(text.columnDetailId, "text")}
              /* onMouseOut={(e) => this.mouseOut(e, detail, rowNo)} */
            >
              {this.state.editInput.id === text.columnDetailId &&
              this.state.editInput.type === "text" ? (
                <Editor
                  name={text.columnDetailId}
                  value={text.text || ""}
                  onChange={this.handleUpdateDetailsChange}
                  customProps={[
                    text.columnId,
                    text.rowNo,
                    text.points,
                    "text",
                    detail,
                  ]}
                />
              ) : (
                <label className="break-line-cust">
                  <HtmlRendor
                    value={this.state.editText[text.columnDetailId].text}
                  />
                </label>
              )}
            </td>
            {this.props.decision.allowpoints === "1" ? (
              <td
                className="th-lg-5"
                onClick={() => this.setEditInput(text.columnDetailId, "points")}
                /* onMouseOut={(e) => this.mouseOut(e, detail, rowNo)} */
              >
                {this.state.editInput.id === text.columnDetailId &&
                this.state.editInput.type === "points" ? (
                  <Input
                    autoFocus="autoFocus"
                    onFocus={(e) => e.target.select()}
                    type="number"
                    className="th-lg-3"
                    value={this.state.editText[text.columnDetailId].points}
                    onChange={(e) =>
                      this.handleUpdateDetailsChange(
                        e.target.value,
                        text.columnDetailId,
                        text.columnId,
                        text.rowNo,
                        text.text,
                        "points",
                        detail
                      )
                    }
                    onBlur={() => this.setEditInput(0, "")}
                  />
                ) : (
                  <label className="break-line-cust">
                    {this.state.editText[text.columnDetailId].points}
                  </label>
                )}
              </td>
            ) : null}
          </React.Fragment>
        );
      });
      let votertooltip = [];

      votername.map((name, index) => {
        let string =
          "<p key=" + index + ' className="voter-name">' + name + "</p>";
        return votertooltip.push(string);
      });

      children.push(
        <td
          onClick={() => this.setEditInput(0, "")}
          key={pindex + 210}
          className="action-td"
          align="right"
        >
          {this.state.updateRowsNo.includes(rowNo) && (
            <div
              className="colnew comment-new"
              onClick={(e) => this.updateDecisionRow(e, detail)}
            >
              <img
                src={"/assets/check-icon.svg"}
                className="comment-icon"
                alt="check"
              />
            </div>
          )}
          <div className="colnew" onClick={() => this.viewComment(rowNo)}>
            <img
              className="comment-icon"
              src={
                parseInt(this.props.decision.hasRowComments[rowNo]) === 1
                  ? "/assets/comment-dot.svg"
                  : "/assets/comment.svg"
              }
              alt="comment"
            />
          </div>
          <div className="colnew" onClick={() => this.addVote(rowNo)}>
            <img
              src={"/assets/plus-thin.svg"}
              className="plus-icon"
              alt="plus"
            />

            {vote > 0 && (
              <Tooltip
                content={this.stripHtml(votertooltip.join())}
                placement="left"
                background="#2c4a7c"
                border="#2c4a7c"
                fontSize="10px"
                padding="8"
              >
                <span className="pl-1">{vote > 0 && vote}</span>
              </Tooltip>
            )}
          </div>

          <Tooltip
            content={employeeName}
            placement="left"
            background="#2c4a7c"
            border="#2c4a7c"
            fontSize="10px"
            padding="8"
          >
            <div className="colnew" data-tip data-for={"tool_" + rowNo}>
              {employeePhoto.trim() !== "" && (
                <div className="avtar-image">
                  <AvatarLabel avatar={employeePhoto} />
                </div>
              )}
              {employeePhoto.trim() === "" && (
                <Avatar
                  name={employeeName}
                  size="20"
                  round="20px"
                  maxInitials={1}
                  color="#2c4a7c"
                  textSizeRatio={1.75}
                />
              )}
            </div>
          </Tooltip>
          <div
            className="w-5 colnew"
            onClick={() => this.deleteDetails(detail)}
          >
            <img src={"/assets/cross.svg"} alt="X" />
          </div>
        </td>
      );

      return table.push(
        <tr
          key={pindex}
          className={this.state.rowComment === rowNo ? "active-table-row" : ""}
        >
          {children}
        </tr>
      );
    });

    this.state.columns.map((column, pindex) => {
      return table.push(
        <tr key={pindex + 100} draggable="false">
          {this.props.decision.decisionColumns.map((columnDetail, index) => {
            return (
              <React.Fragment key={index}>
                <td className="th-lg-9">
                  <Editor
                    name={columnDetail.decisionColumnId}
                    value={""}
                    customProps={[pindex, "text"]}
                    onChange={this.handleDetailsChange}
                  />
                </td>
                {this.props.decision.allowpoints === "1" ? (
                  <td className="th-lg-5">
                    <input
                      type="number"
                      min="0"
                      max="10"
                      className="form-control th-lg-3"
                      name={"details_points_" + (index + 1)}
                      value={
                        this.state.addText[
                          columnDetail.decisionColumnId + "_" + pindex
                        ]
                          ? this.state.addText[
                              columnDetail.decisionColumnId + "_" + pindex
                            ].points
                          : ""
                      }
                      onChange={(e) =>
                        this.handleDetailsChange(
                          e.target.value,
                          columnDetail.decisionColumnId,
                          pindex,
                          "points"
                        )
                      }
                    />
                  </td>
                ) : (
                  " "
                )}
              </React.Fragment>
            );
          })}
          {
            <td className="td-valign-center">
              <span
                className="btn-cust-close float-right"
                onClick={() => this.deleteAddColumns(pindex)}
              >
                <img src={"/assets/cross.svg"} alt="X" />
              </span>
              <span
                className="btn-cust-check float-right"
                onClick={() => this.addDecisionRow(pindex)}
              >
                <img src={"/assets/check-icon.svg"} alt="check" />
              </span>
            </td>
          }
        </tr>
      );
    });

    return table;
  };

  createTableFoot = () => {
    let table = [];
    this.props.decision.allowpoints === "1" &&
      table.push(
        <tr key={table.length + 500}>
          {this.props.decision.decisionColumns.map((column, index) => {
            return (
              <React.Fragment key={index}>
                {this.props.decision.allowpoints === "1" ? (
                  <th>Total points</th>
                ) : null}
                {this.props.decision.allowpoints === "1" ? (
                  <th>{this.state.totalPoints[column.decisionColumnId]}</th>
                ) : null}
                {this.props.decision.decisionColumns.length === index + 1 &&
                  this.props.decision.allowpoints === "1" && <th></th>}
              </React.Fragment>
            );
          })}
        </tr>
      );
    return table;
  };

  createVoteTable = () => {
    let table = "";
    table = this.props.decision.decisionColumns.map((column, index) => {
      let voteCount = 0;
      return (
        <Table key={index} className="table-custom" responsive>
          <thead>
            <tr>
              <th>{column.title}</th>
            </tr>
          </thead>
          <tbody>
            {this.state.membersVotes.map((vote, pindex) => {
              if (vote.columnId === column.decisionColumnId) {
                voteCount++;
                return (
                  <tr key={pindex + 200}>
                    <td>
                      <AvatarLabel label={vote.name} avatar={vote.photoUrl} />{" "}
                      <span
                        className="btn-cust-close cursor-pointer float-right"
                        onClick={() => this.deleteVote(vote)}
                      >
                        <img src={"/assets/cross.svg"} alt="X" />
                      </span>
                    </td>
                  </tr>
                );
              } else {
                return false;
              }
            })}
            <tr>
              <td>
                <b className="float-left">Count: {voteCount}</b>
                <Button
                  className="btn-custom btn-circle btn-md add-comment-btn"
                  onClick={() => this.voteToggle(column.decisionColumnId)}
                >
                  +
                </Button>
              </td>
            </tr>
          </tbody>
        </Table>
      );
    });
    return table;
  };

  rejectDecision = () => {
    const payloadData = this.setFormData();
    payloadData.append("decisionId", this.props.decision.decisionId);
    this.props
      .rejectDecision(payloadData)
      .then(() => {
        this.props.successAlert("Decision rejected.");
        this.getDecisionDetails();
        this.props.history.push("/dashboard");
      })
      .catch((err) => {
        if (parseInt(err.data.status) === expirePlanStatus) {
          this.props.warningAlert(expirePlanAlertMessage);
        } else {
          this.props.errorAlert("Something went wrong, please try again!");
        }
      });
  };

  swipeTwoRows = (dragArray) => {
    const payloadData = this.setFormData();
    payloadData.append("token", this.props.token);
    payloadData.append("dragArray", dragArray);
    payloadData.append("decisionId", this.props.decision.decisionId);
    payloadData.append("workspaceId", this.props.workspaceId);
    this.props
      .swipeRowDecision(payloadData)
      .then(() => {
        this.getDecisionDetails();
      })
      .catch((err) => {
        this.props.errorAlert("Something went wrong, please try again!");
      });
  };

  render() {
    if (!this.props.decision || !this.props.decision.decisionColumns) {
      return (
        <main className="create-main">
          <div className="loader"></div>
        </main>
      );
    }
    const that = this;
    const dragProps = {
      onDragEnd(fromIndex, toIndex) {
        let totalCount = parseInt(that.state.dragRows.length);
        if (parseInt(totalCount) > parseInt(fromIndex)) {
          const data2 = [...that.state.dragRows];
          const item1 = data2.splice(fromIndex, 1)[0];
          data2.splice(toIndex, 0, item1);
          that.swipeTwoRows(data2);
        }
      },
      nodeSelector: "tr",
      handleSelector: "tr",
      lineClassName: "dragcolor",
    };

    return (
      <main ref={this.mainContainer} className="create-main">
        <br />
        <div className="container">
          {parseInt(this.props.decision.decisionStatusId) ===
            getStatusIdByName("Pending Approval") &&
            this.props.decision.isDiscussion === "1" && (
              <Alert color="success" style={{ textAlign: "center" }}>
                This discussion is pending approval.
              </Alert>
            )}
          {parseInt(this.props.decision.decisionStatusId) ===
            getStatusIdByName("Pending Approval") &&
            this.props.decision.isDiscussion !== "1" && (
              <Alert color="success" style={{ textAlign: "center" }}>
                This decision is pending approval.
              </Alert>
            )}
          <div className="decision-detail-heading">
            <h5>{this.props.decision.title}</h5>
            <span>
              Meeting Start Date:{" "}
              {formatedDate(this.props.decision.meetingDate)} and Deadline:{" "}
              {formatedDate(this.props.decision.dueDate)}
            </span>
            <hr />
            <span>Template created by: {this.props.decision.creatorName}</span>
          </div>

          <hr />
          <Row className="decision-detail-first">
            <Col md={6}>
              <div className="decision-detail-heading decision-detail-heading-row">
                <h5>Driver</h5>
                <span>
                  <AvatarLabel
                    label={this.props.decision.coordinatorName}
                    avatar={this.props.decision.coordinatorPhoto}
                  />
                </span>
              </div>
            </Col>
            <Col md={6}>
              <div className="decision-detail-heading decision-detail-heading-row">
                <h5>Approvers</h5>
                <table>
                  <tbody>
                    {this.props.decision.approvers.map((approver, index) => {
                      let icon =
                        approver.approvalStatus !== "0"
                          ? approver.approvalStatus === "1"
                            ? "accept.svg"
                            : "reject.svg"
                          : "";
                      return (
                        <tr key={index}>
                          <td>
                            <AvatarLabel
                              label={approver.approverName}
                              labelIcon={icon}
                              avatar={approver.approverPhoto}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </Col>
          </Row>
          <Row className="custom-row div-white-back members-line">
            <Col md={12}>
              <h5 className="default-color">Meeting members</h5>
            </Col>
            {this.props.decision.members.map((member, index) => {
              if (!parseInt(member.isCoordinator)) {
                let text = "";
                if (index !== 0) {
                  text += ", ";
                }
                text += member.displayName;
                return text;
              } else {
                return "";
              }
            })}{" "}
          </Row>
          <br />
          {this.props.decision.description && (
            <Row className="decision-detail-anonymous div-white-back custom-row">
              <Col md={5}>
                {this.props.decision.isDiscussion === "1" && (
                  <h5 className="default-color"> Description</h5>
                )}
                {this.props.decision.isDiscussion !== "1" && (
                  <h5 className="default-color"> Description</h5>
                )}
              </Col>
              <Col md={12}>
                <Label className="color-black">
                  <HtmlRendor value={this.props.decision.description} />
                </Label>
              </Col>
            </Row>
          )}

        {this.props.decision.hasCollabNote > 0 && (
            <Row className="decision-detail-anonymous div-white-back custom-row">
            <Col md={5}>
                <h5 className="default-color"> Collab note</h5>
            </Col>
            <Col md={12}>
              <FirePadEditor 
              onChange={this.handleEditorChange} 
              decisionId={this.props.decision.decisionId}
              name="collabNoteText"/>
            </Col>
          </Row>
          )}
          <br />
        
          <Row className="custom-row">
            <h5 className="default-color">
              Findings
              {this.props.decision.allowpoints === "1" ? (
                <React.Fragment>
                  <span className="point-range"> Points range(0-10) </span>
                  <Tooltip
                    content={"Points range(0-10)"}
                    background="#2c4a7c"
                    border="#2c4a7c"
                    fontSize="10px"
                    padding="8"
                    placement="right"
                    customCss={css`
                    white-space: nowrap;
                  `}
                  >
                    <img
                      src="/assets/questions.svg"
                      id={"tool_que"}
                      alt="question"
                      className="cursor-pointer question-icon"
                      width="17px"
                    ></img>
                  </Tooltip>
                </React.Fragment>
              ) : (
                " "
              )}
            </h5>
            <Col md={4}></Col>
          </Row>
          <Row className="decision-coord-detail-table">
            <Col md={this.state.rowComment ? 8 : 12} className="no-padding">
              <div className="table-responsive">
                  <table
                    className="table-custom table table-hover table-custom-details"
                    id="resizable"
                    ref={(table) => (this.table = table)}
                  >
                    <thead>
                      <tr>
                        {this.props.decision.decisionColumns.map(
                          (column, index) => {
                            return (
                              <React.Fragment key={index}>
                                <th className="th-lg-8">{column.title}</th>
                                {this.props.decision.allowpoints === "1" ? (
                                  <th className="th-lg-4 col-100px-fixed">
                                    Points
                                  </th>
                                ) : null}
                              </React.Fragment>
                            );
                          }
                        )}
                        <th className="col-245px-fixed"></th>
                      </tr>
                    </thead>
                      <tbody>{this.createTable()}</tbody>
                      <tfoot>{this.createTableFoot()}</tfoot>
                  </table>
              </div>
            </Col>
            {this.state.rowComment ? (
              <Comments
                decision={this.props.decision}
                deleteComment={this.deleteComment}
                commentModal={this.state.commentModal}
                handleChange={this.handleEditorChange}
                comment={this.state.comment}
                errors={this.state.errors}
                addComment={this.addComment}
                commentToggle={this.commentToggle}
                employeeId={this.props.employeeId}
                viewComment={this.viewComment}
                commentAddbtn={this.state.commentAddbtn}
              />
            ) : (
              ""
            )}
          </Row>
          <Row className="custom-row">
            <Col md={9}></Col>
            <Col md={3}>
              <Button
                onClick={this.add}
                className="btn-custom btn-custom-create btn-custom-width"
              >
                + &nbsp; Add a comparison row
              </Button>
            </Col>
          </Row>
          <br />

          {this.props.decision.decisionColumns.length > 1 &&
            this.props.decision.allowTeamVote > 0 && (
              <React.Fragment>
                <Row className="custom-row">
                  <Col md={12}>
                    <h5 className="default-color">Team member votes</h5>
                    <div className="vote-table">{this.createVoteTable()}</div>
                  </Col>
                </Row>
                <br />
              </React.Fragment>
            )}
          {this.props.decision.anonymousFeedback > 0 && (
            <Row className="custom-row">
              <Col md={5}>
                <h5 className="default-color">Team Anonymous Feedback</h5>
              </Col>

              <Col className="white-back members-line" md={12}>
                <Table className="table-custom" responsive>
                  <tbody>
                    {this.props.decision.feedbacks.map((feedback, index) => {
                      if (feedback.feedback) {
                        return (
                          <tr key={index}>
                            <td>
                              <HtmlRendor value={feedback.feedback} />
                            </td>
                          </tr>
                        );
                      } else {
                        return false;
                      }
                    })}
                  </tbody>
                </Table>
              </Col>
            </Row>
          )}
          <br />
          <div>
            {" "}
            <Row className="decision-detail-anonymous custom-row">
              <Col md={5}>
                {this.props.decision.isDiscussion === "1" && (
                  <h5 className="default-color">Final Discussion</h5>
                )}
                {this.props.decision.isDiscussion !== "1" && (
                  <h5 className="default-color">Final Decision</h5>
                )}
              </Col>
              <Col md={12}>
                <Editor
                  value={this.props.decision.finalDecisionText}
                  onChange={this.handleEditorChange}
                  name="finalDecisionText"
                />
              </Col>
            </Row>
            <Row className="decision-detail-anonymous custom-row">
              <Col md={5}>
                {this.props.decision.isDiscussion === "1" && (
                  <h5 className="default-color">Reason for Discussion/Notes</h5>
                )}
                {this.props.decision.isDiscussion !== "1" && (
                  <h5 className="default-color">Reason for Decision/Notes</h5>
                )}
              </Col>
              <Col md={12}>
                <Editor
                  value={this.props.decision.finalDecisionReason}
                  onChange={this.handleEditorChange}
                  name="finalDecisionReason"
                />
              </Col>
            </Row>
            <Row className="decision-detail-anonymous custom-row">
            {this.props.decision.isDiscussion !== "1" && (
                <Col md={2}>
                    <h5 className="default-color">Decision Type</h5>
                  <FormGroup>
                    <Input
                      type="select"
                      name="decisionTypeId"
                      onChange={this.handleChange}
                      value={this.state.decisionTypeId}
                    >
                      {this.state.decisionTypes.map((type, index) => {
                        return (
                          <option key={index} value={type.id}>
                            {type.name}
                          </option>
                        );
                      })}
                    </Input>
                  </FormGroup>
                </Col>
              )}
              {parseInt(this.state.decisionTypeId) ===
                getDecisionTypesIdByName("Postponed") ||
              parseInt(this.state.decisionTypeId) ===
                getDecisionTypesIdByName("Followup") ? (
                <React.Fragment>
                  <Col md={1}></Col>
                  <Col md={2}>
                    <FormGroup className="date-time-custom">
                      <h5 className="default-color">New Meeting Date</h5>
                      <DatePicker
                        className="form-control"
                        name="meetingDate"
                        autoComplete="off"
                        minDate={new Date()}
                        dateFormat={datePickerFormate}
                        selected={this.state.meetingDate}
                        onChange={(date) =>
                          this.onDateChange("meetingDate", date)
                        }
                      />
                    </FormGroup>
                  </Col>
                </React.Fragment>
              ) : (
                ""
              )}
              {this.props.decision.isDiscussion !== "1" && (
                   <Col md={1}></Col>
              )}
              <Col md={2}>
                {this.props.decision.isDiscussion === "1" && (
                  <h5 className="default-color">Discussion Status</h5>
                )}
                {this.props.decision.isDiscussion !== "1" && (
                  <h5 className="default-color">Decision Status</h5>
                )}

                <FormGroup>
                  <Input
                    type="select"
                    name="decisionStatusId"
                    onChange={this.handleChange}
                    value={this.state.decisionStatusId}
                  >
                    {decisionStatus.map((status, index) => {
                      return (
                        <option key={index} value={status.id}>
                          {status.name}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
              </Col>
              <Col md={1}></Col>
              {this.props.decision.isDiscussion !== "1" && (
                <Col md={2}>
                  <h5 className="default-color">Risk Analysis</h5>
                  <FormGroup>
                    <Input
                      type="select"
                      name="decisionRiskTypeId"
                      onChange={this.handleChange}
                      value={this.state.decisionRiskTypeId}
                    >
                      {decisionRiskTypes.map((type, index) => {
                        return (
                          <option key={index} value={type.id}>
                            {type.name}
                          </option>
                        );
                      })}
                    </Input>
                  </FormGroup>
                </Col>
              )}
            </Row>
            {parseInt(this.state.decisionStatusId) ===
              getStatusIdByName("Finalized") ||
            getDecisionTypesIdByName("Postponed") ===
              parseInt(this.state.decisionTypeId) ||
            getDecisionTypesIdByName("Followup") ===
              parseInt(this.state.decisionTypeId) ? (
              <React.Fragment>
                <Row className="decision-detail-anonymous custom-row">
                  {getDecisionTypesIdByName("Postponed") ===
                    parseInt(this.state.decisionTypeId) ||
                  getDecisionTypesIdByName("Followup") ===
                    parseInt(this.state.decisionTypeId) ? (
                    <React.Fragment>
                      <Col md={2}>
                        <FormGroup>
                          <h5 className="default-color">Meeting Start Time</h5>
                          <DatePicker
                            className="form-control"
                            name="meetingTime"
                            autoComplete="off"
                            showTimeSelect
                            showTimeSelectOnly
                            minDate={new Date()}
                            timeIntervals={timePickerInterval}
                            timeCaption="Start"
                            dateFormat={timePickerFormate}
                            selected={this.state.meetingTime}
                            onChange={(date) =>
                              this.onDateChange("meetingTime", date)
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col md={1}></Col>
                      <Col md={2}>
                        <FormGroup>
                          <h5 className="default-color">Meeting End Time</h5>
                          <DatePicker
                            className="form-control"
                            name="meetingEndTime"
                            autoComplete="off"
                            showTimeSelect
                            showTimeSelectOnly
                            minTime={this.state.meetingTime}
                            maxTime={moment().endOf("day").toDate()}
                            timeIntervals={timePickerInterval}
                            timeCaption="End"
                            dateFormat={timePickerFormate}
                            selected={this.state.meetingEndTime}
                            onChange={(date) =>
                              this.onDateChange("meetingEndTime", date)
                            }
                          />
                        </FormGroup>
                      </Col>
                    </React.Fragment>
                  ) : (
                    <Col md={2}></Col>
                  )}
                  <Col md={1}></Col>
                  <Col md={4}>
                    {parseInt(this.state.decisionStatusId) ===
                      getStatusIdByName("Finalized") && (
                      <FormGroup className="members-line" check>
                        {this.props.decision.isDiscussion === "1" && (
                          <p>
                            <Input
                              type="hidden"
                              name={"shareDecision"}
                              onChange={() =>
                                this.handleCheckboxChange("shareDecision")
                              }
                              checked={this.state.shareDecision}
                              value="true"
                            />{" "}
                            {/* Email Discussion results to Members */}
                          </p>
                        )}

                        {this.props.decision.isDiscussion !== "1" && (
                          <p>
                            <Input
                              type="hidden"
                              name={"shareDecision"}
                              onChange={() =>
                                this.handleCheckboxChange("shareDecision")
                              }
                              checked={this.state.shareDecision}
                              value="true"
                            />{" "}
                            {/* Email Decision results to Members */}
                          </p>
                        )}
                      </FormGroup>
                    )}
                  </Col>
                  <Col md={1}></Col>
                </Row>
                {parseInt(this.state.decisionStatusId) !==
                  getStatusIdByName("Finalized") && (
                  <Row>
                    <Col>
                      <FormGroup check>
                        <Label className="color-black" check>
                          <Input
                            type="checkbox"
                            name={"zoomMeeting"}
                            onChange={() =>
                              this.handleCheckboxChange("zoomMeeting")
                            }
                            checked={this.state.zoomMeeting}
                            value={this.state.zoomMeeting}
                          />{" "}
                          Add zoom meeting
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                )}

                {parseInt(this.state.decisionStatusId) !==
                  getStatusIdByName("Finalized") &&
                  this.state.zoomMeeting &&
                  this.state.zoomUrl && (
                    <Row>
                      <Col md={8}>
                        <InputGroup onClick={this.handleCopy}>
                          <CopyToClipboard text={this.state.zoomUrl}>
                            <React.Fragment>
                              <Input
                                type="text"
                                onFocus={(event) => event.target.select()}
                                onClick={(event) => event.target.select()}
                                value={this.state.zoomUrl}
                              />
                              <InputGroupAddon addonType="append">
                                <InputGroupText className="copy-text">
                                  {this.state.copyText}
                                </InputGroupText>
                              </InputGroupAddon>
                            </React.Fragment>
                          </CopyToClipboard>
                        </InputGroup>
                      </Col>
                    </Row>
                  )}
              </React.Fragment>
            ) : (
              ""
            )}
          </div>
          {this.state.isCoordinator === true &&
          parseInt(this.state.decisionStatusId) !==
            getStatusIdByName("Finalized") ? (
            <Row className="decision-detail-button custom-row">
              <Col md={10} />
              <Col md={1} className="left-btn-bottom">
                <FormGroup>
                  <Button
                    onClick={this.handleSubmitClick}
                    className="btn-custom btn-custom-create btn-custom-width"
                    disabled={this.state.isSubmiting ? true : false}
                  >
                    Submit {this.state.isSubmiting && <Spinner size="sm" />}
                  </Button>
                </FormGroup>
              </Col>
              <Col md={1} className="right-btn-bottom">
                <FormGroup>
                  <Link to={"/dashboard"}>
                    <Button className="btn-custom btn-custom-create btn-custom-width">
                      Close
                    </Button>
                  </Link>
                </FormGroup>
              </Col>
            </Row>
          ) : this.state.isCoordinator === true ? (
            <Row className="decision-detail-button custom-row">
              <Col md={8} />
              <Col md={2} className="left-btn-bottom">
                <FormGroup>
                  <Button
                    onClick={this.handleApproveClick}
                    className="btn-custom btn-custom-create btn-custom-width"
                    disabled={this.state.isSubmiting ? true : false}
                  >
                    {this.state.isApprover &&
                    this.props.decision.approvers.length === 1
                      ? "Submit "
                      : "Request for Approval "}
                    {this.state.isSubmiting && <Spinner size="sm" />}
                  </Button>
                </FormGroup>
              </Col>
              <Col md={2} className="right-btn-bottom">
                <FormGroup>
                  <Link to={"/dashboard"}>
                    <Button className="btn-custom btn-custom-create btn-custom-width">
                      Close
                    </Button>
                  </Link>
                </FormGroup>
              </Col>
            </Row>
          ) : this.state.isApprover === true ? (
            <Row className="decision-detail-button custom-row">
              {this.state.approvalSent === true ? (
                <React.Fragment>
                  <Col md={8} />
                  <Col md={2} className="left-btn-bottom">
                    <FormGroup>
                      <Button
                        onClick={() => this.handleSubmitClick(true)}
                        className="btn-custom btn-custom-create btn-custom-width"
                      >
                        {parseInt(this.state.decisionStatusId) === 4
                          ? "Approve "
                          : "Submit "}

                        {this.state.isSubmiting && <Spinner size="sm" />}
                      </Button>
                    </FormGroup>
                  </Col>
                  <Col md={2} className="right-btn-bottom">
                    <FormGroup>
                      <Button
                        onClick={this.rejectDecision}
                        className="btn-custom btn-custom-create btn-custom-width"
                      >
                        Reject
                      </Button>
                    </FormGroup>
                  </Col>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Col md={10} />
                  <Col md={2} className="right-btn-bottom">
                    <FormGroup>
                      <Link to={"/dashboard"}>
                        <Button className="btn-custom btn-custom-create btn-custom-width">
                          Close
                        </Button>
                      </Link>
                    </FormGroup>
                  </Col>
                </React.Fragment>
              )}
            </Row>
          ) : (
            ""
          )}
        </div>

        <Modal isOpen={this.state.voteModal} toggle={this.voteToggle}>
          <ModalHeader toggle={this.voteToggle}>Member vote</ModalHeader>
          <ModalBody>
            <FormGroup>
              <SelectRow
                name="votes"
                items={this.state.voteMembers}
                nameField={"displayName"}
                valueField={"employeeId"}
                multi={true}
                avatar={true}
                value={this.state.votes}
                onChange={this.onSelectChange}
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              className="btn-custom btn-custom-create"
              onClick={this.setVoteMembers}
            >
              Done
            </Button>{" "}
            <Button
              className="btn-custom btn-custom-create"
              onClick={this.voteToggle}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </main>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    decision: findElementByKey(
      state.decisions,
      "decisionId",
      ownProps.match.params.id
    ),
    employees: state.employees,
    workspaceId: state.auth.workspaceId,
    employeeId: state.auth.employeeId,
    token: state.auth.token,
    isValidate: state.auth.isValidate,
    currentPlan: state.account.currentPlan,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    swipeRowDecision: (data) => {
      return dispatch(swipeRowDecision(data));
    },
    getDecisionDetails: (data) => {
      return dispatch(getCoordinatorDecisionDetails(data));
    },
    errorAlert: (data) => {
      return dispatch(errorAlert(data));
    },
    successAlert: (data) => {
      return dispatch(successAlert(data));
    },
    warningAlert: (data) => {
      return dispatch(warningAlert(data));
    },
    updateDecisionDetailsCoordinator: (data) => {
      return dispatch(updateDecisionDetailsCoordinator(data));
    },
    updateDecisionDetailsApprover: (data) => {
      return dispatch(updateDecisionDetailsApprover(data));
    },
    getCommentsByRow: (data) => {
      return dispatch(getCommentsByRow(data));
    },
    addComment: (data) => {
      return dispatch(addComment(data));
    },
    deleteComment: (data) => {
      return dispatch(deleteComment(data));
    },
    loadEmployees: (data) => {
      return dispatch(loadEmployees(data));
    },
    addDecisionRow: (data) => {
      return dispatch(addDecisionRow(data));
    },

    addVote: (data) => {
      return dispatch(addVote(data));
    },

    updateDecisionRow: (data) => {
      return dispatch(updateDecisionRow(data));
    },
    deleteDecisionRow: (data) => {
      return dispatch(deleteDecisionRow(data));
    },
    createZoomMeeting: (data) => {
      return dispatch(createZoomMeeting(data));
    },
    rejectDecision: (data) => {
      return dispatch(rejectDecision(data));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateDecisionDetailsCoordinator);
