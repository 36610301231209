import React, { Component } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Row,
  Col,
  Button,
} from "reactstrap";

class SlackConnect extends Component {
  render() {
    return (
      <main className="connects" id="connects">
        <Row className="split-screen connects"></Row>
        <Row>
          <Col sm="4"></Col>
          <Col sm="4" className="pd-10">
            <Row>
              <Col sm="4"></Col>
              <Col sm="4">
                <img
                  src={"/consolve_logo.png"}
                  alt="workspace"
                  className="logo-img w-50"
                />
              </Col>
              <Col sm="4"></Col>
            </Row>
          </Col>
          <Col sm="4"></Col>
        </Row>
        <br />
        <Row>
          <Col sm="1"></Col>
          <Col sm="10" className="pd-10">
            <Card className="color-bg">
              <CardBody>
                <CardTitle>
                  <img src={"/assets/information.svg"} alt={"information"} />
                </CardTitle>
                <CardSubtitle>
                  <ul>
                    <li>
                      Connecting your Slack account will allow some people on
                      Consolve to see how to contact you on Slack
                    </li>
                    <br />
                    <li>
                      If you share links to Consolve content in Slack, Slack
                      will save and store that content and make it searchable
                      from Slack, even after it has been deleted from Consolve
                    </li>
                  </ul>
                </CardSubtitle>
                <div className="main-card">
                  <Row>
                    <Col md={4}></Col>
                    <Col md={4}>
                      <Button
                        onClick={() => window.close()}
                        className="btn-custom btn-custom-create"
                      >
                        Cancel
                      </Button>
                      &nbsp;&nbsp;{" "}
                      <a
                        href={
                          "https://slack.com/oauth/v2/authorize?scope=incoming-webhook&client_id=683906814500.787823368662"
                        }
                      >
                        <Button className="btn-custom btn-custom-create">
                          Got it
                        </Button>
                      </a>
                    </Col>
                    <Col md={4}></Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col sm="1"></Col>
        </Row>
      </main>
    );
  }
}

export default SlackConnect;
