import React from "react";

const Context = ({
  id,
  visible,
  btnVisible,
  x,
  y,
  isCoordinator,
  isApprover,
  status,
  deleteDecision,
  redirectTo,
  approvalSent,
  isMember,
}) =>
  visible && (
    <ul className="context-menu" style={{ left: `${x}px`, top: `${y}px` }}>
      {btnVisible && status !== "Finalized" && status !== "Cancelled" && (
        <li onClick={() => redirectTo("/decision/update/" + id)}>
          <img src={"/assets/edit_icon.svg"} alt="edit" />
          &nbsp; Template
        </li>
      )}
      {btnVisible && status === "Draft" && (
        <li onClick={() => deleteDecision(id)}>
          <img src={"/assets/delete_icon.svg"} alt="delete" />
          &nbsp; Delete
        </li>
      )}
    </ul>
  );

export default Context;
