export const REGISTER_EMAIL = "REGISTER_EMAIL";
export const VALIDATE_VERIFICATION_CODE = "VALIDATE_VERIFICATION_CODE";
export const CREATE_WORKSPACE = "CREATE_WORKSPACE";
export const INVITE_MEMBERS_FOR_WORKSPACE = "INVITE_MEMBERS_FOR_WORKSPACE";
export const SET_DATA_TO_LOCALSTORAGE = "SET_DATA_TO_LOCALSTORAGE";
export const VALIDATE_WORKSPACE = "VALIDATE_WORKSPACE";
export const VALIDATE_LOGIN = "VALIDATE_LOGIN";
export const FORGET_PASSWORD = "FORGET_PASSWORD";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const FIND_WORKSPACE = "FIND_WORKSPACE";
export const RESET_PASSWORD_FROM_INVITE = "RESET_PASSWORD_FROM_INVITE";
export const LOGOUT = "LOGOUT";
export const GOOGLE_LOGOUT = "GOOGLE_LOGOUT";
