import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Button,
  FormGroup,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Row,
  Col,
  Input,
  Spinner,
} from "reactstrap";
import ReeValidate from "ree-validate";
import { registerEmail } from "../../store/signupActions";
import { errorAlert } from "../../../web/store/alertActions";
import { websiteUrl } from "../../../../config/config";

class RegisterEmail extends Component {
  constructor(props) {
    super(props);

    this.validator = new ReeValidate({
      email: "required|email",
    });
    this.timeout = null;
    this.state = {
      isSubmiting: false,
      formData: {
        email: "",
      },
      errors: this.validator.errors,
      isDisable: true,
    };
  }

  componentDidMount() {
    if (this.props.isAuthenticated) {
      this.props.history.push("/dashboard");
    }
  }

  handleChange = (e) => {
    this.setErrors();
    setTimeout(() => {
      this.setDisabled();
    }, 10);
    const name = e.target.name;
    const value = e.target.value;
    this.setState({
      formData: { ...this.state.formData, [name]: value },
    });
    this.validator.reset();
  };

  setErrors = () => {
    const { errors } = this.validator;
    this.setState({ errors });
  };

  setDisabled = () => {
    this.setState({ isDisable: this.state.errors.items.length > 0 });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { formData } = this.state;
    const { errors } = this.validator;
    this.validator.validateAll(formData).then((success) => {
      if (success) {
        this.setState({ isSubmiting: true });
        const payloadData = new FormData();
        payloadData.append("emailAddress", this.state.formData.email);
        this.props
          .registerEmail(payloadData)
          .then(() => {
            this.props.history.push("/validate-verification-code");
          })
          .catch((err) => {
            this.setState({ isSubmiting: false });
            this.props.errorAlert("Something went wrong, please try again!");
          });
      } else {
        this.setState({ errors });
      }
    });
  };

  render() {
    return (
      <main>
        <Row className="split-screen"></Row>
        <Row>
          <Col sm="4"></Col>
          <Col sm="4" className="pd-10">
            <Row>
              <Col sm="4"></Col>
              <Col sm="4">
                <a href={websiteUrl} alt="website">
                  <img
                    src={"/assets/consolve-icon.png"}
                    alt="workspace"
                    className="logo-img w-50"
                  />
                </a>
              </Col>
              <Col sm="4"></Col>
            </Row>
          </Col>
          <Col sm="4"></Col>
        </Row>
        <br />
        <Row>
          <Col sm="4"></Col>
          <Col sm="4" className="pd-10">
            <Card className="color-bg">
              <CardBody>
                <CardTitle>Start with an Email Address</CardTitle>
                <CardSubtitle>
                  Before you create a workspace please verify your email
                  address. Workspace can be a Team or a Company or a Department.
                </CardSubtitle>
                <div className="main-card">
                  <form onSubmit={this.handleSubmit}>
                    <FormGroup>
                      <Input
                        type="email"
                        name="email"
                        id="email"
                        onChange={this.handleChange}
                        placeholder="Email address"
                      />
                    </FormGroup>
                    <Button
                      className="btn-custom"
                      color="primary"
                      size="lg"
                      block
                      disabled={this.state.isDisable}
                    >
                      Confirm {this.state.isSubmiting && <Spinner size="sm" />}
                    </Button>

                    {this.state.errors.has("email") && this.timeout === null && (
                      <label id="name-error" className="error" htmlFor="email">
                        {this.state.errors.first("email")}
                      </label>
                    )}
                  </form>
                  <br />
                  <img
                    alt="workspace"
                    src={"/assets/signup_2.svg"}
                    className="w-50"
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col sm="4"></Col>
        </Row>
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    registerEmail: (data) => {
      return dispatch(registerEmail(data));
    },
    errorAlert: (data) => {
      return dispatch(errorAlert(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterEmail);
