import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col } from "reactstrap";
import { errorAlert, successAlert } from "../../web/store/alertActions";
import {
  getAllIntegrations,
  disconnectFromSlack,
  disconnectFromZoom,
} from "../store/accountActions";
import { slackConnectUrl, zoomConnectUrl } from "../../../config/config";

class Integrations extends Component {
  componentDidMount() {
    if (!this.props.workspaceId) {
      this.props.history.push("/");
    } else {
      if (this.props.isValidate) this.getIntegrations();
    }
  }

  getIntegrations = () => {
    const payloadData = new FormData();
    payloadData.append("token", this.props.token);
    payloadData.append("employeeId", this.props.employeeId);
    payloadData.append("workspaceId", this.props.workspaceId);
    this.props.getAllIntegrations(payloadData);
  };

  connectSlack = () => {
    window.location.href = slackConnectUrl + "&state=" + this.props.workspaceId;
  };

  connectZoom = () => {
    window.location.href = zoomConnectUrl + "&state=" + this.props.employeeId;
  };

  disconnectSlack = () => {
    const payloadData = new FormData();
    payloadData.append("token", this.props.token);
    payloadData.append("employeeId", this.props.employeeId);
    payloadData.append("workspaceId", this.props.workspaceId);
    this.props
      .disconnectFromSlack(payloadData)
      .then(() => {
        this.props.successAlert("Slack disconnected.");
        this.getIntegrations();
      })
      .catch(() => {
        this.props.errorAlert("Something went wrong, please try again!");
      });
  };

  disconnectZoom = () => {
    const payloadData = new FormData();
    payloadData.append("token", this.props.token);
    payloadData.append("employeeId", this.props.employeeId);
    payloadData.append("workspaceId", this.props.workspaceId);
    this.props
      .disconnectFromZoom(payloadData)
      .then(() => {
        this.props.successAlert("Zoom disconnected.");
        this.getIntegrations();
      })
      .catch(() => {
        this.props.errorAlert("Something went wrong, please try again!");
      });
  };

  render() {
    if (!this.props.profile.emailAddress) {
      return (
        <main className="create-main">
          <div className="loader"></div>
        </main>
      );
    }

    let slack = false;
    let zoom = false;
    this.props.integrations.map((integration) => {
      if (integration.type === "Slack" && integration.connected === true) {
        slack = true;
      }
      if (integration.type === "Zoom" && integration.connected === true) {
        zoom = true;
      }
      return true;
    });

    return (
      <main className="create-main">
        <br />
        <div className="create-decision container">
          <br />
          <h5 className="page-title">Connect apps to Consolve</h5>
          <p>
            Integrated features from these apps make it easier to collaborate
            with people you know on Consolve.
          </p>
          {parseInt(this.props.profile.isAdmin) !== 0 && (
            <>
              <hr />
              <Row>
                <Col md={1}>
                  <img
                    className={"header-profile-image-default"}
                    src={"/assets/slack_icon.svg"}
                    alt={"slack"}
                  />
                </Col>
                <Col md={9}>
                  <span className="comment-text notification-text">Slack</span>
                  <br />
                  <span className="creator-text">
                    Helps send Slack notifications for decisions.
                  </span>
                </Col>
                <Col md={2} className="connect-links">
                  {slack ? (
                    <span className="cursor-pointer">
                      Connected{" "}
                      <span
                        className="btn-cust-close"
                        onClick={() => this.disconnectSlack()}
                      >
                        <img src={"/assets/cross.svg"} alt="X" />
                      </span>
                    </span>
                  ) : (
                    <span
                      className="cursor-pointer"
                      onClick={this.connectSlack}
                    >
                      Connect to Slack
                    </span>
                  )}
                </Col>
              </Row>
            </>
          )}
          <hr />
          <Row>
            <Col md={1}>
              <img
                className={"header-profile-image-default"}
                src={"/assets/zoom_icon.svg"}
                alt={"zoom"}
              />
            </Col>
            <Col md={9}>
              <span className="comment-text notification-text">Zoom</span>
              <br />
              <span className="creator-text">
                Helps you auto-generate and share zoom invite for the meeting.
              </span>
            </Col>
            <Col md={2} className="connect-links cursor-pointer">
              {zoom ? (
                <span className="cursor-pointer">
                  Connected{" "}
                  <span
                    className="btn-cust-close"
                    onClick={() => this.disconnectZoom()}
                  >
                    <img src={"/assets/cross.svg"} alt="X" />
                  </span>
                </span>
              ) : (
                <span className="cursor-pointer" onClick={this.connectZoom}>
                  Connect to Zoom
                </span>
              )}
            </Col>
          </Row>
          <hr />
          <br />
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    employeeId: state.auth.employeeId,
    workspaceId: state.auth.workspaceId,
    integrations: state.account.integrations,
    profile: state.account.profile,
    isValidate: state.auth.isValidate,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    successAlert: (data) => {
      return dispatch(successAlert(data));
    },
    errorAlert: (data) => {
      return dispatch(errorAlert(data));
    },
    getAllIntegrations: (data) => {
      return dispatch(getAllIntegrations(data));
    },
    disconnectFromSlack: (data) => {
      return dispatch(disconnectFromSlack(data));
    },
    disconnectFromZoom: (data) => {
      return dispatch(disconnectFromZoom(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Integrations);
