import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Table, Col } from "reactstrap";
import InfiniteScroll from "react-infinite-scroller";
import { getNotifications } from "../store/accountActions";
import { errorAlert } from "../../web/store/alertActions";
import { scrollDataLimit } from "../../../constants";
import { notifiedOn, formateTimeUTCToLocal } from "../../../utilities/helpers";
import { Link } from "react-router-dom";

class Notifications extends Component {
  constructor(props) {
    super(props);

    this.state = {
      offset: 0,
      hasMoreData: true,
      loading: true
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.props.token) {
      this.loadNotifications();
    } else {
      this.props.history.push("/");
    }
  }

  loadNotifications = () => {
    const payloadData = new FormData();
    payloadData.append("token", this.props.token);
    payloadData.append("employeeId", this.props.employeeId);
    payloadData.append("workspaceId", this.props.workspaceId);
    payloadData.append("offset", 0);
    payloadData.append("limit", scrollDataLimit);
    this.props
      .getNotifications(payloadData)
      .then(async response => {
        await this.setHasMore();
        await this.setOffset();
        this.setState({ loading: false });
      })
      .catch(e => {
        this.setHasMore();
        this.setState({ loading: false });
        this.props.errorAlert("Something went wrong with search decisions!");
      });
  };

  setHasMore = async (offset = this.state.offset + scrollDataLimit) => {
    if (offset !== this.props.notifications.length) {
      await this.setState({ hasMoreData: false });
    }
  };

  setOffset = async (offset = this.state.offset + scrollDataLimit) => {
    await this.setState({ offset, sorting: false });
  };

  loadMoreNotifications = async () => {
    if (!this.state.loading) {
      await this.setState({ loading: true });
      await this.loadNotifications();
    }
  };

  render() {
    return (
      <main>
        <div className="dashboard-custom container">
          <br />
          <Row>
            <h5 className="page-title float-left">Notifications</h5>
          </Row>
          <br />
          <InfiniteScroll
            pageStart={this.state.offset}
            loadMore={this.loadMoreNotifications}
            hasMore={this.state.hasMoreData}
            loader={
              <Row key={0} className="container justify-content-center">
                <div className="loader"></div>
              </Row>
            }
          >
            <Row className="table-dashboard justify-content-center">
              <Table className="table-custom" responsive hover>
                <tbody>
                  {!this.state.sorting &&
                    this.props.notifications.map((notification, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <Link
                              to={
                                notification.decisionStatus === "Finalized" ||
                                notification.decisionStatus === "Cancelled"
                                  ? "/decision/view/" + notification.decisionId
                                  : notification.decisionCoordinatorId ===
                                    this.props.employeeId
                                  ? "/decision/coordinator/" +
                                    notification.decisionId
                                  : (notification.decisionIsApprover  ===
                                    this.props.employeeId && +notification.decisionApprovalSent)
                                  ? "/decision/coordinator/" + notification.decisionId
                                  : notification.decisionIsMember  ===
                                    this.props.employeeId
                                  ? "/decision/member/" + notification.decisionId
                                  : "/decision/view/" + notification.decisionId
                              }
                              className="dropdown-links"
                            >
                              <Row>
                                <Col md={1}>
                                  <div style={{backgroundImage: `url(${notification.photoUrl
                                        ? notification.photoUrl
                                        : "/assets/no-image.png"})`}} className="not-img-cover">
                                  </div>
                                </Col>
                                <Col md={11} className="not-div">
                                  <strong className="not-username">
                                  {notification.activityEmployeeName}
                                  </strong>{" "}
                                  {notification.activityString}
                                  <br />
                                  <span className="creator-text">
                                    {notifiedOn(
                                      formateTimeUTCToLocal(
                                        notification.activityCreatedOn
                                      )
                                    )}
                                  </span>
                                </Col>
                              </Row>
                            </Link>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </Row>
          </InfiniteScroll>
          <br />
        </div>
      </main>
    );
  }
}

const mapStateToProps = state => {
  return {
    notifications: state.account.notifications,
    workspaceId: state.auth.workspaceId,
    employeeId: state.auth.employeeId,
    token: state.auth.token,
    isValidate: state.auth.isValidate
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getNotifications: data => {
      return dispatch(getNotifications(data));
    },
    errorAlert: data => {
      return dispatch(errorAlert(data));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
