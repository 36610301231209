import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Row,
  Col,
  Button,
} from "reactstrap";
import { setDataToLocalstorage } from "../../store/signupActions";
import { websiteUrl, httpHost, clientHost } from "../../../../config/config";
import { objectToQueryParameters } from "../../../../utilities/helpers";

class WelcomeSignup extends Component {
  constructor(props) {
    super(props);

    this.timeout = null;
    this.state = {
      emails: ["", "", ""],
      errors: [],
      currentInput: "",
      isDisable: true,
    };
  }

  componentDidMount() {
    if (!this.props.workspaceName) {
      this.props.history.push("/register-email");
    }
    if (this.props.isAuthenticated) {
      this.props.history.push("/dashboard");
    }
  }

  setData = () => {
    let data = {
      emailAddress: this.props.emailAddress,
      employeeId: this.props.employeeId,
      workspaceId: this.props.workspaceId,
      token: this.props.token,
      workspaceName: this.props.workspaceName,
    };
    let origin =
      httpHost +
      this.props.workspaceName +
      "." +
      clientHost +
      "?" +
      objectToQueryParameters(data);
    setTimeout(() => {
      window.location.href = origin;
    }, 200);
  };

  render() {
    return (
      <main>
        <Row className="split-screen"></Row>
        <Row>
          <Col sm="4"></Col>
          <Col sm="4" className="pd-10">
            <Row>
              <Col sm="4"></Col>
              <Col sm="4">
                <a href={websiteUrl} alt="website">
                  <img
                    src={"/assets/consolve-icon.png"}
                    alt="workspace"
                    className="logo-img w-50"
                  />
                </a>
              </Col>
              <Col sm="4"></Col>
            </Row>
          </Col>
          <Col sm="4"></Col>
        </Row>
        <br />
        <Row>
          <Col sm="4"></Col>
          <Col sm="4" className="pd-10">
            <Card className="color-bg">
              <CardBody>
                <CardTitle>
                  {this.props.workspaceName}! Welcome to consolve!
                </CardTitle>
                <CardSubtitle className="text-align-center">
                  Consolve helps them make the data driven decisions using
                  Delphi model of decision making.
                </CardSubtitle>
                <div className="main-card">
                  <img
                    className="w-50"
                    alt="workspace"
                    src={"/assets/signup_tada.svg"}
                  />
                  <br />
                  <Button
                    className="btn-custom"
                    color="primary"
                    size="lg"
                    onClick={this.setData}
                    block
                  >
                    Go to Dashboard
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col sm="4"></Col>
        </Row>
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    emailAddress: state.auth.email,
    employeeId: state.auth.employeeId,
    token: state.auth.token,
    workspaceId: state.auth.workspaceId,
    workspaceName: state.auth.workspaceName,
    isAuthenticated: state.auth.isAuthenticated,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setDataToLocalstorage: (data, origin) => {
      return dispatch(setDataToLocalstorage(data, origin));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WelcomeSignup);
