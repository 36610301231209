import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Button,
  Table,
  Input,
  Label,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Alert,
} from "reactstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Link } from "react-router-dom";
import {
  swipeRowDecision,
  getDecisionDetails,
  updateDecisionDetailsMember,
  getCommentsByRow,
  addComment,
  deleteComment,
  addDecisionRow,
  updateDecisionRow,
  deleteDecisionRow,
  addVote,
} from "../store/decisionActions";
import ReeValidate from "ree-validate";
import {
  errorAlert,
  successAlert,
  warningAlert,
} from "../../web/store/alertActions";
import {
  findElementByKey,
  formatedDate,
  updateObjectByDoubleKey,
  updateObjectByKey,
  formatedTime12,
  formateTimeUTCToLocal,
  getStatusIdByName,
} from "../../../utilities/helpers";
import Comments from "./Comments";
import {
  expirePlanType,
  expirePlanAlertMessage,
  expirePlanStatus,
  autoSaveTimeout,
} from "../../../constants";
import ColumnResizer from "column-resizer";
import Editor from "../../../common/editor";
import HtmlRendor from "../../../common/html-rendor";
import AvatarLabel from "../../../common/avatar-label";
import Avatar from "react-avatar";
import ReactDragListView from "react-drag-listview/lib/index.js";
import Tooltip from "react-simple-tooltip";
import {css} from "styled-components";
import FirePadEditor from '../../../common/firepadeditor/Editor'

class CreateDecisionDetailsMember extends Component {
  constructor(props) {
    super(props);

    this.validator = new ReeValidate({
      comment: "required",
      anonymous: "",
      collabNoteText: "",
    });

    this.autoSaveRows = [];
    this.autoUpdateRows = [];

    this.state = {
      commentAddbtn: true,
      columnLength: 100,
      columns: [],
      addRows: [],
      updateRows: [],
      deleteRows: [],
      tableRows: [],
      editInput: 0,
      anonymous: "",
      collabNoteText: "",
      copyText: "Copy",
      rowComment: 0,
      comment: "",
      updateRowsNo: [],
      autoSaveRows: [],
      autoUpdateRows: [],
      commentModal: false,
      errors: this.validator.errors,
      tooltipOpen: "",
      quetooltipopen: false,
      dragRows: [],
    };
  }

  componentDidMount() {
    if (this.props.token) {
      if (this.props.isValidate) this.getDecisionDetails();
    } else {
      this.props.history.push("/");
    }
    this.enableResize();
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (
      props.decision &&
      props.decision.decisionColumns &&
      !props.decision.comments
    ) {
      this.updateCurrentState(props);
    }
  }

  componentWillUnmount() {
    for (let i = 0; i < this.autoSaveRows.length; i++) {
      clearTimeout(this.autoSaveRows[i]);
    }
    for (let i = 0; i < this.autoUpdateRows.length; i++) {
      clearTimeout(this.autoUpdateRows[i]);
    }
    this.disableResize();
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  componentDidUpdate() {
    this.enableResize();
  }

  componentWillUpdate() {
    this.disableResize();
  }

  handleClickOutside = (event) => {
    if (this.table && !this.table.contains(event.target)) {
      this.setEditInput(0);
    }
  };

  addVote = (pindex) => {
    const payloadData = new FormData();
    payloadData.append("decisionId", this.props.decision.decisionId);
    payloadData.append("rowNo", pindex);
    payloadData.append("employeeId", this.props.employeeId);

    this.props.addVote(payloadData).then(() => {
      this.getDecisionDetails();
      this.resetState();
    });
  };

  stripHtml = (html) => {
    let temporalDivElement = document.createElement("div");
    // Set the HTML content with the providen
    temporalDivElement.innerHTML = html;
    // Retrieve the text property of the element (cross-browser support)
    return temporalDivElement.textContent || temporalDivElement.innerText || "";
  };

  enableResize = () => {
    const options = {
      liveDrag: true,
      disabledColumns: [this.state.columnLength - 1],
      flush: true,
      draggingClass: "rangeDrag",
      removePadding: false,
      resizeMode: "overflow",
    };
    if (!this.resizer) {
      if (this.table) {
        this.resizer = new ColumnResizer(this.table, options);
      }
    } else {
      this.resizer.reset(options);
    }
  };

  disableResize() {
    if (this.resizer) {
      this.resizer.reset({ disable: true });
    }
  }

  getDecisionDetails = () => {
    const requestData = new FormData();
    requestData.append("decisionId", this.props.match.params.id);
    requestData.append("workspaceId", this.props.workspaceId);
    requestData.append("employeeId", this.props.employeeId);
    requestData.append("token", this.props.token);
    this.props.getDecisionDetails(requestData);
  };

  updateCurrentState = async (props) => {
    let updateRow = [];
    let editText = [];
    props.decision.decisionColumns.reduce((r, a) => {
      a.columnDetails.map((b, i) => {
        editText[b.columnDetailId] = b.detailText;
        if (b.columnDetailId !== "") {
          return updateRow.push({
            columnDetailId: b.columnDetailId,
            columnId: b.decisionColumnId,
            detailText: b.detailText,
            points: b.points,
            rowNo: b.rowNo,
          });
        } else {
          return true;
        }
      });
      return r;
    }, []);
    this.setTableRows(props);
    this.setState({
      updateRows: updateRow,
      editText: editText,
      columnLength: props.decision.decisionColumns.length,
    });
  };

  setTableRows = (props) => {
    let array = [];
    let dataArray = [];
    let idrag = 0;
    props.decision.decisionColumns.reduce((r, a) => {
      a.columnDetails.map((b, i) => {
        if (typeof array[b.rowNo] !== "undefined") {
          return array[b.rowNo].push({
            text: b.detailText || "",
            points: b.points,
            rowNo: b.rowNo,
            employeeId: b.employeeId,
            columnDetailId: b.columnDetailId,
            columnId: b.decisionColumnId,
            creatorId: b.employeeId,
            employeeName: b.employeeName,
            employeePhoto: b.employeePhoto,
            vote: b.vote,
            votername: b.voterName,
          });
        } else {
          dataArray.push(idrag);
          idrag = parseInt(idrag) + 1;
          return (array[b.rowNo] = [
            {
              text: b.detailText || "",
              points: b.points,
              rowNo: b.rowNo,
              employeeId: b.employeeId,
              columnDetailId: b.columnDetailId,
              columnId: b.decisionColumnId,
              creatorId: b.employeeId,
              employeeName: b.employeeName,
              employeePhoto: b.employeePhoto,
              vote: b.vote,
              votername: b.voterName,
            },
          ]);
        }
      });
      return r;
    }, []);

    this.setState({ tableRows: array, dragRows: dataArray });
  };

  commentToggle = () => {
    this.setState({
      commentModal: !this.state.commentModal,
      comment: "",
    });
    this.validator.reset();
    this.setState({
      commentAddbtn: true,
    });
  };

  viewComment = (id) => {
    if (id === this.state.rowComment) {
      return true;
    }

    this.setState({
      rowComment: id,
    });

    this.getComments(id);
  };

  getComments = (id) => {
    this.setState({
      rowComment: id,
    });

    const payloadData = new FormData();
    payloadData.append("token", this.props.token);
    payloadData.append("employeeId", this.props.employeeId);
    payloadData.append("workspaceId", this.props.workspaceId);
    payloadData.append("rowNo", id);
    payloadData.append("decisionId", this.props.decision.decisionId);
    this.props.getCommentsByRow(payloadData);
  };

  addComment = () => {
    if (parseInt(this.props.currentPlan) === expirePlanType) {
      this.props.warningAlert(expirePlanAlertMessage);
      return true;
    }
    const form = { comment: this.state.comment };
    const { errors } = this.validator;
    this.validator.validateAll(form).then((success) => {
      if (success) {
        const payloadData = new FormData();
        payloadData.append("token", this.props.token);
        payloadData.append("employeeId", this.props.employeeId);
        payloadData.append("workspaceId", this.props.workspaceId);
        payloadData.append("rowNo", this.state.rowComment);
        payloadData.append("comment", this.state.comment);
        payloadData.append("decisionId", this.props.decision.decisionId);
        this.props
          .addComment(payloadData)
          .then(() => {
            this.getComments(this.state.rowComment);
            this.commentToggle();
            this.props.successAlert("Comment added.");
          })
          .catch(() => {
            this.props.errorAlert("Something went wrong, please try again!");
          });
      } else {
        this.setState({ errors });
      }
    });
  };

  deleteComment = (id) => {
    if (parseInt(this.props.currentPlan) === expirePlanType) {
      this.props.warningAlert(expirePlanAlertMessage);
      return true;
    }
    const payloadData = new FormData();
    payloadData.append("token", this.props.token);
    payloadData.append("employeeId", this.props.employeeId);
    payloadData.append("workspaceId", this.props.workspaceId);
    payloadData.append("commentId", id);
    payloadData.append("decisionId", this.props.decision.decisionId);
    this.props
      .deleteComment(payloadData)
      .then(() => {
        this.getComments(this.state.rowComment);
        this.props.successAlert("Comment deleted successfully.");
      })
      .catch(() => {
        this.props.errorAlert("Something went wrong, please try again!");
      });
  };

  add = async () => {
    let columns = await this.state.columns.filter((row) => {
      return row || row === "";
    });
    await this.setState({ columns: [...columns, ""] });
    this.updateRow();
  };

  handleDetailsChange = async (value, decisionId, index) => {
    let obj = {
      columnId: decisionId,
      index: index + 1,
      value: value,
    };
    this.setState({
      addRows: updateObjectByDoubleKey(
        this.state.addRows,
        obj,
        "columnId",
        "index"
      ),
    });
    this.autoSave(index);
  };

  handleEditorChange = (content, name) => {
    const value = content;
    const { errors } = this.validator;
    errors.remove(name);
    this.setState({ [name]: value });
    this.validator.validate(name, value).then(() => {
      this.setState({ errors });
    });
    if (value.trim() !== "") {
      this.setState({ commentAddbtn: false });
    } else {
      this.setState({ commentAddbtn: true });
    }
  };

  autoSave = (pindex) => {
    let autoSaveRows = this.state.autoSaveRows;
    if (!autoSaveRows.includes(pindex)) {
      autoSaveRows.push(pindex);
      this.setState({ autoSaveRows });
    } else {
      clearTimeout(this.autoSaveRows[pindex]);
    }
    this.autoSaveRows[pindex] = setTimeout(() => {
      clearTimeout(this.autoSaveRows[pindex]);
      this.addDecisionRow(pindex);
    }, autoSaveTimeout);
  };

  handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    this.setState({
      [name]: value,
    });
    this.validator.validate(name, value).then(() => {
      const { errors } = this.validator;
      this.setState({ errors });
    });
  };

  updateRow = () => {
    let rowNo = 0,
      columns = this.state.addRows;
    if (columns.length) {
      rowNo = parseInt(columns[columns.length - 1].rowNo) + 1;
    } else {
      columns = this.props.decision.decisionColumns;
      if (columns.length && columns[0].columnDetails.length) {
        rowNo =
          parseInt(
            columns[0].columnDetails[columns[0].columnDetails.length - 1].rowNo
          ) + 1;
      }
    }
    this.props.decision.decisionColumns.map((column) => {
      //  setTimeout(() => {
      this.setState({
        addRows: updateObjectByDoubleKey(
          this.state.addRows,
          {
            columnId: column.decisionColumnId,
            index: this.state.columns.length,
            value: "",
            rowNo: rowNo,
          },
          "columnId",
          "index"
        ),
      });
      //     }, 100);
      return true;
    });
  };

  handleUpdateDetailsChange = async (
    value,
    detailsId,
    columnId,
    rowNo,
    other,
    detail
  ) => {
    let obj = {
      columnDetailId: detailsId,
      columnId: columnId,
      detailText: value,
      points: other,
      rowNo: rowNo,
    };
    let editText = this.state.editText;
    editText[detailsId] = value;
    this.setState({ editText });
    let updateRowsNo = this.state.updateRows;
    if (!updateRowsNo.includes(rowNo)) {
      updateRowsNo.push(rowNo);
    }
    await this.setState({
      updateRows: updateObjectByKey(
        this.state.updateRows,
        obj,
        "columnDetailId"
      ),
      updateRowsNo,
    });
    this.autoUpdate(rowNo, detail);
  };

  autoUpdate = (pindex, details) => {
    let autoUpdateRows = this.state.autoUpdateRows;
    if (!autoUpdateRows.includes(pindex)) {
      autoUpdateRows.push(pindex);
      this.setState({ autoUpdateRows });
    } else {
      clearTimeout(this.autoUpdateRows[pindex]);
    }
    let autoUpdateDetails = this.state.autoUpdateRows;
    autoUpdateDetails[pindex] = details;
    this.setState({ autoUpdateRows: autoUpdateDetails });
    this.autoUpdateRows[pindex] = setTimeout(() => {
      clearTimeout(this.autoUpdateRows[pindex]);
      this.updateDecisionRow(pindex, this.state.autoUpdateRows[pindex]);
    }, autoSaveTimeout);
  };

  setEditInput = (id) => {
    this.setState({
      editInput: id,
    });
  };

  handleSubmit = async () => {
    if (parseInt(this.props.currentPlan) === expirePlanType) {
      this.props.warningAlert(expirePlanAlertMessage);
      return true;
    }
    for (let i = 0; i < this.autoSaveRows.length; i++) {
      clearTimeout(this.autoSaveRows[i]);
    }
    for (let i = 0; i < this.autoUpdateRows.length; i++) {
      clearTimeout(this.autoUpdateRows[i]);
    }
    let array = [],
      rowNo = 0,
      columns = this.props.decision.decisionColumns;
    if (columns.length && columns[0].columnDetails.length) {
      rowNo =
        columns[0].columnDetails[columns[0].columnDetails.length - 1].rowNo;
    }
    await this.state.addRows.map((row) => {
      let index = array.findIndex((arr) => arr.columnId === row.columnId);
      if (index > -1) {
        return array[index].rows.push({
          rowNo: parseInt(rowNo) + row.index,
          detailText: row.value,
        });
      } else {
        return array.push({
          columnId: row.columnId,
          rows: [
            {
              rowNo: parseInt(rowNo) + row.index,
              detailText: row.value,
            },
          ],
        });
      }
    });

    let updateRows = [];
    await this.state.updateRows.map((row) => {
      let index = updateRows.findIndex((arr) => arr.columnId === row.columnId);
      if (index > -1) {
        return updateRows[index].rows.push({
          detailText: row.detailText,
          columnDetailId: row.columnDetailId,
        });
      } else {
        return updateRows.push({
          columnId: row.columnId,
          rows: [
            {
              detailText: row.detailText,
              columnDetailId: row.columnDetailId,
            },
          ],
        });
      }
    });

    const payloadData = new FormData();
    payloadData.append("token", this.props.token);
    payloadData.append("employeeId", this.props.employeeId);
    payloadData.append("workspaceId", this.props.workspaceId);
    payloadData.append("coordinatorId", this.props.decision.coordinatorId);
    payloadData.append("anonymousFeedbackText", this.state.anonymous);
    payloadData.append("collabNoteText", this.state.collabNoteText);
    payloadData.append("decisionId", this.props.decision.decisionId);
    payloadData.append("updateRows", JSON.stringify(updateRows));
    payloadData.append("deleteRows", JSON.stringify(this.state.deleteRows));
    payloadData.append("addRows", JSON.stringify(array));

    this.props
      .updateDecisionDetailsMember(payloadData)
      .then((success) => {
        this.props.successAlert("Decision details updated successfully.");
        this.props.history.push("/dashboard");
      })
      .catch((err) => {
        if (parseInt(err.data.status) === expirePlanStatus) {
          this.props.warningAlert(expirePlanAlertMessage);
        } else {
          this.props.errorAlert("Something went wrong, please try again!");
        }
      });
  };

  resetState = () => {
    this.setState({
      columns: [],
      addRows: [],
      deleteRows: [],
      anonymous: "",
      collabNoteText: "",
    });
  };

  setFormData = () => {
    const payloadData = new FormData();
    payloadData.append("token", this.props.token);
    payloadData.append("employeeId", this.props.employeeId);
    payloadData.append("workspaceId", this.props.workspaceId);
    return payloadData;
  };

  deleteDetails = (details) => {
    if (parseInt(this.props.currentPlan) === expirePlanType) {
      this.props.warningAlert(expirePlanAlertMessage);
      return true;
    }
    const payloadData = this.setFormData();
    let deleteRows = details.map((detail) => detail.columnDetailId);
    payloadData.append("rowNo", details[0].rowNo);
    payloadData.append("decisionId", this.props.decision.decisionId);
    payloadData.append("deleteRows", JSON.stringify(deleteRows));
    this.props
      .deleteDecisionRow(payloadData)
      .then(() => {
        this.props.successAlert("Row deleted successfully.");
        this.getDecisionDetails();
        this.resetState();
      })
      .catch((err) => {
        if (parseInt(err.data.status) === expirePlanStatus) {
          this.props.warningAlert(expirePlanAlertMessage);
        } else {
          this.props.errorAlert("Something went wrong, please try again!");
        }
      });
  };

  handleCopy = () => {
    this.setState({
      copyText: "Copied",
    });
    setTimeout(() => {
      this.setState({
        copyText: "Copy",
      });
    }, 2000);
  };

  addDecisionRow = async (pindex) => {
    if (parseInt(this.props.currentPlan) === expirePlanType) {
      this.props.warningAlert(expirePlanAlertMessage);
      return true;
    }

    if (this.autoSaveRows[pindex]) {
      clearTimeout(this.autoSaveRows[pindex]);
    }
    let addRows = [];
    let emptyFields = true;
    let genRow = pindex;

    await this.state.addRows.map((row) => {
      if (row.index === pindex + 1) {
        if (row.value) emptyFields = false;
        let index = addRows.findIndex((arr) => arr.columnId === row.columnId);
        if (index > -1) {
          return addRows[index].rows.push({
            rowNo: row.rowNo,
            detailText: row.value,
            points: 0,
          });
        } else {
          return addRows.push({
            columnId: row.columnId,
            rows: [
              {
                rowNo: row.rowNo,
                detailText: row.value,
                points: 0,
              },
            ],
          });
        }
      } else {
        return true;
      }
    });
    if (emptyFields) {
      return true;
    }

    let updateRows = [];

    await this.state.updateRows.map((row) => {
      let index = updateRows.findIndex((arr) => arr.columnId === row.columnId);
      if (index > -1) {
        return updateRows[index].rows.push({
          rowNo: row.rowNo,
          detailText: row.detailText,
          columnDetailId: row.columnDetailId,
          points: row.points,
        });
      } else {
        return updateRows.push({
          columnId: row.columnId,
          rows: [
            {
              rowNo: row.rowNo,
              detailText: row.detailText,
              columnDetailId: row.columnDetailId,
              points: row.points,
            },
          ],
        });
      }
    });

    const payloadData = this.setFormData();
    addRows.sort((a, b) =>
      parseInt(a.columnId) > parseInt(b.columnId) ? 1 : -1
    );
    payloadData.append("addRows", JSON.stringify(addRows));
    payloadData.append("updateRows", JSON.stringify(updateRows));

    this.props
      .addDecisionRow(payloadData)
      .then(() => {
        this.props.successAlert("Row added successfully.");
        this.getDecisionDetails();
        this.setState({
          updateRowsNo: [],
        });
        this.deleteAddColumns(genRow);
      })
      .catch((err) => {
        if (parseInt(err.data.status) === expirePlanStatus) {
          this.props.warningAlert(expirePlanAlertMessage);
        } else {
          this.props.errorAlert("Something went wrong, please try again!");
        }
      });
  };

  mouseOut = async (e, details, rowNumber) => {
    if (this.state.updateRowsNo.includes(rowNumber)) {
      this.setEditInput(0, "");
      this.updateDecisionRow(e, details);
    }
  };

  updateDecisionRow = async (e, details) => {
    if (parseInt(this.props.currentPlan) === expirePlanType) {
      this.props.warningAlert(expirePlanAlertMessage);
      return true;
    }

    let addRows = [];
    let rowNo = 0;
    let decisionColumnDetails = this.props.decision.decisionColumnDetails;

    if (decisionColumnDetails !== undefined) {
      if (decisionColumnDetails.length > 0) {
        rowNo = decisionColumnDetails[decisionColumnDetails.length - 1].rowNo;
      }
    }

    await this.state.addRows.map((row) => {
      let index = addRows.findIndex((arr) => arr.columnId === row.columnId);
      if (index > -1) {
        return addRows[index].rows.push({
          rowNo: parseInt(rowNo) + row.index,
          detailText: row.value,
          points: row.points != undefined ? row.points : 0,
        });
      } else {
        return addRows.push({
          columnId: row.columnId,
          rows: [
            {
              rowNo: parseInt(rowNo) + row.index,
              detailText: row.value,
              points: row.points != undefined ? row.points : 0,
            },
          ],
        });
      }
    });

    let updateRows = [];

    await this.state.updateRows.map((row) => {
      let index = updateRows.findIndex((arr) => arr.columnId === row.columnId);
      if (index > -1) {
        return updateRows[index].rows.push({
          rowNo: row.rowNo,
          detailText: row.detailText,
          columnDetailId: row.columnDetailId,
          points: row.points,
        });
      } else {
        return updateRows.push({
          columnId: row.columnId,
          rows: [
            {
              rowNo: row.rowNo,
              detailText: row.detailText,
              columnDetailId: row.columnDetailId,
              points: row.points,
            },
          ],
        });
      }
    });

    const payloadData = this.setFormData();
    payloadData.append("updateRows", JSON.stringify(updateRows));
    payloadData.append("addRows", JSON.stringify(addRows));
    this.props
      .updateDecisionRow(payloadData)
      .then(() => {
        this.props.successAlert("Row updated successfully.");
        this.getDecisionDetails();
        this.setState({
          columns: [],
          addRows: [],
          updateRows: [],
          deleteRows: [],
          updateRowsNo: [],
        });
        this.deleteRowFromUpdate(rowNo);
      })
      .catch((err) => {
        if (parseInt(err.data.status) === expirePlanStatus) {
          this.props.warningAlert(expirePlanAlertMessage);
        } else {
          this.props.errorAlert("Something went wrong, please try again!");
        }
        this.deleteRowFromUpdate(rowNo);
      });
  };

  deleteRowFromUpdate = (rowNo) => {
    let updateRowsNo = this.state.updateRowsNo;
    var index = updateRowsNo.indexOf(rowNo);
    if (index > -1) {
      updateRowsNo.splice(index, 1);
    }
    this.setState({ updateRowsNo });
  };

  deleteAddColumns = async (index) => {
    let columns = this.state.columns;
    delete columns[index];

    let addRows = this.state.addRows.filter((row) => row.index !== index + 1);
    this.setState({ columns, addRows });
  };

  quetooltipOpenHandle = () => {
    this.setState({ quetooltipopen: true });
  };

  quetooltipCloseHandle = () => {
    this.setState({ quetooltipopen: false });
  };

  createTable = () => {
    let table = [];
    this.state.tableRows.map((detail, pindex) => {
      let children = [];
      let rowNo = 0;
      let vote = 0;
      let votername = "";
      let count = 0;
      let employeeName = "";
      let employeePhoto = "";
      detail.map((text, index) => {
        rowNo = text.rowNo;
        vote = text.vote;
        votername = text.votername;
        votername = votername.split(",");
        employeeName = text.employeeName;
        employeePhoto = text.employeePhoto;
        if (!this.state.editText[text.columnDetailId]) {
          count++;
        }
        return children.push(
          <td
            className="th-lg-9"
            key={index}
            onClick={() => this.setEditInput(text.columnDetailId)}
            /* onMouseOut={(e) => this.mouseOut(e, detail, rowNo)} */
          >
            {this.state.editInput === text.columnDetailId &&
            text.creatorId === this.props.employeeId ? (
              <Editor
                name={text.columnDetailId}
                value={text.text || ""}
                onChange={this.handleUpdateDetailsChange}
                customProps={[text.columnId, text.rowNo, text.points, detail]}
                enableToolbar={false}
              />
            ) : (
              <label className="break-line-cust">
                <HtmlRendor value={this.state.editText[text.columnDetailId]} />
              </label>
            )}
          </td>
        );
      });
      let votertooltip = [];

      votername.map((name, index) => {
        let string =
          "<p key=" + index + ' className="voter-name">' + name + "</p>";
        return votertooltip.push(string);
      });
      children.push(
        <td
          onClick={() => this.setEditInput(0)}
          key={pindex + 210}
          className="action-td"
          align="right"
        >
          {detail[0].employeeId === this.props.employeeId &&
            this.state.updateRowsNo.includes(rowNo) && (
              <div
                className="colnew comment-new"
                onClick={(e) => this.updateDecisionRow(e, detail)}
              >
                <img
                  src={"/assets/check-icon.svg"}
                  className="comment-icon"
                  alt="check"
                />
              </div>
            )}
          <div className="colnew" onClick={() => this.viewComment(rowNo)}>
            <img
              className="comment-icon"
              src={
                parseInt(this.props.decision.hasRowComments[rowNo]) === 1
                  ? "/assets/comment-dot.svg"
                  : "/assets/comment.svg"
              }
              alt="comment"
            />
          </div>
          <div className="colnew" onClick={() => this.addVote(rowNo)}>
            <img
              src={"/assets/plus-thin.svg"}
              className="plus-icon"
              alt="plus"
            />

            {vote > 0 && (
              <Tooltip
                content={this.stripHtml(votertooltip.join())}
                placement="left"
                background="#2c4a7c"
                border="#2c4a7c"
                fontSize="10px"
                padding="8"
              >
                <span className="pl-1">{vote > 0 && vote}</span>
              </Tooltip>
            )}
          </div>

          <Tooltip
            content={employeeName}
            placement="left"
            background="#2c4a7c"
            border="#2c4a7c"
            fontSize="10px"
            padding="8"
          >
            <div className="colnew">
              {employeePhoto.trim() !== "" && (
                <div className="avtar-image">
                  <AvatarLabel avatar={employeePhoto} />
                </div>
              )}
              {employeePhoto.trim() === "" && (
                <Avatar
                  name={employeeName}
                  size="20"
                  round="20px"
                  maxInitials={1}
                  color="#2c4a7c"
                  textSizeRatio={1.75}
                />
              )}
            </div>
          </Tooltip>

          {(detail[0].employeeId === this.props.employeeId ||
            count === detail.length) && (
            <div
              className="w-5 colnew"
              onClick={() => this.deleteDetails(detail)}
            >
              <img src={"/assets/cross.svg"} alt="X" />
            </div>
          )}
        </td>
      );
      return table.push(
        <tr
          key={pindex}
          className={this.state.rowComment === rowNo ? "active-table-row" : ""}
        >
          {children}
        </tr>
      );
    });
    return table;
  };

  swipeTwoRows = (dragArray) => {
    const payloadData = this.setFormData();
    payloadData.append("token", this.props.token);
    payloadData.append("dragArray", dragArray);
    payloadData.append("decisionId", this.props.decision.decisionId);
    payloadData.append("workspaceId", this.props.workspaceId);
    this.props
      .swipeRowDecision(payloadData)
      .then(() => {
        this.getDecisionDetails();
      })
      .catch((err) => {
        this.props.errorAlert("Something went wrong, please try again!");
      });
  };

  render() {
    if (!this.props.decision || !this.props.decision.decisionColumns) {
      return (
        <main className="create-main">
          <div className="loader"></div>
        </main>
      );
    }

    const that = this;
    const dragProps = {
      onDragEnd(fromIndex, toIndex) {
        let totalCount = parseInt(that.state.dragRows.length);
        if (parseInt(totalCount) > parseInt(fromIndex)) {
          const data2 = [...that.state.dragRows];
          const item1 = data2.splice(fromIndex, 1)[0];
          data2.splice(toIndex, 0, item1);
          that.swipeTwoRows(data2);
        }
      },
      nodeSelector: "tr",
      handleSelector: "tr",
      lineClassName: "dragcolor",
    };

    return (
      <main className="create-main">
        <br />
        <div className="container">
          {parseInt(this.props.decision.decisionStatusId) ===
            getStatusIdByName("Pending Approval") &&
            this.props.decision.isDiscussion !== "1" && (
              <Alert color="success" style={{ textAlign: "center" }}>
                This decision is pending approval.
              </Alert>
            )}
          {parseInt(this.props.decision.decisionStatusId) ===
            getStatusIdByName("Pending Approval") &&
            this.props.decision.isDiscussion === "1" && (
              <Alert color="success" style={{ textAlign: "center" }}>
                This discussion is pending approval.
              </Alert>
            )}

          <div className="decision-detail-heading">
            <h5>{this.props.decision.title}</h5>
            <span>
              Meeting Start Date: {formatedDate(this.props.decision.startDate)}{" "}
              and Deadline: {formatedDate(this.props.decision.dueDate)}
            </span>
            {this.props.decision.meetingTime && (
              <React.Fragment>
                <br />
                <span>
                  Meeting Start Time:{" "}
                  {formatedTime12(
                    formateTimeUTCToLocal(this.props.decision.meetingTime)
                  )}{" "}
                  and End Time:{" "}
                  {formatedTime12(
                    formateTimeUTCToLocal(this.props.decision.meetingEndTime)
                  )}
                </span>
              </React.Fragment>
            )}
            <hr />
            <span>Template created by: {this.props.decision.creatorName}</span>
          </div>
          <hr />
          <Row className="decision-detail-first">
            <Col md={6}>
              <div className="decision-detail-heading decision-detail-heading-row">
                <h5>Driver</h5>
                <span>
                  <AvatarLabel
                    label={this.props.decision.coordinatorName}
                    avatar={this.props.decision.coordinatorPhoto}
                  />
                </span>
              </div>
            </Col>
            <Col md={6}>
              <div className="decision-detail-heading decision-detail-heading-row">
                <h5>Approvers</h5>
                <table>
                  <tbody>
                    {this.props.decision.approvers.map((approver, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <AvatarLabel
                              label={approver.approverName}
                              avatar={approver.approverPhoto}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </Col>
          </Row>
          {this.props.decision.description && (
            <Row className="decision-detail-anonymous div-white-back custom-row">
              <Col md={5}>
                {this.props.decision.isDiscussion === "1" && (
                  <h5 className="default-color"> Description</h5>
                )}
                {this.props.decision.isDiscussion !== "1" && (
                  <h5 className="default-color">Description</h5>
                )}
              </Col>
              <Col md={12}>
                <Label className="color-black">
                  <HtmlRendor value={this.props.decision.description} />
                </Label>
              </Col>
            </Row>
          )}
         {this.props.decision.hasCollabNote > 0 && (
            <Row className="decision-detail-anonymous div-white-back custom-row">
            <Col md={5}>
                <h5 className="default-color"> Collab note</h5>
            </Col>
            <Col md={12}>
            <FirePadEditor 
              onChange={this.handleEditorChange} 
              decisionId={this.props.decision.decisionId}
              name="collabNoteText"/>
            </Col>
          </Row>
          )}
          <br />
          <Row className="custom-row">
            <h5 className="default-color pr-1">Findings </h5>
            <Tooltip
              content={"Points range(0-10)"}
              background="#2c4a7c"
              border="#2c4a7c"
              fontSize="10px"
              padding="8"
              placement="right"
              customCss={css`
              white-space: nowrap;
            `}
            >
              <img
                src="/assets/questions.svg"
                id={"tool_que"}
                onMouseEnter={this.quetooltipOpenHandle}
                onMouseLeave={this.quetooltipCloseHandle}
                alt="question"
                className="cursor-pointer question-icon mrm-4"
                width="17px"
              ></img>
            </Tooltip>
            <Col md={4}></Col>
          </Row>
          <Row className="decision-coord-detail-table">
            <Col md={this.state.rowComment ? 8 : 12} className="no-padding">
              <div className="table-responsive">
                  <Table
                    className="table-custom table-custom-details"
                    innerRef={(table) => (this.table = table)}
                    hover
                  >
                    <thead>
                      <tr>
                        {this.props.decision.decisionColumns.map(
                          (column, index) => {
                            return (
                              <th className="th-lg-9" key={index}>
                                {column.title}
                              </th>
                            );
                          }
                        )}
                        <th className="col-245px-fixed"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.createTable()}
                      {this.state.columns.map((column, pindex) => {
                        return (
                          <tr key={pindex}>
                            {this.props.decision.decisionColumns.map(
                              (columnDetail, index) => {
                                return (
                                  <td className="th-lg-9" key={index + 1}>
                                    <Editor
                                      name={columnDetail.decisionColumnId}
                                      onChange={this.handleDetailsChange}
                                      customProps={[pindex]}
                                      value={""}
                                      enableToolbar={false}
                                    />
                                  </td>
                                );
                              }
                            )}
                            {
                              <td className="td-valign-center row">
                                <span
                                  className="btn-cust-check float-right"
                                  onClick={() => this.addDecisionRow(pindex)}
                                >
                                  <img
                                    src={"/assets/check-icon.svg"}
                                    alt="check"
                                  />
                                </span>
                                <span
                                  className="btn-cust-close float-right"
                                  onClick={() => this.deleteAddColumns(pindex)}
                                >
                                  <img src={"/assets/cross.svg"} alt="X" />
                                </span>
                              </td>
                            }
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
              </div>
            </Col>
            {this.state.rowComment ? (
              <Comments
                decision={this.props.decision}
                deleteComment={this.deleteComment}
                commentModal={this.state.commentModal}
                handleChange={this.handleEditorChange}
                comment={this.state.comment}
                errors={this.state.errors}
                addComment={this.addComment}
                commentToggle={this.commentToggle}
                employeeId={this.props.employeeId}
                viewComment={this.viewComment}
                commentAddbtn={this.state.commentAddbtn}
              />
            ) : (
              ""
            )}
          </Row>
          <Row className="custom-row">
            <Col md={9}></Col>
            <Col md={3}>
              <Button
                onClick={this.add}
                className="btn-custom btn-custom-create btn-custom-width"
              >
                + &nbsp; Add a comparison row
              </Button>
            </Col>
          </Row>
          <br />
          {this.props.decision.anonymousFeedback !== "0" && (
            <Row className="decision-detail-anonymous custom-row">
              <Col md={5}>
                <h5 className="default-color">Anonymous Feedback</h5>
              </Col>
              <Col md={12}>
                <Editor
                  value={this.props.decision.anonymousFeedbackText}
                  onChange={this.handleEditorChange}
                  name="anonymous"
                />
              </Col>
            </Row>
          )}
          {this.props.decision.zoomUrl && (
            <Row className="decision-detail-anonymous custom-row">
              <Col md={5}>
                <h5 className="default-color">Zoom meeting</h5>
              </Col>
              <Col md={8}>
                <InputGroup onClick={this.handleCopy}>
                  <CopyToClipboard text={this.props.decision.zoomUrl}>
                    <React.Fragment>
                      <Input
                        type="text"
                        onFocus={(event) => event.target.select()}
                        onClick={(event) => event.target.select()}
                        value={this.props.decision.zoomUrl}
                      />
                      <InputGroupAddon addonType="append">
                        <InputGroupText className="copy-text">
                          {this.state.copyText}
                        </InputGroupText>
                      </InputGroupAddon>
                    </React.Fragment>
                  </CopyToClipboard>
                </InputGroup>
              </Col>
            </Row>
          )}
          <Row className="decision-detail-button custom-row">
            <Col md={10}></Col>
            <Col md={1} className="left-btn-bottom">
              <FormGroup>
                <Button
                  onClick={this.handleSubmit}
                  className="btn-custom btn-custom-create btn-custom-width"
                >
                  Submit
                </Button>
              </FormGroup>
            </Col>
            <Col md={1} className="right-btn-bottom">
              <FormGroup>
                <Link to={"/dashboard"}>
                  <Button className="btn-custom btn-custom-create btn-custom-width">
                    Close
                  </Button>
                </Link>
              </FormGroup>
            </Col>
          </Row>
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    decision: findElementByKey(
      state.decisions,
      "decisionId",
      ownProps.match.params.id
    ),
    workspaceId: state.auth.workspaceId,
    employeeId: state.auth.employeeId,
    token: state.auth.token,
    isValidate: state.auth.isValidate,
    currentPlan: state.account.currentPlan,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    swipeRowDecision: (data) => {
      return dispatch(swipeRowDecision(data));
    },
    getDecisionDetails: (data) => {
      return dispatch(getDecisionDetails(data));
    },
    errorAlert: (data) => {
      return dispatch(errorAlert(data));
    },
    addVote: (data) => {
      return dispatch(addVote(data));
    },
    successAlert: (data) => {
      return dispatch(successAlert(data));
    },
    warningAlert: (data) => {
      return dispatch(warningAlert(data));
    },
    updateDecisionDetailsMember: (data) => {
      return dispatch(updateDecisionDetailsMember(data));
    },
    getCommentsByRow: (data) => {
      return dispatch(getCommentsByRow(data));
    },
    addComment: (data) => {
      return dispatch(addComment(data));
    },
    deleteComment: (data) => {
      return dispatch(deleteComment(data));
    },
    addDecisionRow: (data) => {
      return dispatch(addDecisionRow(data));
    },
    updateDecisionRow: (data) => {
      return dispatch(updateDecisionRow(data));
    },
    deleteDecisionRow: (data) => {
      return dispatch(deleteDecisionRow(data));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateDecisionDetailsMember);
