import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Row,
  Label,
  Col,
  FormGroup,
  Input,
  Button,
  Form,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import Editor from "../../../common/editor";
import { CopyToClipboard } from "react-copy-to-clipboard";
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import ReeValidate from "ree-validate";
import { loadEmployees } from "../../employees/store/employeeActions";
import { loadDepartments } from "../../departments/store/departmentActions";
import {
  errorAlert,
  successAlert,
  warningAlert,
} from "../../web/store/alertActions";
import SelectRow from "../../../common/select";
import "react-datepicker/dist/react-datepicker.css";
import {
  datePickerFormate,
  decisionPriority,
  timePickerFormate,
  timePickerInterval,
  notIntegratedResponseStatus,
  expirePlanType,
  expirePlanAlertMessage,
  expirePlanStatus,
} from "../../../constants";
import {
  getDecision,
  updateDecisionTemplate,
  createZoomMeeting,
} from "../store/decisionActions";
import {
  formatedTimestamp,
  findElementByKey,
  updateObjectByKey,
  mergeAndFormateTimestamp,
  formateTimeUTCToLocal,
  sortBy,
} from "../../../utilities/helpers";
import moment from "moment";
import Tooltip from "react-simple-tooltip";
import {css} from "styled-components";

class CreateDecisionTemplate extends Component {
  constructor(props) {
    super(props);

    this.validator = new ReeValidate({
      title: "required",
      description: "",
      members: "required",
      department: "required",
      coordinator: "required",
      approvers: "required",
      meetingDate: "required",
      meetingTime: "",
      meetingEndTime: "",
      dueDate: "required",
      anonymousFeedback: "",
      allowpoints: "",
      allowTeamVote: "",
      column0: "",
      column1: "",
      priority: "required",
      hasCollabNote: "",
      visibleToDepartment: "",
      tooltipOpen: "",
    });

    this.state = {
      departmentOptions: [],
      employeeOptions: [],
      department: null,
      coordinator: null,
      priority: null,
      members: [],
      approvers: [],
      updateColumns: [],
      discussionColumns: [],
      errorDiscussion: false,
      copyText: "Copy",
      form: {
        title: "",
        description: "",
        department: "",
        members: "",
        coordinator: "",
        approvers: "",
        meetingDate: "",
        dueDate: "",
        meetingTime: new Date(),
        meetingEndTime: new Date(),
        anonymousFeedback: false,
        allowpoints: false,
        hasCollabNote: false,
        visibleToDepartment: false,
        allowTeamVote: false,
        calendarInvite: false,
        hasMeetingChange: false,
        zoomMeeting: false,
        column0: "",
        priority: "",
        updateColumns: [],
        deleteColumns: [],
        zoomUrl: "",
        tooltipOpen: false,
      },
      errors: this.validator.errors,
      columnsErrors: "",
    };
  }

  componentDidMount() {
    if (this.props.token) {
      if (this.props.isValidate) {
        this.loadDepartmentsAndEmployees();
        this.getDecision();
      }
    } else {
      this.props.history.push("/");
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (props.decision && props.decision.decisionColumns) {
      this.updateCurrentState(props);
    }
  }

  updateCurrentState = async (props) => {
    let department = await props.departments.filter((department) => {
      return department.departmentId === props.decision.departmentId;
    })[0];
    let coordinator = await props.decision.members.filter((employee) => {
      return parseInt(employee.isCoordinator) === 1;
    })[0];
    let members1 = await props.decision.members.filter((employee) => {
      return parseInt(employee.isCoordinator) !== 1;
    });
    let arr = [];
    let members = [];
    for (var i = 0; i < members1.length; i++) {
      if (!arr.includes(members1[i].employeeId)) {
        arr.push(members1[i].employeeId);
        members.push(members1[i]);
      }
    }

    let approvers = await props.employees.filter((employee) => {
      return props.decision.approvers
        .map((approver) => approver.employeeId)
        .includes(employee.employeeId);
    });

    let priority = await decisionPriority.filter((priority) => {
      return priority.priorityId === parseInt(props.decision.priority);
    })[0];

    this.setState({
      form: {
        ...this.state.form,
        title: props.decision.title,
        description: props.decision.description,
        priority: props.decision.priority,
        coordinator: coordinator.employeeId,
        department: props.decision.departmentId,
        members: members.map((member) => member.employeeId),
        approvers: props.decision.approvers.map(
          (approver) => approver.employeeId
        ),
        anonymousFeedback: parseInt(props.decision.anonymousFeedback)
          ? true
          : false,
        allowpoints: parseInt(props.decision.allowpoints) ? true : false,
        hasCollabNote: parseInt(props.decision.hasCollabNote)
        ? true
        : false,
        visibleToDepartment: parseInt(props.decision.visibleToDepartment)
          ? true
          : false,
        allowTeamVote: parseInt(props.decision.allowTeamVote) ? true : false,
        dueDate: new Date(props.decision.dueDate.replace(/-/g, "/")),
        meetingDate: new Date(props.decision.meetingDate.replace(/-/g, "/")),
        calendarInvite: props.decision.meetingTime ? true : false,
        meetingTime: props.decision.meetingTime
          ? new Date(formateTimeUTCToLocal(props.decision.meetingTime))
          : new Date(),
        meetingEndTime: props.decision.meetingEndTime
          ? new Date(formateTimeUTCToLocal(props.decision.meetingEndTime))
          : new Date(),
        zoomMeeting: props.decision.zoomUrl ? true : false,
        zoomUrl: props.decision.zoomUrl,
        updateColumns: props.decision.decisionColumns,
      },
    });
    this.setState({
      coordinator: coordinator,
      department: department,
      priority: priority,
      approvers: approvers,
      members: members,
      updateColumns: props.decision.decisionColumns,
    });
  };

  getDecision = () => {
    const requestData = new FormData();
    requestData.append("workspaceId", this.props.workspaceId);
    requestData.append("employeeId", this.props.employeeId);
    requestData.append("decisionId", this.props.match.params.id);
    requestData.append("token", this.props.token);
    this.props.getDecision(requestData);
  };

  loadDepartmentsAndEmployees = () => {
    const requestData = new FormData();
    requestData.append("workspaceId", this.props.workspaceId);
    requestData.append("employeeId", this.props.employeeId);
    requestData.append("token", this.props.token);
    this.props.loadEmployees(requestData);
    this.props.loadDepartments(requestData);
  };

  onSelectChange = async (name, value, valueKey, isMulti) => {
    if (isMulti) {
      let values = [];
      if (value) {
        await value.map((item) => {
          return values.push(item[valueKey]);
        });
      }
      await this.setState({
        form: { ...this.state.form, [name]: values },
        [name]: value,
      });
      this.validateField(name, values);
    } else {
      let val = "";
      if (value) {
        val = value[valueKey];
      }
      await this.setState({
        form: { ...this.state.form, [name]: val },
        [name]: value,
      });
      this.validateField(name, val);
    }
  };

  onDateChange = async (name, value) => {
    await this.setState({
      form: { ...this.state.form, [name]: value },
    });
    this.validateField(name, value);
    this.hasChangeDate();
  };

  handleChange = async (e) => {
    const name = e.target.name;
    const value = e.target.value;
    await this.setState({
      form: { ...this.state.form, [name]: value },
    });
    this.validateField(name, value);
  };

  handleEditorChange = async (description, name) => {
    await this.setState({
      form: { ...this.state.form, [name]: description },
    });
    this.validateField("description", description);
  };

  add = () => {
    this.setState((prevState) => ({
      discussionColumns: [...prevState.discussionColumns, ""],
    }));
  };

  remove = async (index) => {
    let discussionColumns = [...this.state.discussionColumns];
    discussionColumns.splice(index, 1);
    this.setState({ discussionColumns });
  };

  onMutipleChange = async (index, e) => {
    const name = e.target.name;
    const value = e.target.value;

    if (name === "column0") {
      await this.setState({
        form: { ...this.state.form, [name]: value },
      });
      this.validateField(name, value);
    }
    this.setState({ errorDiscussion: false });
    let discussionColumns = [...this.state.discussionColumns];

    discussionColumns[index] = value;
    this.setState({ discussionColumns });
  };

  onUpdateColumnsChange = async (column, e) => {
    let newCol = updateObjectByKey(
      this.state.updateColumns,
      {
        decisionColumnId: column.decisionColumnId,
        title: e.target.value,
      },
      "decisionColumnId"
    );
    await newCol.sort(
      (a, b) => parseInt(a.decisionColumnId) - parseInt(b.decisionColumnId)
    );
    this.setState({
      form: { ...this.state.form, updateColumns: newCol },
      updateColumns: newCol,
    });
  };

  onDeleteColumnsChange = async (column) => {
    let deleteColumns = this.state.form.deleteColumns;
    deleteColumns.push(column.decisionColumnId);
    let updateColumns = this.state.form.updateColumns;
    await delete updateColumns[column.decisionColumnId];
    this.setState({
      form: {
        ...this.state.form,
        deleteColumns: deleteColumns,
        updateColumns: updateColumns,
      },
      updateColumns: this.state.updateColumns.filter(
        (col) => col.decisionColumnId !== column.decisionColumnId
      ),
    });
  };

  validateField = (name, value) => {
    this.validator.validate(name, value).then(() => {
      const { errors } = this.validator;
      this.setState({ errors });
    });
  };

  handleCheckboxChange = (name) => {
    this.setState({
      form: { ...this.state.form, [name]: !this.state.form[name] },
    });

    if (
      !this.state.form[name] &&
      name === "zoomMeeting" &&
      this.state.form.zoomUrl === this.props.decision.zoomUrl
    ) {
      this.createZoomMeeting();
    }
  };

  createZoomMeeting = () => {
    const payloadData = new FormData();
    payloadData.append("token", this.props.token);
    payloadData.append("employeeId", this.props.employeeId);
    payloadData.append("workspaceId", this.props.workspaceId);
    payloadData.append("title", this.state.form.title);
    this.props
      .createZoomMeeting(payloadData)
      .then((success) => {
        this.setState({
          form: { ...this.state.form, zoomUrl: success.payload.url },
        });
      })
      .catch((err) => {
        if (parseInt(err.data.status) === notIntegratedResponseStatus) {
          this.props.warningAlert(
            "Please connect Zoom account using Integrations!"
          );
        } else {
          this.props.errorAlert(
            "Something went wrong with create zoom meeting!"
          );
        }
      });
  };

  hasChangeDate = () => {
    if (
      this.state.form.meetingDate.getTime() !==
        new Date(this.props.decision.meetingDate).getTime() ||
      this.state.form.meetingTime.getTime() !==
        new Date(this.props.decision.meetingTime).getTime() ||
      this.state.form.meetingEndTime.getTime() !==
        new Date(this.props.decision.meetingEndTime).getTime()
    ) {
      this.setState({ form: { ...this.state.form, hasMeetingChange: true } });
    } else {
      this.setState({ form: { ...this.state.form, hasMeetingChange: false } });
    }
    if (
      this.state.form.zoomUrl === this.props.decision.zoomUrl &&
      this.state.form.zoomMeeting
    ) {
      this.createZoomMeeting();
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { form } = this.state;
    const { errors } = this.validator;
    this.validator.validateAll(form).then((success) => {
      if (success) {
        this.updateDecisionTemplate();
      } else {
        this.setState({ errors });
      }
    });
  };

  tooltipOpenHandle = () => {
    this.setState({ tooltipOpen: true });
  };
  tooltipCloseHandle = () => {
    this.setState({ tooltipOpen: false });
  };

  updateDecisionTemplate = () => {
    if (parseInt(this.props.currentPlan) === expirePlanType) {
      this.props.warningAlert(expirePlanAlertMessage);
      return true;
    }

    let newArray = this.state.discussionColumns.filter(
      (element) => element !== ""
    );
    let newUpdate = this.state.updateColumns.filter(
      (element) => element.title !== ""
    );
    let valCol = +this.props.decision.isDiscussion ? 1 : 2;
    if (parseInt(newArray.length) + parseInt(newUpdate.length) < valCol) {
      this.setState({ errorDiscussion: true });
    } else {
      this.setState({ errorDiscussion: false });
      const payloadData = new FormData();
      payloadData.append("token", this.props.token);
      payloadData.append("employeeId", this.props.employeeId);
      payloadData.append("creatorId", this.props.employeeId);
      payloadData.append("decisionId", this.props.decision.decisionId);
      payloadData.append("workspaceId", this.props.workspaceId);
      payloadData.append("departmentId", this.state.form.department);
      payloadData.append("coordinatorId", this.state.form.coordinator);
      payloadData.append("title", this.state.form.title);
      payloadData.append("description", this.state.form.description);
      payloadData.append("dueDate", formatedTimestamp(this.state.form.dueDate));
      if (this.state.form.zoomMeeting) {
        payloadData.append("zoomUrl", this.state.form.zoomUrl);
      }
      if (this.state.form.calendarInvite) {
        payloadData.append(
          "meetingDate",
          mergeAndFormateTimestamp(
            this.state.form.meetingDate,
            this.state.form.meetingTime
          )
        );
        payloadData.append(
          "meetingTime",
          mergeAndFormateTimestamp(
            this.state.form.meetingDate,
            this.state.form.meetingTime
          )
        );
        payloadData.append(
          "meetingEndTime",
          mergeAndFormateTimestamp(
            this.state.form.meetingDate,
            this.state.form.meetingEndTime
          )
        );
      } else {
        payloadData.append(
          "meetingDate",
          formatedTimestamp(this.state.form.meetingDate)
        );
      }
      payloadData.append(
        "hasCollabNote",
        this.state.form.hasCollabNote ? 1 : 0
      );
      payloadData.append(
        "visibleToDepartment",
        this.state.form.visibleToDepartment ? 1 : 0
      );
      payloadData.append(
        "anonymousFeedback",
        this.state.form.anonymousFeedback ? 1 : 0
      );
      payloadData.append("allowpoints", this.state.form.allowpoints ? 1 : 0);
      payloadData.append(
        "allowTeamVote",
        this.state.form.allowTeamVote ? 1 : 0
      );
      payloadData.append(
        "calendarInvite",
        this.state.form.calendarInvite ? 1 : 0
      );
      payloadData.append(
        "hasMeetingChange",
        this.state.form.hasMeetingChange ? 1 : 0
      );
      payloadData.append(
        "addDecisionColumns",
        JSON.stringify(this.state.discussionColumns)
      );

      let updateColumns = [];
      this.state.form.updateColumns.map((column) => {
        return updateColumns.push({
          decisionColumnId: column.decisionColumnId,
          columnTitle: column.title,
        });
      });
      payloadData.append(
        "updateDecisionColumns",
        JSON.stringify(updateColumns)
      );
      payloadData.append(
        "deleteDecisionColumns",
        JSON.stringify(this.state.form.deleteColumns)
      );
      payloadData.append("members", JSON.stringify(this.state.form.members));
      payloadData.append(
        "approvers",
        JSON.stringify(this.state.form.approvers)
      );
      payloadData.append("priority", this.state.form.priority);
      this.props
        .updateDecisionTemplate(payloadData)
        .then(() => {
          this.props.successAlert("Decision template updated successfully !");
          this.props.history.push("/dashboard");
          window.location.reload();
        })
        .catch((err) => {
          if (parseInt(err.data.status) === expirePlanStatus) {
            this.props.warningAlert(expirePlanAlertMessage);
          } else {
            this.props.errorAlert("Something went wrong, please try again!");
          }
        });
    }
  };

  handleCopy = () => {
    this.setState({
      copyText: "Copied",
    });
    setTimeout(() => {
      this.setState({
        copyText: "Copy",
      });
    }, 2000);
  };

  render() {
    if (!this.props.decision || !this.props.decision.decisionColumns) {
      return (
        <main className="create-main">
          <div className="loader"></div>
        </main>
      );
    }

    return (
      <main className="create-main">
        <br />
        <div className="create-decision container">
          <br />
          <h5 className="page-title">
            Update a{" "}
            {this.props.decision.isDiscussion &&
            +this.props.decision.isDiscussion
              ? "Discussion"
              : "Decision"}{" "}
            Template
          </h5>
          <br />
          <Form className="form-custom" onSubmit={this.handleSubmit}>
            <Row>
              <Col md={5}>
                <FormGroup>
                  <Label>Title</Label>
                  <Input
                    type="text"
                    name="title"
                    onChange={this.handleChange}
                    value={this.state.form.title}
                  />
                  {this.state.errors.has("title") && (
                    <label id="name-error" className="error" htmlFor="title">
                      {this.state.errors.first("title")}
                    </label>
                  )}
                </FormGroup>
              </Col>
              <Col md={5}>
                <FormGroup>
                  <Label>Department</Label>
                  <SelectRow
                    name="department"
                    classes="z-index-select"
                    items={this.props.departments}
                    nameField={"name"}
                    valueField={"departmentId"}
                    value={this.state.department || null}
                    onChange={this.onSelectChange}
                  />
                  {this.state.errors.has("department") && (
                    <label
                      id="name-error"
                      className="error"
                      htmlFor="department"
                    >
                      {this.state.errors.first("department")}
                    </label>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={10}>
                <FormGroup>
                  <Label>
                    {+this.props.decision.isDiscussion
                      ? "Description"
                      : "Description"}
                  </Label>
                  <Editor
                    value={this.props.decision.description}
                    name="description"
                    onChange={this.handleEditorChange}
                  />
                  {this.state.errors.has("description") && (
                    <label
                      id="name-error"
                      className="error"
                      htmlFor="description"
                    >
                      {this.state.errors.first("description")}
                    </label>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={5}>
                <FormGroup>
                  <Label>Meeting Start Date</Label>
                  <DatePicker
                    className="form-control"
                    name="meetingDate"
                    autoComplete="off"
                    minDate={new Date()}
                    dateFormat={datePickerFormate}
                    selected={this.state.form.meetingDate}
                    onChange={(date) => this.onDateChange("meetingDate", date)}
                  />
                  {this.state.errors.has("meetingDate") && (
                    <label
                      id="name-error"
                      className="error"
                      htmlFor="meetingDate"
                    >
                      {this.state.errors.first("meetingDate")}
                    </label>
                  )}
                </FormGroup>
              </Col>
              <Col md={5}>
                <FormGroup>
                  <Label>Due Date</Label>
                  <DatePicker
                    className="form-control"
                    autoComplete="off"
                    name="dueDate"
                    dateFormat={datePickerFormate}
                    minDate={this.state.form.meetingDate}
                    selected={this.state.form.dueDate}
                    onChange={(date) => this.onDateChange("dueDate", date)}
                  />
                  {this.state.errors.has("dueDate") && (
                    <label id="name-error" className="error" htmlFor="dueDate">
                      {this.state.errors.first("dueDate")}
                    </label>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={5}>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      name={"calendarInvite"}
                      onChange={() =>
                        this.handleCheckboxChange("calendarInvite")
                      }
                      checked={this.state.form.calendarInvite}
                      value={this.state.form.calendarInvite}
                    />{" "}
                    Send Calendar Invite
                  </Label>
                </FormGroup>
              </Col>
              {this.state.form.calendarInvite && (
                <Col md={5}>
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        name={"zoomMeeting"}
                        onChange={() =>
                          this.handleCheckboxChange("zoomMeeting")
                        }
                        checked={this.state.form.zoomMeeting}
                        value={this.state.form.zoomMeeting}
                      />{" "}
                      Add zoom meeting
                    </Label>
                  </FormGroup>
                </Col>
              )}
            </Row>
            {this.state.form.calendarInvite && (
              <React.Fragment>
                <Row>
                  <Col md={2}>
                    <FormGroup>
                      <Label>Meeting Start Time</Label>
                      <DatePicker
                        className="form-control"
                        name="meetingTime"
                        autoComplete="off"
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={timePickerInterval}
                        timeCaption="Start"
                        dateFormat={timePickerFormate}
                        selected={this.state.form.meetingTime}
                        onChange={(date) =>
                          this.onDateChange("meetingTime", date)
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col md={2}>
                    <FormGroup>
                      <Label>Meeting End Time</Label>
                      <DatePicker
                        className="form-control"
                        name="meetingEndTime"
                        autoComplete="off"
                        showTimeSelect
                        timeFormat={timePickerFormate}
                        minTime={this.state.form.meetingTime}
                        maxTime={moment().endOf("day").toDate()}
                        showTimeSelectOnly
                        timeIntervals={timePickerInterval}
                        timeCaption="End"
                        dateFormat={timePickerFormate}
                        selected={this.state.form.meetingEndTime}
                        onChange={(date) =>
                          this.onDateChange("meetingEndTime", date)
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col md={1} />
                  {this.state.form.zoomMeeting && this.state.form.zoomUrl && (
                    <Col md={5}>
                      <FormGroup>
                        <Label>&nbsp;</Label>
                        <InputGroup onClick={this.handleCopy}>
                          <CopyToClipboard text={this.state.form.zoomUrl}>
                            <React.Fragment>
                              <Input
                                type="text"
                                onFocus={(event) => event.target.select()}
                                onClick={(event) => event.target.select()}
                                value={this.state.form.zoomUrl}
                              />
                              <InputGroupAddon addonType="append">
                                <InputGroupText className="copy-text">
                                  {this.state.copyText}
                                </InputGroupText>
                              </InputGroupAddon>
                            </React.Fragment>
                          </CopyToClipboard>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  )}
                </Row>
              </React.Fragment>
            )}
            <br />
            <Row>
              <Col md={5}>
                <FormGroup>
                  <Label>Driver</Label>
                  <span>&nbsp;</span>
                    <Tooltip
                      content="Driver. The one person responsible for corralling stakeholders, collating all the necessary information and getting a decision made by the agreed date."
                      placement="right"
                      background="#2c4a7c"
                      border="#2c4a7c"
                      fontFamily="inherit"
                      fontSize="inherit"
                      padding="8"
                      arrow="10"
                      customCss={css`
                      white-space: nowrap;
                    `}
                    >
                    <img
                      src="/assets/questions.svg"
                      id={"tool_que"}
                      alt="question"
                      className="cursor-pointer question-icon mrm-4"
                      width="17px"
                    ></img>
                  </Tooltip>
                  <SelectRow
                    name="coordinator"
                    items={this.props.employees.sort(
                      sortBy("displayName", "ASC")
                    )}
                    avatar={true}
                    nameField={"displayName"}
                    valueField={"employeeId"}
                    value={this.state.coordinator || null}
                    onChange={this.onSelectChange}
                  />
                  {this.state.errors.has("coordinator") && (
                    <label
                      id="name-error"
                      className="error"
                      htmlFor="coordinator"
                    >
                      {this.state.errors.first("coordinator")}
                    </label>
                  )}
                </FormGroup>
              </Col>

              <Col md={5}>
                <FormGroup>
                  <Label>Approvers</Label>
                  <span>&nbsp;</span>
                    <Tooltip
                      content="Approver. The one person who makes the decision."
                      placement="right"
                      background="#2c4a7c"
                      border="#2c4a7c"
                      fontFamily="inherit"
                      fontSize="inherit"
                      padding="8"
                      arrow="10"
                      customCss={css`
                      white-space: nowrap;
                    `}
                    >
                    <img
                      src="/assets/questions.svg"
                      id={"tool_que"}
                      alt="question"
                      className="cursor-pointer question-icon mrm-4"
                      width="17px"
                    ></img>
                  </Tooltip>
                  <SelectRow
                    name="approvers"
                    items={this.props.employees.sort(
                      sortBy("displayName", "ASC")
                    )}
                    avatar={true}
                    multi={true}
                    nameField={"displayName"}
                    valueField={"employeeId"}
                    value={this.state.approvers}
                    onChange={this.onSelectChange}
                  />
                  {this.state.errors.has("approvers") && (
                    <label
                      id="name-error"
                      className="error"
                      htmlFor="approvers"
                    >
                      {this.state.errors.first("approvers")}
                    </label>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={5}>
                <FormGroup>
                  <Label>Contributors</Label>
                  <span>&nbsp;</span>
                    <Tooltip
                      content="Contributors. They have knowledge or expertise that may influence the decision."
                      placement="right"
                      background="#2c4a7c"
                      border="#2c4a7c"
                      fontFamily="inherit"
                      fontSize="inherit"
                      padding="8"
                      arrow="10"
                      customCss={css`
                      white-space: nowrap;
                    `}
                    >
                    <img
                      src="/assets/questions.svg"
                      id={"tool_que"}
                      alt="question"
                      className="cursor-pointer question-icon mrm-4"
                      width="17px"
                    ></img>
                  </Tooltip>
                  <SelectRow
                    name="members"
                    items={this.props.employees.sort(
                      sortBy("displayName", "ASC")
                    )}
                    avatar={true}
                    nameField={"displayName"}
                    valueField={"employeeId"}
                    multi={true}
                    value={this.state.members}
                    onChange={this.onSelectChange}
                  />
                  {this.state.errors.has("members") && (
                    <label id="name-error" className="error" htmlFor="members">
                      {this.state.errors.first("members")}
                    </label>
                  )}
                </FormGroup>
              </Col>
              <Col md={5}>
                <FormGroup>
                  <Label>Priority</Label>
                  <SelectRow
                    name="priority"
                    items={decisionPriority}
                    nameField={"name"}
                    valueField={"priorityId"}
                    value={this.state.priority || null}
                    onChange={this.onSelectChange}
                  />
                  {this.state.errors.has("priority") && (
                    <label id="name-error" className="error" htmlFor="priority">
                      {this.state.errors.first("priority")}
                    </label>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Label>Discussion Columns</Label>
            {this.state.errorDiscussion ? (
              <p id="name-error" className="error" htmlFor={"column1"}>
                {+this.props.decision.isDiscussion
                  ? "Discussion column field is required."
                  : "Minimum 2 discussion column field is required."}
              </p>
            ) : null}
            {this.state.updateColumns.map((column, index) => {
              return (
                <Row key={index}>
                  <Col md={5}>
                    <FormGroup>
                      <Input
                        type="text"
                        onChange={(e) => this.onUpdateColumnsChange(column, e)}
                        value={column.title}
                        name={"column_update" + index}
                      />{" "}
                      {this.state.errors.has("column_update" + index) && (
                        <label
                          id="name-error"
                          className="error"
                          htmlFor={"column_update" + index}
                        >
                          {+this.props.decision.isDiscussion
                            ? "Discussion column field is required."
                            : "Minimum 2 discussion column field is required."}
                        </label>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md={2} className={"close-cust"}>
                    {this.state.discussionColumns.length +
                      this.state.updateColumns.length >
                      1 && (
                      <button
                        className="link-button btn-custom btn-cust"
                        href="#"
                        type="button"
                        onClick={() => this.onDeleteColumnsChange(column)}
                      >
                        <img src={"/assets/cross.svg"} alt="X" />
                      </button>
                    )}{" "}
                  </Col>
                  <Col md={6}>
                    {this.state.updateColumns.length - 1 === index &&
                      !this.state.discussionColumns.length && (
                        <Button
                          color="primary"
                          className="btn-custom btn-custom-create btn-custom-width-add"
                          onClick={this.add}
                        >
                          + &nbsp; Add
                        </Button>
                      )}
                  </Col>
                </Row>
              );
            })}
            {this.state.discussionColumns.map((column, index) => {
              return (
                <Row key={index}>
                  <Col md={5}>
                    <FormGroup>
                      <Input
                        type="text"
                        onChange={(e) => this.onMutipleChange(index, e)}
                        value={column}
                        name={"column" + index}
                      />{" "}
                      {this.state.errors.has("column" + index) && (
                        <label
                          id="name-error"
                          className="error"
                          htmlFor={"column" + index}
                        >
                          {+this.props.decision.isDiscussion
                            ? "Discussion column field is required."
                            : "Minimum 2 discussion column field is required."}
                        </label>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md={2} className={"close-cust"}>
                    {this.state.discussionColumns.length +
                      this.state.updateColumns.length >
                      1 && (
                      <button
                        className="link-button btn-custom btn-cust"
                        href="#"
                        type="button"
                        onClick={() => this.remove(index)}
                      >
                        <img src={"/assets/cross.svg"} alt="X" />
                      </button>
                    )}{" "}
                  </Col>
                  <Col md={6}>
                    {this.state.discussionColumns.length - 1 === index && (
                      <Button
                        color="primary"
                        className="btn-custom btn-custom-create btn-custom-width-add"
                        onClick={this.add}
                      >
                        + &nbsp; Add
                      </Button>
                    )}
                  </Col>
                </Row>
              );
            })}
            <Row>
              <Col>
                <FormGroup check>
                  <Label check className="pr-2">
                    <Input
                      type="checkbox"
                      name={"hasCollabNote"}
                      onChange={() =>
                        this.handleCheckboxChange("hasCollabNote")
                      }
                      checked={this.state.form.hasCollabNote}
                      value={this.state.form.hasCollabNote}
                    />{" "}
                    Collab note
                  </Label>
                  <Tooltip
                    content="Collaborative real-time editable note for discussions and note taking."
                    placement="right"
                    background="#2c4a7c"
                    border="#2c4a7c"
                    fontFamily="inherit"
                    fontSize="inherit"
                    padding="8"
                    arrow="10"
                    customCss={css`
                    white-space: nowrap;
                  `}
                  >
                    <img
                      src="/assets/questions.svg"
                      id={"tool_que"}
                      alt="question"
                      class="cursor-pointer question-icon mrm-4"
                      width="17px"
                    ></img>
                  </Tooltip>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup check>
                  <Label check className="pr-2">
                    <Input
                      type="checkbox"
                      name={"visibleToDepartment"}
                      onChange={() =>
                        this.handleCheckboxChange("visibleToDepartment")
                      }
                      checked={this.state.form.visibleToDepartment}
                      value={this.state.form.visibleToDepartment}
                    />{" "}
                    Visible To Department
                  </Label>
                  <Tooltip
                      content="Decision will be visible to all employees part of your department."
                      placement="right"
                      background="#2c4a7c"
                      border="#2c4a7c"
                      fontFamily="inherit"
                      fontSize="inherit"
                      padding="8"
                      arrow="10"
                      customCss={css`
                      white-space: nowrap;
                    `}
                    >
                    <img
                      src="/assets/questions.svg"
                      id={"tool_que"}
                      alt="question"
                      class="cursor-pointer question-icon mrm-4"
                      width="17px"
                    ></img>
                  </Tooltip>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      name={"anonymousFeedback"}
                      onChange={() =>
                        this.handleCheckboxChange("anonymousFeedback")
                      }
                      checked={this.state.form.anonymousFeedback}
                      value={this.state.form.anonymousFeedback}
                    />{" "}
                    Anonymous Feedback
                  </Label>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      name={"allowpoints"}
                      onChange={() => this.handleCheckboxChange("allowpoints")}
                      checked={this.state.form.allowpoints}
                      value={this.state.form.allowpoints}
                    />{" "}
                    Allow Points
                  </Label>
                </FormGroup>
              </Col>
            </Row>
            {+this.props.decision.isDiscussion ? (
              ""
            ) : (
              <Row>
                <Col>
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        name={"allowTeamVote"}
                        onChange={() =>
                          this.handleCheckboxChange("allowTeamVote")
                        }
                        checked={this.state.form.allowTeamVote}
                        value={this.state.form.allowTeamVote}
                      />{" "}
                      Allow Team Voting
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
            )}
            <Row>
              <Col md={8}></Col>
              <Col md={2}>
                <FormGroup>
                  <Button className="btn-custom btn-custom-create btn-custom-width">
                    Update
                  </Button>
                </FormGroup>
              </Col>
              <Col md={2}>
                <FormGroup>
                  <Link to={"/dashboard"}>
                    <Button className="btn-custom btn-custom-create btn-custom-width">
                      Cancel
                    </Button>
                  </Link>
                </FormGroup>
              </Col>
            </Row>
            <br />
          </Form>
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    workspaceId: state.auth.workspaceId,
    employeeId: state.auth.employeeId,
    token: state.auth.token,
    departments: state.departments,
    employees: state.employees,
    decision: findElementByKey(
      state.decisions,
      "decisionId",
      ownProps.match.params.id
    ),
    isValidate: state.auth.isValidate,
    currentPlan: state.account.currentPlan,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadDepartments: (data) => {
      return dispatch(loadDepartments(data));
    },
    loadEmployees: (data) => {
      return dispatch(loadEmployees(data));
    },
    errorAlert: (data) => {
      return dispatch(errorAlert(data));
    },
    successAlert: (data) => {
      return dispatch(successAlert(data));
    },
    warningAlert: (data) => {
      return dispatch(warningAlert(data));
    },
    getDecision: (data) => {
      return dispatch(getDecision(data));
    },
    updateDecisionTemplate: (data) => {
      return dispatch(updateDecisionTemplate(data));
    },
    createZoomMeeting: (data) => {
      return dispatch(createZoomMeeting(data));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateDecisionTemplate);
