import {
  LOAD_DEPARTMENTS,
  GET_DEPARTMENTS_AND_EMPLOYEES
} from "./action-types";
import { sortBy } from "../../../utilities/helpers";

const initialState = [];

const reducer = (state = initialState, { type, payload = null }) => {
  switch (type) {
    case LOAD_DEPARTMENTS:
      return loadDepartments(state, payload);
    case GET_DEPARTMENTS_AND_EMPLOYEES:
      return loadDepartments(state, payload);
    default:
      return state;
  }
};

const loadDepartments = (state, payload) => {
  return Object.assign(
    [],
    payload.departments.sort(sortBy('departmentId'))
  );
};

export default reducer;
