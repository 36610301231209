import {
  LOAD_DECISIONS,
  SEARCH_DECISIONS,
  GET_DECISION_DETAILS,
  GET_COORDINATOR_DECISION_DETAILS,
  GET_DECISION,
  DELETE_DECISION_TEMPLATE,
  GET_COMMENTS_BY_ROW
} from "./action-types";
import {
  updateObjectByKey,
  mergeAndSort,
  removeObjectByKeyValue
} from "../../../utilities/helpers";

const initialState = [];

const reducer = (state = initialState, { type, payload = null }) => {
  switch (type) {
    case LOAD_DECISIONS:
      return loadDecisions(state, payload);
    case SEARCH_DECISIONS:
      return searchDecisions(state, payload);
    case GET_DECISION_DETAILS:
      return updateDecisions(state, payload);
    case GET_COORDINATOR_DECISION_DETAILS:
      return updateDecisions(state, payload);
    case GET_DECISION:
      return updateDecisions(state, payload);
    case GET_COMMENTS_BY_ROW:
      return updateDecisions(state, payload);
    case DELETE_DECISION_TEMPLATE:
      return deleteDecision(state, payload);
    default:
      return state;
  }
};

const loadDecisions = (state, payload) => {
  if (payload.offset !== "0") {
    return Object.assign(
      [],
      mergeAndSort(state, payload.data, "decisionId", payload.sorted)
    );
  } else {
    return searchDecisions(state, payload);
  }
};

const searchDecisions = (state, payload) => {
  return Object.assign([], payload.data);
};

const deleteDecision = (state, payload) => {
  return Object.assign(
    [],
    removeObjectByKeyValue(state, "decisionId", payload.decisionId)
  );
};

const updateDecisions = (state, payload) => {
  return Object.assign([], updateObjectByKey(state, payload, "decisionId"));
};

export default reducer;
