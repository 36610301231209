export const decisionStatus = [
  { id: 1, name: "Draft" },
  { id: 2, name: "In Progress" },
  { id: 3, name: "Blocked" },
  { id: 4, name: "Finalized" },
  { id: 5, name: "Postponed" },
  { id: 6, name: "Delayed" },
  { id: 7, name: "Cancelled" },
  { id: 8, name: "Pending Approval" },
];

export const decisionTypes = [
  { id: -1, name: "Compromise" },
  { id: -2, name: "Postponed" },
  { id: -3, name: "Followup" },
  { id: -4, name: "Cancel" },
];

export const decisionRiskTypes = [
  { id: 1, name: "Low" },
  { id: 2, name: "Medium" },
  { id: 3, name: "High" },
  { id: 4, name: "Very High" },
];

export const decisionStatusColor = {
  Draft: "draft-badge-color",
  "In Progress": "inprogress-badge-color",
  Blocked: "blocked-badge-color",
  Finalized: "finelized-badge-color",
  Postponed: "postponed-badge-color",
  Delayed: "delayed-badge-color",
  Cancelled: "cancelled-badge-color",
};

export const decisionStatusColorChart = {
  Draft: "#55678d",
  "In Progress": "#4da7ff",
  Blocked: "#ff5252",
  Finalized: "#2ad876",
  Postponed: "#17a2b8",
  Delayed: "#e4b820",
  Cancelled: "#ff5252",
};

export const decisionPriority = [
  { priorityId: 0, name: "P0" },
  { priorityId: 1, name: "P1" },
  { priorityId: 2, name: "P2" },
  { priorityId: 3, name: "P3" },
  { priorityId: 4, name: "P4" },
];

export const dateFormate = "DD MMM YYYY";

export const timeFormate = "HH:mm:ss";

export const datePickerFormate = "dd MMM yyyy";

export const timePickerFormate = "h:mm a";

export const dateTimePickerFormate = "dd MMM yyyy h:mm a";

export const scrollDataLimit = 10;

export const timePickerInterval = 10;

export const logoutResponses = ["-6"];

export const passwordNotSetResponseStatus = -26;

export const notIntegratedResponseStatus = -27;

export const forgotPasswordUserNotFound = -30;

export const lockedAccountResponseStatus = -31;

export const userDeactivedStatus = -34;

export const timestampFormate = "YYYY-MM-DD HH:mm:ss";

export const commentedFormate = "MMM Do";

export const contextLabel = "•••";

export const autoSaveTimeout = 900000;

export const loginExceptRoutes = [
  "/invite-member",
  "/reset-password",
  "/forget-password",
];

export const defaultDepartment = "Global";

export const integrationFeatureType = 1;
export const analyticsFeatureType = 2;
export const exportFeatureType = 3;
export const freePlanType = 0;
export const premiumPlanType = 1;
export const premiumPlusPlanType = 2;
export const enterprisePlanType = 3;
export const expirePlanType = -1;
export const expirePlanStatus = -28;

export const accessDeniedStatus = -15;

export const expirePlanAlertMessage =
  "We are sorry your trial period has ended!. Please upgrade your plan by choosing upgrade from the drop down.";

export const premiumFeatures = [
  "Decision Creation",
  "Decision Search",
  "Anonymous Feedback",
  "Third Party Integrations",
];

export const premiumPlusFeatures = [
  "Decision Creation",
  "Decision Search",
  "Anonymous Feedback",
  "Third Party Integrations",
  "Decision Analytics",
  "Mobile Applications",
  "Archive (2+ yrs)",
];

export const enterpriseFeatures = [
  "Decision Creation",
  "Decision Search",
  "Anonymous Feedback",
  "Third Party Integrations",
  "Decision Analytics",
  "Mobile Applications",
  "Archive (2+ yrs)",
  "AI based Risk Forecast",
  "Enterprise support 24x7",
];

export const channel = "vimeo"; // "youtube"
export const videoId = "401577427"; // "L61p2uyiMSo"

export const editorTools = {
  buttonList: [
    ["undo", "redo", "font", "fontSize", "formatBlock"],
    ["bold", "underline", "italic", "strike", "link"],
    [
      "fontColor",
      "hiliteColor",
      "outdent",
      "indent",
      "align",
      "horizontalRule",
      "list",
      "table",
    ],
  ],
};
