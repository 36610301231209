import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Button,
  FormGroup,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Row,
  Col,
  Label,
  Input,
  Spinner,
} from "reactstrap";
import { Link } from "react-router-dom";
import ReeValidate from "ree-validate";
import {
  errorAlert,
  successAlert,
  warningAlert,
} from "../../../web/store/alertActions";
import { login, logout } from "../../store/loginActions";
import { setDataToLocalstorage } from "../../store/signupActions";
import {
  getProfile,
  getGoogleUrl,
} from "../../../account/store/accountActions";
import { objectToQueryParameters } from "../../../../utilities/helpers";
import { websiteUrl } from "../../../../config/config";
import {
  passwordNotSetResponseStatus,
  lockedAccountResponseStatus,
  userDeactivedStatus,
} from "../../../../constants";
import GoogleButton from "react-google-button";
import queryString from "query-string";

class RegisterEmail extends Component {
  constructor(props) {
    super(props);

    this.validator = new ReeValidate({
      email: "required|email",
      password: "required",
    });
    this.timeout = null;
    this.state = {
      googleCount: 0,
      url: "#",
      isSubmiting: false,
      formData: {
        email: "",
        password: "",
      },
      errors: this.validator.errors,
      isDisable: true,
      isGoogleDisable: true,
    };
  }

  componentDidMount() {
    let params = queryString.parse(this.props.location.search);

    if (this.state.googleCount === 0) {
      if (Object.keys(params).length !== 0) {
        if (parseInt(params.valid) === 1) {
          this.setGoogleData(params);
          this.setGoogleLoadProfile(params);
          this.props.history.push("/dashboard");
        } else {
          if (parseInt(params.status) === parseInt(userDeactivedStatus)) {
            this.props.warningAlert(
              "Your account is deactivated. Please contact your administrator."
            );
          } else {
            this.props.warningAlert(
              "Sorry, you entered an incorrect email address or password."
            );
          }
          this.props.logout();
          this.props.history.push("/login");
        }
      } else {
        if (!this.props.workspaceId) {
          this.props.history.push("/");
        }
      }
      this.setState({ googleCount: 1 });
    }

    let data = objectToQueryParameters({
      workspaceId: this.props.workspaceId,
    });

    this.props.getGoogleUrl(data).then((response) => {
      this.setState({ url: response.payload.data, isGoogleDisable: false });
    });

    if (Object.keys(params).length !== 0) {
      if (parseInt(params.valid) === 1) {
        this.props.history.push("/dashboard");
      }
      this.props.history.push("/login");
    } else {
      if (this.props.token) {
        this.props.history.push("/dashboard");
      }
    }
  }

  setGoogleData = (params) => {
    let data = {
      emailAddress: params.emailAddress,
      workspaceId: params.workspaceId,
      employeeId: params.employeeId,
      workspaceName: params.workspaceName,
      token: params.token,
      isValidate: true,
      photo: params.photoUrl,
      loginWith: 1,
    };
    this.props.setDataToLocalstorage(data);
  };

  setData = () => {
    let data = {
      emailAddress: this.state.formData.email,
      workspaceId: this.props.workspaceId,
      employeeId: this.props.employeeId,
      workspaceName: this.props.workspaceName,
      loginWith: 0,
    };
    this.props.setDataToLocalstorage(data);
  };

  setGoogleLoadProfile = (params) => {
    let data = objectToQueryParameters({
      workspaceId: params.workspaceId,
      employeeId: params.employeeId,
      token: params.token,
    });
    this.props.getProfile(data);
  };

  loadProfile = () => {
    let data = objectToQueryParameters({
      workspaceId: this.props.workspaceId,
      employeeId: this.props.employeeId,
      token: this.props.token,
    });
    this.props.getProfile(data);
  };

  handleChange = (e) => {
    this.setErrors();
    setTimeout(() => {
      this.setDisabled();
    }, 10);
    const name = e.target.name;
    const value = e.target.value;
    this.setState({
      formData: { ...this.state.formData, [name]: value },
    });
    this.validator.validate(name, value).then(() => {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.timeout = null;
        this.setErrors();
      }, 2000);
    });
  };

  setErrors = () => {
    const { errors } = this.validator;
    this.setState({ errors });
  };

  setDisabled = () => {
    this.setState({
      isDisable:
        this.state.errors.items.length > 0 ||
        this.state.formData.password === "" ||
        this.state.formData.email === "",
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { formData } = this.state;
    const { errors } = this.validator;
    this.validator.validateAll(formData).then((success) => {
      if (success) {
        this.setState({ isSubmiting: true });
        const payloadData = new FormData();
        payloadData.append("emailAddress", this.state.formData.email);
        payloadData.append("password", this.state.formData.password);
        payloadData.append("workspaceId", this.props.workspaceId);
        this.props
          .login(payloadData)
          .then(() => {
            this.props.successAlert("Login successfully...");
            this.setData();
            this.loadProfile();
            this.props.history.push("/dashboard");
          })
          .catch((err) => {
            this.setState({ isSubmiting: false });
            if (
              parseInt(err.data.status) ===
              parseInt(passwordNotSetResponseStatus)
            ) {
              this.props.warningAlert(
                "Please setup password, by clicking forget password."
              );
            } else if (
              parseInt(err.data.status) === parseInt(userDeactivedStatus)
            ) {
              this.props.warningAlert(
                "Your account is deactivated. Please contact your administrator."
              );
            } else if (
              parseInt(err.data.status) === lockedAccountResponseStatus
            ) {
              this.props.errorAlert(
                "Your account has been locked due to multiple failed login attempts. please reset your password."
              );
              this.props.history.push("/forget-password");
            } else {
              this.props.errorAlert(
                "Sorry, you entered an incorrect email address or password."
              );
            }
          });
      } else {
        this.setState({ errors });
      }
    });
  };

  connectGoogle = () => {
    window.location.href = this.state.url;
  };

  render() {
    return (
      <main>
        <Row className="split-screen"></Row>
        <Row>
          <Col sm="4"></Col>
          <Col sm="4" className="pd-10">
            <Row>
              <Col sm="4"></Col>
              <Col sm="4">
                <a href={websiteUrl} alt="website">
                  <img
                    src={"/assets/consolve-icon.png"}
                    alt="workspace"
                    className="logo-img w-50"
                  />
                </a>
              </Col>
              <Col sm="4"></Col>
            </Row>
          </Col>
          <Col sm="4"></Col>
        </Row>
        <br />
        <Row>
          <Col sm="4"></Col>
          <Col sm="4" className="pd-10">
            <Card className="color-bg">
              <CardBody className="validate-workspace-tag">
                <CardTitle>Sign in to {this.props.workspaceName}</CardTitle>
                <CardSubtitle>
                  {this.props.workspaceName}.consolve.com
                </CardSubtitle>
                <div className="main-card">
                  <form onSubmit={this.handleSubmit}>
                    <FormGroup>
                      <Label>Enter your email address & password.</Label>
                      <Input
                        type="email"
                        name="email"
                        id="email"
                        onChange={this.handleChange}
                        placeholder="you@example.com"
                      />
                    </FormGroup>
                    <FormGroup>
                      <Input
                        type="password"
                        name="password"
                        id="password"
                        onChange={this.handleChange}
                        placeholder="Password"
                      />
                    </FormGroup>
                    <Button
                      className="btn-custom btn-signin"
                      color="primary"
                      size="lg"
                      block
                      disabled={this.state.isDisable}
                    >
                      Sign In {this.state.isSubmiting && <Spinner size="sm" />}
                    </Button>

                    <GoogleButton
                      type="light"
                      label="Use Google Account"
                      onClick={this.connectGoogle}
                      disabled={this.state.isGoogleDisable}
                    />

                    <div className="links-div">
                      <Link
                        to={"/forget-password"}
                        className="card-links-first"
                      >
                        Forgot password?
                      </Link>
                    </div>
                  </form>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col sm="4"></Col>
        </Row>
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    workspaceId: state.auth.workspaceId,
    employeeId: state.auth.employeeId,
    workspaceName: state.auth.workspaceName,
    token: state.auth.token,
    isAuthenticated: state.auth.isAuthenticated,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: (data) => {
      return dispatch(logout(data));
    },
    getGoogleUrl: (data) => {
      return dispatch(getGoogleUrl(data));
    },
    login: (data) => {
      return dispatch(login(data));
    },
    errorAlert: (data) => {
      return dispatch(errorAlert(data));
    },
    successAlert: (data) => {
      return dispatch(successAlert(data));
    },
    warningAlert: (data) => {
      return dispatch(warningAlert(data));
    },
    setDataToLocalstorage: (data) => {
      return dispatch(setDataToLocalstorage(data));
    },
    getProfile: (data) => {
      return dispatch(getProfile(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterEmail);
