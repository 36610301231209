import { postRequest, getRequest } from "./RequestService";

export const getProfile = (data) => {
  return getRequest("getProfile.php?" + data);
};

export const getGoogleUrl = (data) => {
  return postRequest("getGoogleUrl.php", data);
};

export const saveProfile = (data) => {
  return postRequest("saveProfile.php", data);
};

export const changePassword = (data) => {
  return postRequest("changePassword.php", data);
};

export const uploadProfilePic = (data, header) => {
  return postRequest("uploadProfilePic.php", data, header);
};

export const getNotifications = (data) => {
  return postRequest("getActivities.php", data);
};

export const getAllIntegrations = (data) => {
  return postRequest("getAllIntegrations.php", data);
};

export const disconnectFromSlack = (data) => {
  return postRequest("uninstallSlack.php", data);
};

export const disconnectFromZoom = (data) => {
  return postRequest("uninstallZoom.php", data);
};

export const assignAdmin = (data) => {
  return postRequest("assignAdmin.php", data);
};

export const empStatusChange = (data) => {
  return postRequest("empStatusChange.php", data);
};

export const removeAdmin = (data) => {
  return postRequest("removeAdmin.php", data);
};

export const getFeatureFlags = (data) => {
  return postRequest("getFeatureFlags.php", data);
};

export const getWorkspacePlans = (data) => {
  return postRequest("getWorkspacePlans.php", data);
};

export const upgradeWorkspacePlan = (data) => {
  return postRequest("upgradeWorkspacePlan.php", data);
};

export const cancelSubscription = (data) => {
  return postRequest("cancelSubscription.php", data);
};

export const generateExportData = (data) => {
  return postRequest("generateExportData.php", data);
};
