import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import logger from "redux-logger";
import reducers from "./reducers";
import { debug } from "../config/config";

const store = debug
  ? createStore(reducers, applyMiddleware(thunk, logger))
  : createStore(reducers, applyMiddleware(thunk));

export default store;
