import React, { Component } from "react";
import { connect } from "react-redux";
import { successAlert, errorAlert } from "../../../web/store/alertActions";
import {
  assignAdmin,
  removeAdmin,
  empStatusChange,
} from "../../store/accountActions";
import { deleteDepartment } from "../../../departments/store/departmentActions";

class Context extends Component {
  getFormData = () => {
    const payloadData = new FormData();
    payloadData.append("token", this.props.token);
    payloadData.append("employeeId", this.props.employeeId);
    payloadData.append("workspaceId", this.props.workspaceId);
    return payloadData;
  };

  assignAdmin = () => {
    let payloadData = this.getFormData();
    payloadData.append("userId", this.props.id);
    this.props
      .assignAdmin(payloadData)
      .then((success) => {
        this.props.successAlert("Assigned to admin.");
        this.props.getDepartmentsAndEmployees();
      })
      .catch((e) => {
        this.props.errorAlert("Something went wrong, please try again!");
      });
  };

  empStatusChange = () => {
    let payloadData = this.getFormData();
    payloadData.append("userId", this.props.id);
    payloadData.append("status", this.props.isActive);
    this.props
      .empStatusChange(payloadData)
      .then((response) => {
        if (response.payload.emp_status === 1) {
          this.props.successAlert("Employee activated.");
        } else {
          this.props.successAlert("Employee deactivated.");
        }
        this.props.getDepartmentsAndEmployees();
      })
      .catch((e) => {
        this.props.errorAlert("Something went wrong, please try again!");
      });
  };

  removeAdmin = () => {
    let payloadData = this.getFormData();
    payloadData.append("userId", this.props.id);
    this.props
      .removeAdmin(payloadData)
      .then((success) => {
        this.props.successAlert("Removed from admin.");
        this.props.getDepartmentsAndEmployees();
      })
      .catch((e) => {
        this.props.errorAlert("Something went wrong, please try again!");
      });
  };

  deleteDepartment = () => {
    let payloadData = this.getFormData();
    payloadData.append("departmentId", this.props.id);
    this.props
      .deleteDepartment(payloadData)
      .then((success) => {
        this.props.successAlert("Department deleted.");
        this.props.getDepartmentsAndEmployees();
      })
      .catch((e) => {
        this.props.errorAlert("Something went wrong, please try again!");
      });
  };

  render() {
    return (
      this.props.visible && (
        <ul
          className="context-menu"
          style={{ left: `${this.props.x}px`, top: `${this.props.y}px` }}
        >
          {this.props.isDepartment ? (
            <li onClick={this.deleteDepartment}>Delete</li>
          ) : (
            <React.Fragment>
              {parseInt(this.props.isAdmin) === 0 ? (
                <li onClick={this.assignAdmin}>Make admin</li>
              ) : (
                <li onClick={this.removeAdmin}>Remove admin</li>
              )}
            </React.Fragment>
          )}
          {!this.props.isDepartment ? (
            <React.Fragment>
              {parseInt(this.props.isActive) === 1 ? (
                <li onClick={this.empStatusChange}>Deactive</li>
              ) : (
                <li onClick={this.empStatusChange}>Active</li>
              )}
            </React.Fragment>
          ) : (
            <React.Fragment></React.Fragment>
          )}
        </ul>
      )
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    employeeId: state.auth.employeeId,
    workspaceId: state.auth.workspaceId,
    departments: state.departments,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    empStatusChange: (data) => {
      return dispatch(empStatusChange(data));
    },
    assignAdmin: (data) => {
      return dispatch(assignAdmin(data));
    },
    removeAdmin: (data) => {
      return dispatch(removeAdmin(data));
    },
    deleteDepartment: (data) => {
      return dispatch(deleteDepartment(data));
    },
    successAlert: (data) => {
      return dispatch(successAlert(data));
    },
    errorAlert: (data) => {
      return dispatch(errorAlert(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Context);
