import moment from "moment";
import {
  dateFormate,
  logoutResponses,
  timestampFormate,
  decisionStatus,
  decisionTypes,
  timeFormate,
  timePickerFormate,
  accessDeniedStatus,
} from "../constants";
import store from "../store";
import { errorAlert } from "../modules/web/store/alertActions";
import { apiHost } from "../config/config";

export const successAction = (payload, action) => ({
  type: action,
  payload,
});

export const formatedDate = (date) => {
  return moment(date).format(dateFormate);
};

export const formateTimeUTCToLocal = (date) => {
  return moment.utc(date).local().format(timestampFormate);
};

export const formatedTime = (time) => {
  return moment.utc(time).format(timeFormate);
};

export const formatedTime12 = (time) => {
  return moment(time).format(timePickerFormate);
};

export const formatedTimestamp = (date) => {
  return moment(date).format(timestampFormate);
};

export const mergeAndFormateTimestamp = (date, time) => {
  return moment(
    formatedDate(date) + " " + formatedTime(time),
    dateFormate + " " + timeFormate
  ).format(timestampFormate);
};

export const isBeforeDate = (date) => {
  return moment(date).isBefore(moment());
};

export const isAfterDate = (date, after = moment()) => {
  return moment(date).isAfter(after);
};

export const mergeObject = (oldData, newData, key) => {
  const oldItems = oldData.filter(
    (item) => !newData.some((newData) => newData[key] === item[key])
  );
  return oldItems.concat(newData);
};

export const mergeAndSort = (oldData, newData, key, sorted = false) => {
  const oldItems = oldData.filter(
    (item) => !newData.some((newData) => newData[key] === item[key])
  );
  let data = oldItems.concat(newData);
  if (!sorted) {
    data.sort(sortBy(key));
  }
  return data;
};

export const sortBy = (key, order = "desc") => {
  return (a, b) => {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      return 0;
    }
    const varA = typeof a[key] === "string" ? a[key].toUpperCase() : a[key];
    const varB = typeof b[key] === "string" ? b[key].toUpperCase() : b[key];

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return order === "desc" ? comparison * -1 : comparison;
  };
};

export const updateObjectByKey = (array, object, key) => {
  let cobj = array.find((obj) => obj[key] === object[key]) || {};
  return [
    ...array.filter((obj) => obj[key] !== object[key]),
    { ...cobj, ...object },
  ];
};

export const updateObjectByDoubleKey = (array, object, key, key2) => {
  let cobj =
    array.find(
      (obj) => obj[key] === object[key] && obj[key2] === object[key2]
    ) || {};
  return [
    ...array.filter(
      (obj) => !(obj[key] === object[key] && obj[key2] === object[key2])
    ),
    { ...cobj, ...object },
  ];
};

export const isTokenExpire = (code) => {
  if (logoutResponses.includes(code)) {
    localStorage.removeItem("emailAddress");
    localStorage.removeItem("employeeId");
    localStorage.removeItem("workspaceId");
    localStorage.removeItem("workspaceName");
    localStorage.removeItem("token");
    localStorage.removeItem("photo_url");
    window.location.reload();
  }
};

export const isNotAccessible = (code) => {
  if (accessDeniedStatus === code) {
    store.dispatch(errorAlert("Access Denied"));
  }
};

export const findElementByKey = (items, key, value) => {
  return items.find((item) => item[key] === value);
};

export const objectToQueryParameters = (obj) => {
  return Object.keys(obj)
    .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`)
    .join("&");
};

export const isNumber = (str) => {
  var pattern = /^\d+$/;
  return pattern.test(str);
};

export const notifiedOn = (date) => {
  return moment(date).startOf("second").fromNow();
};

export const splitHostname = () => {
  let result = {};
  let regexParse = new RegExp("([a-z-0-9]{2,63}).([a-z.]{2,5})$");
  let urlParts = regexParse.exec(window.location.hostname);
  result.domain = urlParts[1];
  result.type = urlParts[2];
  result.subdomain = window.location.hostname
    .replace(result.domain + "." + result.type, "")
    .slice(0, -1);
  return result;
};

export const getStatusIdByName = (name) => {
  return decisionStatus.find((status) => status["name"] === name).id;
};

export const getDecisionTypesIdByName = (name) => {
  return decisionTypes.find((status) => status["name"] === name).id;
};

export const removeObjectByKeyValue = (array, key, value) => {
  return array.filter((arr) => {
    return arr[key] !== value;
  });
};

export const generatePdfUrl = (obj) => {
  return apiHost + "generateDecisionPdf.php?" + objectToQueryParameters(obj);
};
