import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import Department from "./department";
import {
  Col,
  Row,
  Button,
  FormGroup,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
} from "reactstrap";
import { getDepartmentsAndEmployees } from "../../../departments/store/departmentActions";
import { loadEmployees } from "../../../employees/store/employeeActions";
import Context from "./context.jsx";
import { generateExportData } from "../../store/accountActions";

import {
  expirePlanStatus,
  expirePlanAlertMessage,
  exportFeatureType,
} from "../../../../constants";
import {
  errorAlert,
  successAlert,
  warningAlert,
} from "../../../web/store/alertActions";
import { inviteMembersForWorkspace } from "../../../auth/store/signupActions";

class OrganizationSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      emails: ["", "", ""],
      errors: [],
      currentInput: "",
      isLoaded: false,
      createModal: false,
      popup: {
        id: 0,
        isAdmin: 0,
        isActive: 0,
        visible: false,
        isDepartment: false,
        x: 0,
        y: 0,
      },
    };
  }

  componentDidMount() {
    if (this.props.token) {
      if (this.props.isValidate) {
        this.loadEmployees();
        this.getDepartmentsAndEmployees();
      }
    } else {
      this.props.history.push("/");
    }
  }

  getDepartmentsAndEmployees = () => {
    const payloadData = new FormData();
    payloadData.append("token", this.props.token);
    payloadData.append("employeeId", this.props.employeeId);
    payloadData.append("workspaceId", this.props.workspaceId);
    this.props.getDepartmentsAndEmployees(payloadData);
  };

  handleChange = async (e, index) => {
    let emails = [...this.state.emails];
    const name = e.target.name;
    const value = e.target.value;
    emails[index] = value;
    this.setState({ emails });
    this.setState({ currentInput: name });
    let errors = [...this.state.errors];
    errors[index] = "";
    this.setState({ errors });
  };

  add = () => {
    this.setState((prevState) => ({ emails: [...prevState.emails, ""] }));
  };

  remove = async (index) => {
    let emails = [...this.state.emails];
    emails.splice(index, 1);
    this.setState({ emails });

    let errors = [...this.state.errors];
    errors.splice(index, 1);
    await this.setState({ errors });

    await this.resetErrors(index);
  };

  setErrors = async (value, index) => {
    let errors = this.state.errors;
    if (value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
      if (errors[index]) errors.splice(index, 1);
    } else if (value === "") {
      errors[index] = "The email field is required.";
    } else {
      errors[index] = "The email field must be a valid email.";
    }
    await this.setState({ errors });
  };

  resetErrors = async (index) => {
    let errors = this.state.errors;
    await errors.map((error, eindex) => {
      if (eindex > index) {
        return this.moveErrors(errors, eindex, eindex - 1);
      }
      return error;
    });
  };

  moveErrors = async (errors, fromIndex, toIndex) => {
    let element = errors[fromIndex];
    errors.splice(fromIndex, 1);
    errors.splice(toIndex, 0, element);
    await this.setState({ errors });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    let validate = true;
    let emails = this.state.emails;
    let errors = this.state.errors;
    let payloadEmails = [];
    await emails.map((email, index) => {
      if (email === "") {
        if (emails.length > 1) {
          if (errors[index]) errors.splice(index, 1);
        } else {
          errors[index] = "The email field is required.";
          validate = false;
        }
      } else if (!email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
        errors[index] = "The email field must be a valid email.";
        validate = false;
      } else {
        payloadEmails.push(email);
        if (errors[index]) errors.splice(index, 1);
      }
      return errors;
    });
    await this.setState({ errors });

    if (payloadEmails.length && validate) {
      const payloadData = new FormData();
      payloadData.append("emailAddress", this.props.emailAddress);
      payloadData.append("employeeId", this.props.employeeId);
      payloadData.append("workspaceId", this.props.workspaceId);
      payloadData.append("workspaceName", this.props.workspaceName);
      payloadData.append("members", JSON.stringify(payloadEmails));
      this.props
        .inviteMembersForWorkspace(payloadData)
        .then(() => {
          this.props.successAlert("Members invited successfully.");
          this.createToggle();
        })
        .catch((err) => {
          this.props.errorAlert("Something went wrong, please try again!");
        });
    }
  };

  exportData = () => {
    this.setState({
      exporting: true,
    });
    const payloadData = new FormData();
    payloadData.append("token", this.props.token);
    payloadData.append("employeeId", this.props.employeeId);
    payloadData.append("workspaceId", this.props.workspaceId);
    this.props
      .generateExportData(payloadData)
      .then((result) => {
        let filename = "consolve-export.json";
        let contentType = "application/json;charset=utf-8;";
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          var blob = new Blob(
            [
              decodeURIComponent(
                encodeURI(JSON.stringify(result.payload.data))
              ),
            ],
            { type: contentType }
          );
          navigator.msSaveOrOpenBlob(blob, filename);
        } else {
          var a = document.createElement("a");
          a.download = filename;
          a.href =
            "data:" +
            contentType +
            "," +
            encodeURIComponent(JSON.stringify(result.payload.data));
          a.target = "_blank";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
        this.setState({
          exporting: false,
        });
        this.props.successAlert("Exported successfully.");
      })
      .catch((err) => {
        this.setState({
          exporting: false,
        });
        if (parseInt(err.data.status) === expirePlanStatus) {
          this.props.warningAlert(expirePlanAlertMessage);
        } else {
          this.props.errorAlert("Something went wrong, please try again!");
        }
      });
  };

  loadEmployees = () => {
    const payloadData = new FormData();
    payloadData.append("token", this.props.token);
    payloadData.append("employeeId", this.props.employeeId);
    payloadData.append("workspaceId", this.props.workspaceId);
    this.props.loadEmployees(payloadData);
  };

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({ isLoaded: true });
    setTimeout(() => {
      this.child.setCurrentDepartment(props);
    }, 50);
  }

  createToggle = () => {
    this.setState({
      createModal: !this.state.createModal,
      emails: ["", "", ""],
    });
    // this.validator.reset();
  };

  createUI() {
    return this.state.emails.map((email, index) => {
      return (
        <Row key={index}>
          <Col md={8}>
            <FormGroup>
              <Input
                className={
                  this.state.emails.length === 1 ? "min-width-input" : ""
                }
                type="text"
                name={"email" + index}
                value={email}
                id={"email" + index}
                onChange={(e) => this.handleChange(e, index)}
                placeholder="name@example.com"
              />
              {this.state.errors[index] && (
                <label
                  id="name-error"
                  className="error"
                  htmlFor={"email" + index}
                >
                  {this.state.errors[index]}
                </label>
              )}
            </FormGroup>
          </Col>
          <Col
            md={4}
            className={
              this.state.emails.length - 1 !== index ? "close-cust" : ""
            }
          >
            {this.state.emails.length - 1 === index ? (
              <Button
                className="btn-custom btn-custom-create btn-custom-width-100"
                color="primary"
                onClick={this.add}
              >
                + &nbsp; Add
              </Button>
            ) : (
              <button
                className="link-button btn-custom btn-cust"
                href="#"
                type="button"
                onClick={() => this.remove(index)}
              >
                <img src={"/assets/cross.svg"} alt="X" />
              </button>
            )}
          </Col>
        </Row>
      );
    });
  }

  onContextMenuChange = (
    event,
    id,
    isAdmin,
    isActive,
    isDepartment = false
  ) => {
    event.preventDefault();
    if (!this.state.popup.visible) {
      const that = this;
      document.addEventListener(`click`, function onClickOutside() {
        that.setState({ popup: { visible: false } });
        document.removeEventListener(`click`, onClickOutside);
      });
    }
    this.setState({
      popup: {
        id: id,
        isAdmin: isAdmin,
        isActive: isActive,
        isDepartment: isDepartment,
        visible: true,
        exporting: false,
        x: event.pageX,
        y: event.pageY,
      },
    });
  };

  render() {
    if (!this.state.isLoaded) {
      return (
        <main className="create-main">
          <div className="loader"></div>
        </main>
      );
    }
    if (!this.props.profile.emailAddress) {
      return (
        <main className="create-main">
          <div className="loader"></div>
        </main>
      );
    } else if (!parseInt(this.props.profile.isAdmin)) {
      return <Redirect key="home" to={"/"} />;
    }

    let exportFeature = false;
    this.props.features.map((feature) => {
      if (
        parseInt(feature.featureType) === exportFeatureType &&
        parseInt(feature.isEnabled)
      ) {
        exportFeature = true;
      }
      return true;
    });

    return (
      <main className="create-main">
        <br />
        <div className="create-decision container">
          <br />
          <h5 className="page-title">Organization Settings</h5>
          <br />
          <Row>
            <Col md={3}>
              <h6 className="page-sub-title">Workspace</h6>
              <p>{this.props.originalWorkspaceName}</p>
            </Col>
            <Col md={3}>
              <h6 className="page-sub-title">Current Plan</h6>
              <p>{this.props.profile.currentPlan}</p>
            </Col>
            {exportFeature && (
              <Col md={3}>
                <Button
                  onClick={this.exportData}
                  disabled={this.state.exporting}
                  className="btn-custom btn-custom-create"
                >
                  {this.state.exporting ? "Exporting.." : "Export data"}{" "}
                  {this.state.exporting && <Spinner size="sm" />}
                </Button>
              </Col>
            )}
            <Col md={3}>
              <FormGroup>
                {/* <Link to={"/account/invite-members"}> */}
                <Button
                  className="btn-custom btn-custom-create"
                  onClick={this.createToggle}
                >
                  Invite Team Members
                </Button>
                {/* </Link> */}
              </FormGroup>
            </Col>
          </Row>
          <br />
          <Department
            onRef={(ref) => (this.child = ref)}
            getDepartmentsAndEmployees={this.getDepartmentsAndEmployees}
            onContextMenuChange={this.onContextMenuChange}
          />
          <Context
            {...this.state.popup}
            getDepartmentsAndEmployees={this.getDepartmentsAndEmployees}
          />
          <br />
        </div>
        <Modal isOpen={this.state.createModal} toggle={this.createToggle}>
          <ModalHeader toggle={this.createToggle}>Invite Members</ModalHeader>
          <ModalBody>
            <form onSubmit={this.handleSubmit}>
              {this.createUI()}
              <br />
              <Row>
                <Col md={4}></Col>
                <Col md={3}>
                  <FormGroup>
                    <Button className="btn-custom btn-custom-create btn-custom-width-100">
                      Invite
                    </Button>
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <Button
                      onClick={this.createToggle}
                      className="btn-custom btn-custom-create btn-custom-width-100"
                    >
                      Cancel
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
              <br />
            </form>
          </ModalBody>
        </Modal>
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    employeeId: state.auth.employeeId,
    workspaceId: state.auth.workspaceId,
    originalWorkspaceName: state.auth.originalWorkspaceName,
    departments: state.departments,
    profile: state.account.profile,
    isValidate: state.auth.isValidate,
    features: state.account.features,
    emailAddress: state.auth.email,
    workspaceName: state.auth.workspaceName,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    inviteMembersForWorkspace: (data) => {
      return dispatch(inviteMembersForWorkspace(data));
    },
    getDepartmentsAndEmployees: (data) => {
      return dispatch(getDepartmentsAndEmployees(data));
    },
    loadEmployees: (data) => {
      return dispatch(loadEmployees(data));
    },
    generateExportData: (data) => {
      return dispatch(generateExportData(data));
    },
    errorAlert: (data) => {
      return dispatch(errorAlert(data));
    },
    successAlert: (data) => {
      return dispatch(successAlert(data));
    },
    warningAlert: (data) => {
      return dispatch(warningAlert(data));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganizationSettings);
