import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { errorAlert, successAlert } from "../../../web/store/alertActions";
import {
  getWorkspacePlans,
  getFeatureFlags,
  cancelSubscription
} from "../../store/accountActions";
import {
  freePlanType,
  enterprisePlanType,
  premiumPlanType,
  premiumFeatures,
  premiumPlusPlanType,
  premiumPlusFeatures,
  enterpriseFeatures
} from "../../../../constants";
import { confirmAlert } from "react-confirm-alert";

class Plans extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    if (!this.props.workspaceId) {
      this.props.history.push("/");
    } else {
      this.loadPlans();
    }
  }

  loadPlans = () => {
    const payloadData = new FormData();
    payloadData.append("token", this.props.token);
    payloadData.append("employeeId", this.props.employeeId);
    payloadData.append("workspaceId", this.props.workspaceId);
    this.props.getWorkspacePlans(payloadData);
  };

  loadFeatures = () => {
    const payloadData = new FormData();
    payloadData.append("token", this.props.token);
    payloadData.append("employeeId", this.props.employeeId);
    payloadData.append("workspaceId", this.props.workspaceId);
    this.props.loadFeatures(payloadData);
  };

  handleCancelClick = () => {
    confirmAlert({
      title: "Cancel subscription",
      message: "Are you sure, want to cancel subscription ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.cancelSubscription()
        },
        {
          label: "No"
        }
      ]
    });
  };

  cancelSubscription = () => {
    const payloadData = new FormData();
    payloadData.append("token", this.props.token);
    payloadData.append("employeeId", this.props.employeeId);
    payloadData.append("workspaceId", this.props.workspaceId);
    this.props
      .cancelWorkspacePlan(payloadData)
      .then(() => {
        this.loadFeatures();
        this.props.successAlert("Your subsciption has been cancelled.");
      })
      .catch(err => {
        this.props.errorAlert("Something went wrong, please try again!");
      });
  };

  render() {
    if (!this.props.plans.length) {
      return (
        <main className="create-main">
          <div className="loader"></div>
        </main>
      );
    }
    return (
      <main className="create-main">
        <br />
        <div className="create-decision container">
          <br />
          <div className="decision-detail-heading">
            <h5 className="page-title">
              Choose the plan that’s right for your team.
            </h5>
            <span>Pay by month, and cancel at any time.</span>
          </div>
          <br />
          <hr className="plan-hr" />
          <Row>
            {this.props.plans.map((plan, index) => {
              let features = [];
              if (parseInt(plan.planType) === premiumPlanType) {
                features = premiumFeatures;
              }
              if (parseInt(plan.planType) === premiumPlusPlanType) {
                features = premiumPlusFeatures;
              }
              if (parseInt(plan.planType) === enterprisePlanType) {
                features = enterpriseFeatures;
              }
              return (
                parseInt(plan.planType) !== freePlanType && (
                  <Col key={index} md={4} className={"plan-col border-left"}>
                    <h5>
                      {plan.plan}{" "}
                      {this.props.planType === plan.planType ? (
                        <span className="sub-title">(Current Plan)</span>
                      ) : (
                        ""
                      )}
                    </h5>
                    <br />
                    {enterprisePlanType !== parseInt(plan.planType) ? (
                      <h5>${plan.monthlyPrice} USD</h5>
                    ) : (
                      <h5> </h5>
                    )}
                    {enterprisePlanType === parseInt(plan.planType) ? (
                      <p>
                        To get a price estimate for Enterprise, please contact
                        our team{" "}
                      </p>
                    ) : (
                      <p>${plan.monthlyPrice} USD per person, per month</p>
                    )}
                    <br />
                    <h5>Top Features:</h5>
                    <ul className="no_bullet">
                      {features.map((feature, index) => {
                        return (
                          <li key={index} className="leaf">
                            {feature}
                          </li>
                        );
                      })}
                    </ul>
                    <br />
                    {enterprisePlanType !== parseInt(plan.planType) ? (
                      this.props.planType === plan.planType ? (
                        <React.Fragment>
                          <Button
                            onClick={this.handleCancelClick}
                            className="btn-custom btn-custom-create btn-plan-current"
                          >
                            Cancel Subsciprion
                          </Button>
                        </React.Fragment>
                      ) : (
                        <Link to={"/account/payment/" + plan.planType}>
                          <Button className="btn-custom btn-custom-create btn-plan">
                            Upgrade Now
                          </Button>
                        </Link>
                      )
                    ) : (
                      <a
                        className="btn btn-custom btn-custom-create btn-plan"
                        href="mailto:support@consolve.com"
                      >
                        Contact Us
                      </a>
                    )}
                  </Col>
                )
              );
            })}
          </Row>
          <br />
        </div>
        <br />
      </main>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    employeeId: state.auth.employeeId,
    workspaceId: state.auth.workspaceId,
    plans: state.account.plans,
    planType: state.account.profile.planType
  };
};

const mapDispatchToProps = dispatch => {
  return {
    successAlert: data => {
      return dispatch(successAlert(data));
    },
    errorAlert: data => {
      return dispatch(errorAlert(data));
    },
    getWorkspacePlans: data => {
      return dispatch(getWorkspacePlans(data));
    },
    loadFeatures: data => {
      return dispatch(getFeatureFlags(data));
    },
    cancelWorkspacePlan: data => {
      return dispatch(cancelSubscription(data));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Plans);
