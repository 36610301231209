import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Button,
  FormGroup,
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Input,
  Spinner,
} from "reactstrap";
import ReeValidate from "ree-validate";
import { errorAlert, successAlert } from "../../../web/store/alertActions";
import { resetPasswordFromInvite } from "../../store/loginActions";
import { websiteUrl } from "../../../../config/config";
import { setDataToLocalstorage } from "../../store/signupActions";
import { objectToQueryParameters } from "../../../../utilities/helpers";
import { getProfile } from "../../../account/store/accountActions";

class ResetPasswordFromInvite extends Component {
  constructor(props) {
    super(props);

    this.validator = new ReeValidate({
      password: "required|min:5",
      confirm_password: "required",
    });
    this.timeout = null;
    this.state = {
      formData: {
        token: this.props.match.params.token,
        password: "",
        confirm_password: "",
      },
      cerrors: {
        confirm_password: "",
        password: "",
      },
      errors: this.validator.errors,
      isDisable: true,
      isSubmiting: false,
    };
  }

  handleChange = async (e) => {
    this.setErrors();
    setTimeout(() => {
      this.setDisabled();
    }, 10);
    const name = e.target.name;
    const value = e.target.value;
    await this.setState({
      formData: { ...this.state.formData, [name]: value },
    });

    if (name === "confirm_password") {
      if (this.state.formData.password !== value) {
        this.setState({
          cerrors: {
            ...this.state.cerrors,
            [name]: "Confirmation password does not match with new password.",
          },
        });
      } else {
        this.setState({
          cerrors: {
            ...this.state.cerrors,
            [name]: "",
          },
        });
      }
    } else {
      this.passwordValidator();
    }
    this.validator.validate(name, value).then(() => {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.timeout = null;
        this.setErrors();
      }, 2000);
    });
  };

  passwordValidator = () => {
    let format = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>?]/;
    if (!format.test(this.state.formData.password)) {
      this.setState({
        cerrors: {
          ...this.state.cerrors,
          password: "Password should contain one special character.",
        },
      });
    } else {
      this.setState({
        cerrors: {
          ...this.state.cerrors,
          password: "",
        },
      });
    }
  };

  setErrors = () => {
    const { errors } = this.validator;
    this.setState({ errors });
  };

  setDisabled = () => {
    this.setState({
      isDisable:
        this.state.errors.items.length > 0 ||
        this.state.formData.password === "" ||
        this.state.formData.password !== this.state.formData.confirm_password,
    });
  };

  loadProfile = (emailAddress, employeeId, token) => {
    let data = objectToQueryParameters({
      workspaceId: this.props.workspaceId,
      employeeId: employeeId,
      token: token,
    });
    this.props.getProfile(data);
  };

  setData = (payload) => {
    let data = {
      emailAddress: payload.emailAddress,
      workspaceId: this.props.workspaceId,
      employeeId: payload.employeeId,
      workspaceName: this.props.workspaceName,
      token: payload.token,
      isValidate: true,
      photo: payload.photoUrl,
      loginWith: 0,
    };

    this.props.setDataToLocalstorage(data);
  };

  logout = () => {
    localStorage.removeItem("emailAddress");
    localStorage.removeItem("employeeId");
    localStorage.removeItem("workspaceId");
    localStorage.removeItem("workspaceName");
    localStorage.removeItem("token");
    localStorage.removeItem("photoUrl");
    localStorage.setItem("from", "reset");
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.passwordValidator();
    const { formData } = this.state;
    const { errors } = this.validator;
    this.validator.validateAll(formData).then((success) => {
      if (
        success &&
        !this.state.cerrors.password &&
        !this.state.cerrors.confirm_password
      ) {
        this.setState({ isDisable: true, isSubmiting: true });
        const payloadData = new FormData();
        payloadData.append("token", this.state.formData.token);
        payloadData.append("newPassword", this.state.formData.password);
        payloadData.append("workspaceId", this.props.workspaceId);

        this.props
          .resetPasswordFromInvite(payloadData)
          .then((data1) => {
            this.logout();
            this.props.successAlert("Your account successfully configured.");
            this.setData(data1.payload);
            this.loadProfile(
              data1.payload.emailAddress,
              data1.payload.employeeId,
              data1.payload.token
            );
            this.props.history.push("/dashboard");
          })
          .catch((err) => {
            this.setState({ isDisable: false, isSubmiting: false });
            this.props.errorAlert("Something went wrong, please try again!");
          });
      } else {
        this.setState({ errors });
      }
    });
  };

  render() {
    return (
      <main>
        <Row className="split-screen"></Row>
        <Row>
          <Col sm="4"></Col>
          <Col sm="4" className="pd-10">
            <Row>
              <Col sm="4"></Col>
              <Col sm="4">
                <a href={websiteUrl} alt="website">
                  <img
                    src={"/assets/consolve-icon.png"}
                    alt="workspace"
                    className="logo-img w-50"
                  />
                </a>
              </Col>
              <Col sm="4"></Col>
            </Row>
          </Col>
          <Col sm="4"></Col>
        </Row>
        <br />
        <Row>
          <Col sm="4"></Col>
          <Col sm="4" className="pd-10">
            <Card className="color-bg">
              <CardBody className="validate-workspace-tag">
                <CardTitle>Join the Consolve workspace</CardTitle>
                <div className="main-card">
                  <form onSubmit={this.handleSubmit}>
                    <FormGroup>
                      <span className="password-text">
                        Password should have 5 characters and contain one
                        special character.
                      </span>
                      <Input
                        type="password"
                        name="password"
                        id="password"
                        onChange={this.handleChange}
                        placeholder="New Password"
                      />
                      {this.state.errors.has("password") &&
                        this.timeout === null && (
                          <label
                            id="name-error"
                            className="error"
                            htmlFor="password"
                          >
                            {this.state.errors.first("password")}
                          </label>
                        )}
                      {this.state.cerrors.password && (
                        <label id="name-error" className="error" htmlFor="name">
                          {this.state.cerrors.password}
                        </label>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <Input
                        type="password"
                        name="confirm_password"
                        id="confirm_password"
                        onChange={this.handleChange}
                        placeholder="Confirm New Password"
                      />
                      {this.state.cerrors.confirm_password && (
                        <label id="name-error" className="error" htmlFor="name">
                          {this.state.cerrors.confirm_password}
                        </label>
                      )}
                    </FormGroup>
                    <Button
                      className="btn-custom"
                      color="primary"
                      size="lg"
                      block
                      disabled={this.state.isDisable}
                    >
                      Confirm {this.state.isSubmiting && <Spinner size="sm" />}
                    </Button>
                  </form>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col sm="4"></Col>
        </Row>
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    workspaceId: state.auth.workspaceId,
    employeeId: state.auth.employeeId,
    email: state.auth.email,
    workspaceName: state.auth.workspaceName,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetPasswordFromInvite: (data) => {
      return dispatch(resetPasswordFromInvite(data));
    },
    errorAlert: (data) => {
      return dispatch(errorAlert(data));
    },
    setDataToLocalstorage: (data) => {
      return dispatch(setDataToLocalstorage(data));
    },
    successAlert: (data) => {
      return dispatch(successAlert(data));
    },
    getProfile: (data) => {
      return dispatch(getProfile(data));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPasswordFromInvite);
