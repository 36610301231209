import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col } from "reactstrap";
import {
  getDecisionStatusCount,
  getDecisionCountByMonth
} from "../store/analyticsActions";
import {
  VictoryPie,
  VictoryChart,
  VictoryLine,
  VictoryLabel,
  VictoryBar
} from "victory";
import { decisionStatusColorChart } from "../../../constants";

class Analytics extends Component {
  componentDidMount() {
    if (!this.props.token) {
      this.props.history.push("/");
    } else {
      if (this.props.isValidate) this.getStatusCountData();
    }
  }

  getStatusCountData = () => {
    const payloadData = new FormData();
    payloadData.append("token", this.props.token);
    payloadData.append("employeeId", this.props.employeeId);
    payloadData.append("workspaceId", this.props.workspaceId);
    this.props.getDecisionStatusCount(payloadData);
    this.props.getDecisionCountByMonth(payloadData);
  };

  render() {
    let pieData = [];
    let color = [];
    let pieTotal = 0;
    this.props.decisionStatusCount.map(status => {
      pieData.push({ x: status.status, y: parseInt(status.count) });
      color.push(decisionStatusColorChart[status.status]);
      pieTotal += parseInt(status.count);
      return true;
    });

    let graphData = [];
    let graphTotal = 0;
    this.props.decisionCountByMonth.map(status => {
      graphData.push({ x: status.month, y: parseInt(status.count) });
      graphTotal += parseInt(status.count);
      return true;
    });

    return (
      <main className="create-main">
        <br />
        <div className="create-decision container">
          <br />
          <h5 className="page-title">Decision Status Pie Chart</h5>
          <br />
          <Row>
            <Col md={2}></Col>
            <Col md={8} className="pie-chart-div">
              <VictoryPie
                colorScale={color}
                data={pieData}
                labels={({ datum }) => `${datum.x} (${datum.y})`}
              />
              <p>Total Decisions: {pieTotal}</p>
            </Col>
            <Col md={2}></Col>
          </Row>
          <br />
          <br />
          <h5 className="page-title">Decisions By Month</h5>
          <br />
          <Row>
            <Col md={3}></Col>
            <Col md={6} className="graph-div">
              <VictoryChart domainPadding={30}>
                <VictoryLine
                  data={graphData}
                  labels={({ datum }) => datum.y}
                  style={{
                    data: { stroke: "#2c4a7c", strokeWidth: 3 },
                    labels: {
                      fontSize: 14,
                      fontFamily: "inherit"
                    }
                  }}
                  labelComponent={<VictoryLabel renderInPortal dy={-20} />}
                />
              </VictoryChart>
              <p>Total Decisions: {graphTotal}</p>
            </Col>
            <Col md={3}></Col>
          </Row>
          <br />
          <br />
          <h5 className="page-title">Decision Status Bar</h5>
          <br />
          <Row>
            <Col md={2}></Col>
            <Col md={8} className="pie-chart-div">
              <VictoryChart domainPadding={{ x: 20 }}>
                <VictoryBar
                  horizontal
                  barWidth={20}
                  style={{
                    data: {
                      fill: ({ datum }) => decisionStatusColorChart[datum.x],
                      strokeWidth: 4
                    }
                  }}
                  labels={({ datum }) => datum.y}
                  data={pieData}
                />
              </VictoryChart>
              <p>Total Decisions: {pieTotal}</p>
            </Col>
            <Col md={2}></Col>
          </Row>
          <br />
          <br />
        </div>
      </main>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    employeeId: state.auth.employeeId,
    workspaceId: state.auth.workspaceId,
    decisionStatusCount: state.analytics.decisionStatusCount,
    decisionCountByMonth: state.analytics.decisionCountByMonth,
    isValidate: state.auth.isValidate
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getDecisionStatusCount: data => {
      return dispatch(getDecisionStatusCount(data));
    },
    getDecisionCountByMonth: data => {
      return dispatch(getDecisionCountByMonth(data));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Analytics);
