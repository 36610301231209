import React from "react";
import { FormGroup, Label, Button, Spinner } from "reactstrap";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  injectStripe
} from "react-stripe-elements";

class CardForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      elements: {},
      loading: false
    };
  }

  handleChange = change => {
    let data = this.state.elements;
    data[change.elementType] = change;
    this.setState({ elements: data });
  };

  handleSubmit = ev => {
    ev.preventDefault();
    if (!this.props.payable) {
      return true;
    }
    if (this.props.stripe) {
      this.setState({ loading: true });
      this.props.stripe
        .createToken()
        .then(payload => {
          this.props.handleSubmit(payload.token.id);
        })
        .catch(() => {
          this.setState({ loading: false });
          console.log("Something went wrong !");
        });
    } else {
      console.log("Stripe.js hasn't loaded yet.");
    }
  };

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <FormGroup>
          <Label>Card number</Label>
          <CardNumberElement onChange={this.handleChange} />
          {this.state.elements.cardNumber &&
            this.state.elements.cardNumber.error && (
              <label id="name-error" className="error">
                {this.state.elements.cardNumber.error.message}
              </label>
            )}
        </FormGroup>
        <FormGroup>
          <Label>Expiration date</Label>
          <CardExpiryElement onChange={this.handleChange} />
          {this.state.elements.cardExpiry &&
            this.state.elements.cardExpiry.error && (
              <label id="name-error" className="error">
                {this.state.elements.cardExpiry.error.message}
              </label>
            )}
        </FormGroup>
        <FormGroup>
          <Label>CVC</Label>
          <CardCVCElement onChange={this.handleChange} />
          {this.state.elements.cardCvc && this.state.elements.cardCvc.error && (
            <label id="name-error" className="error">
              {this.state.elements.cardCvc.error.message}
            </label>
          )}
        </FormGroup>
        <Button
          className="btn-custom btn-custom-create"
          disabled={this.state.loading && this.props.loading ? true : false}
        >
          Confirm Subscription{" "}
          {this.state.loading && this.props.loading && <Spinner size="sm" />}
        </Button>
      </form>
    );
  }
}

export default injectStripe(CardForm);
