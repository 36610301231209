import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Row,
  Button,
  Col,
  FormGroup,
  Input,
  Spinner,
  CardSubtitle,
} from "reactstrap";

import ReeValidate from "ree-validate";
import { errorAlert, successAlert } from "../../web/store/alertActions";
import { changePassword } from "../store/accountActions";

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.validator = new ReeValidate({
      password: "required|min:5",
      confirm_password: "required",
      oldPassword: "required",
    });

    this.timeout = null;

    this.state = {
      formData: {
        token: this.props.match.params.token,
        password: "",
        confirm_password: "",
        oldPassword: "",
      },
      cerrors: {
        confirm_password: "",
        password: "",
        oldPassword: "",
      },
      errors: this.validator.errors,
      isDisable: true,
      isSubmiting: false,
    };
  }

  componentDidMount() {
    if (this.props.token) {
      if (this.props.isValidate) this.getDecisionDetails();
    } else {
      this.props.history.push("/");
    }
  }

  handleChange = async (e) => {
    this.setErrors();
    setTimeout(() => {
      this.setDisabled();
    }, 10);
    const name = e.target.name;
    const value = e.target.value;

    await this.setState({
      formData: { ...this.state.formData, [name]: value },
    });

    if (name === "confirm_password") {
      if (this.state.formData.password !== value) {
        this.setState({
          cerrors: {
            ...this.state.cerrors,
            [name]: "Confirmation password does not match with new password.",
          },
        });
      } else {
        this.setState({
          cerrors: {
            ...this.state.cerrors,
            [name]: "",
          },
        });
      }
    } else {
      this.passwordValidator(name);
    }
    this.validator.validate(name, value).then(() => {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.timeout = null;
        this.setErrors();
      }, 2000);
    });
  };

  setErrors = () => {
    const { errors } = this.validator;
    this.setState({ errors });
  };

  setDisabled = () => {
    this.setState({
      isDisable:
        this.state.errors.items.length > 0 ||
        this.state.formData.password === "" ||
        this.state.formData.password !== this.state.formData.confirm_password,
    });
  };

  passwordValidator = (name) => {
    let format = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>?]/;

    if (name === "password") {
      if (!format.test(this.state.formData.password)) {
        this.setState({
          cerrors: {
            ...this.state.cerrors,
            password: "Password should contain one special character.",
          },
        });
      } else {
        this.setState({
          cerrors: {
            ...this.state.cerrors,
            password: "",
          },
        });
      }
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { formData } = this.state;
    const { errors } = this.validator;
    this.passwordValidator("password");
    this.passwordValidator("oldPassword");
    this.validator.validateAll(formData).then((success) => {
      if (
        success &&
        !this.state.cerrors.password &&
        !this.state.cerrors.confirm_password
      ) {
        this.setState({ isDisable: true, isSubmiting: true });

        const payloadData = new FormData();
        payloadData.append("token", this.props.token);
        payloadData.append("newPassword", this.state.formData.password);
        payloadData.append("oldPassword", this.state.formData.oldPassword);
        payloadData.append("workspaceId", this.props.workspaceId);
        this.props
          .changePassword(payloadData)
          .then(() => {
            this.props.successAlert("Password changed successfully...");
            this.props.history.push("/dashboard");
          })
          .catch((err) => {
            if (parseInt(err.data.status) === -32) {
              this.setState({ isDisable: false, isSubmiting: false });
              this.props.errorAlert(
                "Old password does not match, please try again!"
              );
            } else {
              this.setState({ isDisable: false, isSubmiting: false });
              this.props.errorAlert("Something went wrong, please try again!");
            }
          });
      } else {
        this.setState({ errors });
      }
    });
  };

  render() {
    return (
      <main className="create-main">
        <br />
        <div className="create-decision container p-b-20">
          <br />
          <h5 className="page-title">Change Password</h5>
          <br />

          <CardSubtitle>
            Enter your new password for your Consolve account.
          </CardSubtitle>

          <form onSubmit={this.handleSubmit}>
            <span className="password-text">
              Password should have 5 characters and contain one special
              character.
            </span>
            <Row className="m-t-10">
              <Col md={6}>
                <FormGroup>
                  <Input
                    type="password"
                    name="oldPassword"
                    id="oldPassword"
                    onChange={this.handleChange}
                    placeholder="Old Password"
                  />
                  {this.state.cerrors.oldPassword && (
                    <label id="name-error" className="error" htmlFor="name">
                      {this.state.cerrors.oldPassword}
                    </label>
                  )}
                  {this.state.errors.has("oldPassword") &&
                    this.timeout === null && (
                      <label className="error" htmlFor="oldPassword">
                        {this.state.errors.first("oldPassword")}
                      </label>
                    )}
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <FormGroup>
                  <Input
                    type="password"
                    name="password"
                    id="password"
                    onChange={this.handleChange}
                    placeholder="New Password"
                  />
                  {this.state.errors.has("password") && this.timeout === null && (
                    <label id="name-error" className="error" htmlFor="password">
                      {this.state.errors.first("password")}
                    </label>
                  )}
                  {this.state.cerrors.password && (
                    <label id="name-error" className="error" htmlFor="name">
                      {this.state.cerrors.password}
                    </label>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Input
                    type="password"
                    name="confirm_password"
                    id="confirm_password"
                    onChange={this.handleChange}
                    placeholder="Confirm New Password"
                  />
                  {this.state.cerrors.confirm_password && (
                    <label id="name-error" className="error" htmlFor="name">
                      {this.state.cerrors.confirm_password}
                    </label>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Button
                  className="btn-custom btn-custom-create btn-custom-width"
                  disabled={this.state.isDisable}
                >
                  Change my password{" "}
                  {this.state.isSubmiting && <Spinner size="sm" />}
                </Button>
              </Col>
            </Row>
          </form>
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    workspaceId: state.auth.workspaceId,
    token: state.auth.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changePassword: (data) => {
      return dispatch(changePassword(data));
    },
    errorAlert: (data) => {
      return dispatch(errorAlert(data));
    },
    successAlert: (data) => {
      return dispatch(successAlert(data));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
