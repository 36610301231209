import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Button,
  FormGroup,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Row,
  Col,
  Input,
} from "reactstrap";
import ReeValidate from "ree-validate";
import { errorAlert, successAlert } from "../../../web/store/alertActions";
import { findWorkspace } from "../../store/loginActions";
import { websiteUrl } from "../../../../config/config";

class FindWorkspace extends Component {
  constructor(props) {
    super(props);

    this.validator = new ReeValidate({
      email: "required|email",
    });
    this.timeout = null;
    this.state = {
      formData: {
        email: "",
      },
      errors: this.validator.errors,
      isDisable: true,
    };
  }

  handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({
      formData: { ...this.state.formData, [name]: value },
    });
    const { errors } = this.validator;
    errors["email"] = "";
    errors["items"] = [];
    this.setState({ errors });
  };

  setErrors = () => {
    const { errors } = this.validator;
    this.setState({ errors });
  };

  setDisabled = () => {
    this.setState({
      isDisable:
        this.state.errors.items.length > 0 || this.state.formData.email === "",
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const { formData } = this.state;
    const { errors } = this.validator;
    let validate = true;

    if (this.state.formData.email.trim() === "") {
      errors["email"] = ["The email field is required."];
      validate = false;
    } else if (
      !this.state.formData.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
    ) {
      errors["email"] = ["The email field must be a valid email."];
      validate = false;
    } else {
      errors["email"] = "";
      errors["items"] = [];
    }

    this.setState({ errors });

    this.validator.validateAll(formData).then((success) => {
      if (success && validate) {
        const payloadData = new FormData();
        payloadData.append("emailAddress", this.state.formData.email);
        this.props
          .findWorkspace(payloadData)
          .then(() => {
            this.props.successAlert(
              "Thank you. If your email is registered, we will email you the list of workspaces."
            );
            this.props.history.push("/");
          })
          .catch((err) => {
            if (parseInt(err.data.status) === -33) {
              this.setState({ isDisable: false, isSubmiting: false });
              this.props.errorAlert(
                "No workspace found connecting with this email!"
              );
            } else {
              this.setState({ isDisable: false, isSubmiting: false });
              this.props.errorAlert("Something went wrong, please try again!");
            }
          });
      } else {
        this.setState({ errors });
      }
    });
  };

  render() {
    return (
      <main>
        <Row className="split-screen"></Row>
        <Row>
          <Col sm="4"></Col>
          <Col sm="4" className="pd-10">
            <Row>
              <Col sm="4"></Col>
              <Col sm="4">
                <a href={websiteUrl} alt="website">
                  <img
                    src={"/assets/consolve-icon.png"}
                    alt="workspace"
                    className="logo-img w-50"
                  />
                </a>
              </Col>
              <Col sm="4"></Col>
            </Row>
          </Col>
          <Col sm="4"></Col>
        </Row>
        <br />
        <Row>
          <Col sm="4"></Col>
          <Col sm="4" className="pd-10">
            <Card className="color-bg">
              <CardBody>
                <CardTitle>Find your Consolve workspace</CardTitle>
                <CardSubtitle>
                  We’ll send you an email to confirm your address and find
                  existinFindWorkspaceg workspaces you’ve joined or can join.
                </CardSubtitle>
                <div className="main-card">
                  <form onSubmit={this.handleSubmit}>
                    <FormGroup>
                      <Input
                        type="text"
                        name="email"
                        id="email"
                        onChange={this.handleChange}
                        placeholder="you@example.com"
                      />
                    </FormGroup>
                    <Button
                      className="btn-custom"
                      color="primary"
                      size="lg"
                      block
                      //disabled={this.state.isDisable}
                    >
                      Confirm
                    </Button>
                    {this.state.errors.has("email") && this.timeout === null && (
                      <label id="name-error" className="error" htmlFor="email">
                        Sorry, but that email is invalid.
                      </label>
                    )}
                  </form>
                  <br />
                  <img
                    alt="workspace"
                    src={"/assets/find-workspace.svg"}
                    className="w-50"
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col sm="4"></Col>
        </Row>
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    findWorkspace: (data) => {
      return dispatch(findWorkspace(data));
    },
    errorAlert: (data) => {
      return dispatch(errorAlert(data));
    },
    successAlert: (data) => {
      return dispatch(successAlert(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FindWorkspace);
