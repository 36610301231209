import React, { Component } from "react";
import { connect } from "react-redux";
import { Table } from "reactstrap";

import ReactDragListView from "react-drag-listview/lib/index.js";

class TestTemplate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [
        {
          key: "1",
          name: "Boran",
          gender: "male",
          age: "12",
          address: "New York",
        },
        {
          key: "2",
          name: "JayChou",
          gender: "male",
          age: "38",
          address: "TaiWan",
        },
        {
          key: "3",
          name: "Lee",
          gender: "female",
          age: "22",
          address: "BeiJing",
        },
        {
          key: "4",
          name: "ChouTan",
          gender: "male",
          age: "31",
          address: "HangZhou",
        },
        {
          key: "5",
          name: "AiTing",
          gender: "female",
          age: "22",
          address: "Xi’An",
        },
      ],
    };

    const that = this;
    this.dragProps = {
      onDragEnd(fromIndex, toIndex) {
        const data = [...that.state.data];
        const item = data.splice(fromIndex, 1)[0];
        data.splice(toIndex, 0, item);
        that.setState({
          data,
        });
      },
      nodeSelector: "tr",
      handleSelector: "tr",
    };
  }

  componentDidMount() {}

  render() {
    return (
      <div style={{ margin: 20 }}>
        <h2>Table row with dragging</h2>
        <ReactDragListView {...this.dragProps}>
          <Table>
            <thead>
              <th>
                <tr>
                  <td>1</td>
                  <td>2</td>
                  <td>3</td>
                  <td>4</td>
                  <td>5</td>
                </tr>
              </th>
            </thead>
            <tbody>
              {this.state.data.map((column) => {
                return (
                  <tr>
                    <td>{column.key}</td>
                    <td>{column.name}</td>
                    <td>{column.gender}</td>
                    <td>{column.age}</td>
                    <td>{column.address}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </ReactDragListView>
      </div>
    );
  }
}
export default connect()(TestTemplate);
