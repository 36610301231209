import React, { Component } from "react";
import { connect } from "react-redux";
import ModalVideo from "react-modal-video";
import "react-modal-video/scss/modal-video.scss";
import {
  Row,
  Button,
  Table,
  Col,
  FormGroup,
  Input,
  Badge,
  UncontrolledTooltip,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";
import InfiniteScroll from "react-infinite-scroller";
import {
  loadDecisions,
  loadDecisionsCount,
  searchDecisions,
  loadSearchDecisions,
  deleteDecisionTemplate,
  updateOnboarding,
} from "../store/decisionActions";
import {
  errorAlert,
  warningAlert,
  successAlert,
} from "../../web/store/alertActions";
import {
  decisionStatus,
  decisionStatusColor,
  scrollDataLimit,
  decisionPriority,
  contextLabel,
  expirePlanType,
  expirePlanAlertMessage,
  channel,
  videoId,
} from "../../../constants";
import { formatedDate, isAfterDate } from "../../../utilities/helpers";
import Context from "../../../common/context";
import {
  getProfile,
  getFeatureFlags,
} from "../../account/store/accountActions";
import { confirmAlert } from "react-confirm-alert";
import DropdownLink from "../../../common/dropdown-link";
import { Steps, Hints } from "intro.js-react";
import "intro.js/introjs.css";

class ListDecisions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stepsEnabled: false,
      initialStep: 0,
      steps: [
        {
          element: ".bellintro",
          intro: "Activities & Notifications.",
        },
        {
          element: ".searchintro",
          intro: "Search Decisions.",
        },
        {
          element: ".createintro",
          intro: "Create Decision or Discussion Template.",
        },
      ],
      hintsEnabled: false,
      hints: [],
      totalDecisionCount: 0,
      offset: 0,
      orderBy: null,
      order: null,
      decisionStatus: decisionStatus,
      decisionStatusColor: decisionStatusColor,
      isSearch: false,
      searchText: "",
      searchStatus: 0,
      hasMoreData: true,
      loading: true,
      pageLoading: true,
      sorting: false,
      rowHovered: 0,
      isOpen: false,
      isApprover: false,
      count: 0,
      createOpen: false,
      popup: {
        id: 0,
        visible: false,
        btnVisible: true,
        x: 0,
        y: 0,
        isCoordinator: false,
        isApprover: false,
        status: "",
        approvalSent: false,
      },
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.props.token) {
      if (this.props.isValidate) {
        this.loadDecisionsCount();
        this.loadDecisions(true);
        if (!this.props.featureLoaded) {
          this.loadFeatures();
        }
      }
    } else {
      this.props.history.push("/");
    }
    if (
      this.props.currentPlan &&
      parseInt(this.props.currentPlan) === expirePlanType
    ) {
      this.props.warningAlert(expirePlanAlertMessage);
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (
      parseInt(props.VideoDisplayed) === 0 &&
      parseInt(this.state.count) === 0
    ) {
      this.setState({ stepsEnabled: true });
    }
  }

  updateOnboard = () => {
    const requestData = new FormData();
    requestData.append("workspaceId", this.props.workspaceId);
    requestData.append("employeeId", this.props.employeeId);
    requestData.append("token", this.props.token);

    this.props
      .updateOnboarding(requestData)
      .then(async (response) => {
        this.setState({ isOpen: true, count: 1 });
      })
      .catch((e) => {});
  };

  onContextMenuChange = (
    event,
    id,
    coordinator,
    status,
    approver,
    approvalSent,
    isMember,
    creatorId,
    typecheck
  ) => {
    event.preventDefault();

    if (typecheck !== 1) {
      if (!this.state.popup.visible) {
        const that = this;
        document.addEventListener(`click`, function onClickOutside() {
          that.setState({ popup: { visible: false } });
          document.removeEventListener(`click`, onClickOutside);
        });
      }
    }

    let btnVisible = true;
    let visible = true;
    if (typecheck === 1) {
      btnVisible = false;
    }

    let isCoordinator = false;
    let isApprover = false;
    if (
      (coordinator !== this.props.employeeId &&
        creatorId !== +this.props.employeeId) ||
      status === "Finalized"
    ) {
      btnVisible = false;
    }
    if (coordinator === this.props.employeeId) {
      isCoordinator = true;
    }

    if (approver === this.props.employeeId) {
      isApprover = true;
    }

    this.setState({
      popup: {
        id: id,
        visible: visible,
        btnVisible: btnVisible,
        x: event.pageX,
        y: event.pageY,
        isCoordinator: isCoordinator,
        isApprover: isApprover,
        approvalSent: approvalSent ? true : false,
        isMember: isMember ? true : false,
        status: status,
      },
    });
  };

  sort = async (name) => {
    let order = "asc";
    if (name === this.state.orderBy) {
      order = this.state.order === "asc" ? "desc" : "asc";
    }
    await this.setState({
      orderBy: name,
      offset: 0,
      sorting: true,
      hasMoreData: true,
      order,
    });
    this.loadDecisions();
  };

  handleDelete = (id) => {
    const requestData = new FormData();
    requestData.append("workspaceId", this.props.workspaceId);
    requestData.append("decisionId", id);
    requestData.append("employeeId", this.props.employeeId);
    requestData.append("token", this.props.token);

    this.props
      .deleteDecisionTemplate(requestData)
      .then(() => {
        this.props.successAlert("Decision deleted successfully.");
      })
      .catch((e) => {
        this.props.errorAlert("Something went wrong with search decisions!");
      });
  };

  deleteDecision = (id) => {
    confirmAlert({
      title: "Delete decision",
      message: "Are you sure, want to delete this decision?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.handleDelete(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  handleRowClick = (decision) => {
    if (
      decision.decisionStatus === "Finalized" ||
      decision.decisionStatus === "Cancelled"
    ) {
      window.location.href = "/decision/view/" + decision.decisionId;
    } else if (decision.coordinatorId === this.props.employeeId) {
      window.location.href = "/decision/coordinator/" + decision.decisionId;
    } else if (
      decision.approverId === this.props.employeeId &&
      +decision.approvalSent
    ) {
      window.location.href = "/decision/coordinator/" + decision.decisionId;
    } else if (+decision.isMember) {
      window.location.href = "/decision/member/" + decision.decisionId;
    } else {
      window.location.href = "/decision/view/" + decision.decisionId;
    }
  };

  redirectTo = (to) => {
    this.props.history.push(to);
  };

  loadDecisions = async (onLoad = false) => {
    const requestData = new FormData();
    requestData.append("workspaceId", this.props.workspaceId);
    requestData.append("employeeId", this.props.employeeId);
    requestData.append("token", this.props.token);
    requestData.append("offset", this.state.offset);
    requestData.append("limit", scrollDataLimit);
    this.state.orderBy && requestData.append("orderBy", this.state.orderBy);
    this.state.order && requestData.append("order", this.state.order);
    if (this.state.searchText !== "")
      requestData.append("decisionText", this.state.searchText);
    if (this.state.searchStatus !== 0)
      requestData.append("decisionStatusId", this.state.searchStatus);
    let onLoadFlag = onLoad;
    if (this.state.isSearch) {
      await this.props
        .loadSearchDecisions(requestData)
        .then(async (response) => {
          await this.setHasMore();
          await this.setOffset();
          this.setState({ loading: false });
          if (onLoadFlag) {
            this.setState({ pageLoading: false });
          }
        })
        .catch((e) => {
          this.setHasMore();
          this.setState({ loading: false });
          if (onLoadFlag) {
            this.setState({ pageLoading: false });
          }
          this.props.errorAlert("Something went wrong with search decisions!");
        });
    } else {
      await this.props
        .loadDecisions(requestData)
        .then(async (response) => {
          await this.setHasMore();
          await this.setOffset();
          this.setState({ loading: false });
          if (onLoadFlag) {
            this.stopLoading();
          }
        })
        .catch((e) => {
          this.setHasMore();
          this.setState({ loading: false });
          if (onLoadFlag) {
            this.stopLoading();
          }
          this.props.errorAlert("Something went wrong with load decisions!");
        });
    }
  };

  loadDecisionsCount = () => {
    const payloadData = new FormData();
    payloadData.append("workspaceId", this.props.workspaceId);
    payloadData.append("employeeId", this.props.employeeId);
    payloadData.append("token", this.props.token);
    this.props
      .loadDecisionsCount(payloadData)
      .then((response) => {
        this.setState({
          totalDecisionCount: response.payload.data,
        });
      })
      .catch((err) => {
        this.props.errorAlert("Something went wrong, please try again!");
      });
  };

  loadFeatures = () => {
    const payloadData = new FormData();
    payloadData.append("token", this.props.token);
    payloadData.append("employeeId", this.props.employeeId);
    payloadData.append("workspaceId", this.props.workspaceId);
    this.props.loadFeatures(payloadData);
  };

  toggleCreateDropdown = () => {
    this.setState({
      createOpen: !this.state.createOpen,
    });
  };

  stopLoading = () => {
    this.setState({ pageLoading: false });
  };

  setHasMore = async (offset = this.state.offset + scrollDataLimit) => {
    // if (offset !== this.props.decisions.length) {
    //   await this.setState({ hasMoreData: false });
    // }
    if (offset > this.state.totalDecisionCount) {
      await this.setState({ hasMoreData: false });
    }
  };

  setOffset = async (offset = this.state.offset + scrollDataLimit) => {
    await this.setState({ offset, sorting: false });
  };

  loadMoreDecisions = async () => {
    if (!this.state.loading) {
      await this.setState({ loading: true });
      await this.loadDecisions();
    }
  };

  handleSearchChange = async (e) => {
    let name = e.target.name;
    await this.setState({ [name]: e.target.value });
    if (name === "searchStatus") {
      this.search();
    }
  };

  handleMouseHover = (e, id) => {
    this.setState({
      rowHovered: id,
    });
  };

  search = async () => {
    await this.setState({ loading: true, isSearch: true, hasMoreData: true });
    const requestData = new FormData();
    requestData.append("workspaceId", this.props.workspaceId);
    requestData.append("employeeId", this.props.employeeId);
    requestData.append("token", this.props.token);
    requestData.append("offset", 0);
    requestData.append("limit", scrollDataLimit);
    if (this.state.searchText !== "")
      requestData.append("decisionText", this.state.searchText);
    if (this.state.searchStatus !== 0)
      requestData.append("decisionStatusId", this.state.searchStatus);
    await this.props
      .searchDecisions(requestData)
      .then(async (response) => {
        await this.setHasMore(scrollDataLimit);
        await this.setOffset(scrollDataLimit);
        await this.setState({ loading: false });
      })
      .catch((e) => {
        this.setState({ loading: false });
        this.setHasMore();
        this.props.errorAlert("Something went wrong with search decisions!");
      });
  };
  onExit = () => {
    this.setState(() => ({ stepsEnabled: false }));
    this.updateOnboard();
    this.setState({ isOpen: true, count: 1 });
  };

  render() {
    if (this.state.pageLoading) {
      return (
        <main className="create-main">
          <div className="loader"></div>
        </main>
      );
    }

    return (
      <main>
        <Steps
          enabled={this.state.stepsEnabled}
          steps={this.state.steps}
          initialStep={this.state.initialStep}
          onExit={this.onExit}
        />
        <Hints enabled={this.state.hintsEnabled} hints={this.state.hints} />

        <div className="dashboard-custom container">
          <br />

          <ModalVideo
            channel={channel}
            isOpen={this.state.isOpen}
            videoId={videoId}
            onClose={() => this.setState({ isOpen: false })}
            ratio="16:9"
            allowFullScreen={true}
            animationSpeed={500}
            autopause={false}
          />

          <Row className="search-bar">
            <Col md={4}>
              <FormGroup>
                <Input
                  placeholder="Search"
                  name={"searchText"}
                  className="search"
                  value={this.state.searchText}
                  onChange={this.handleSearchChange}
                />
              </FormGroup>
            </Col>
            <Col md={2}>
              <Button
                color={"primary"}
                onClick={this.search}
                className="btn-seacrh searchintro"
              >
                Search
              </Button>
            </Col>
            <Col md={3}></Col>
            <Col md={3}>
              <FormGroup>
                <Input
                  type="select"
                  name={"searchStatus"}
                  onChange={this.handleSearchChange}
                >
                  <option value={0}>All</option>
                  {this.state.decisionStatus.map((status, index) => {
                    return (
                      <option key={index} value={status.id}>
                        {status.name}
                      </option>
                    );
                  })}
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <br />
          <Row>
            <Dropdown
              isOpen={this.state.createOpen}
              toggle={this.toggleCreateDropdown}
            >
              <DropdownToggle tag="span">
                <Button className="btn-custom btn-custom-create createintro">
                  + Create
                </Button>
              </DropdownToggle>
              <DropdownMenu className="profile-dropdown" right={false}>
                <DropdownLink
                  className="dropdown-links"
                  to="/decision/create"
                  onClick={this.toggleCreateDropdown}
                >
                  Decision Template
                </DropdownLink>
                <DropdownLink
                  className="dropdown-links"
                  to="/discussion/create"
                  onClick={this.toggleCreateDropdown}
                >
                  Discussion Template
                </DropdownLink>
              </DropdownMenu>
            </Dropdown>
          </Row>
          <br />
          <InfiniteScroll
            pageStart={this.state.offset}
            loadMore={this.loadMoreDecisions}
            hasMore={this.state.hasMoreData}
            loader={
              <Row key={0} className="container justify-content-center">
                <div className="loader"></div>
              </Row>
            }
          >
            <Row className="table-dashboard justify-content-center">
              <Table className="table-custom" responsive hover>
                <thead>
                  <tr>
                    <th
                      onClick={() => this.sort("title")}
                      className="table-th-fixed"
                    >
                      Decision Title
                    </th>
                    <th></th>
                    <th onClick={() => this.sort("departmentId")}>
                      Department
                    </th>
                    <th onClick={() => this.sort("decisionStatusId")}>
                      Status
                    </th>
                    <th onClick={() => this.sort("meetingDate")}>
                      Meeting Date
                    </th>
                    <th onClick={() => this.sort("dueDate")}>Deadline</th>
                    <th onClick={() => this.sort("priority")}>Priority</th>
                  </tr>
                </thead>
                <tbody>
                  {!this.state.sorting &&
                    this.props.decisions.map((decision, index) => {
                      let classes = this.state.decisionStatusColor[
                        decision.decisionStatus
                      ];
                      return (
                        <tr
                          onMouseEnter={(e) => {
                            this.handleMouseHover(e, decision.decisionId);
                            this.onContextMenuChange(
                              e,
                              decision.decisionId,
                              decision.coordinatorId,
                              decision.decisionStatus,
                              decision.approverId,
                              +decision.approvalSent,
                              +decision.isMember,
                              +decision.creatorId,
                              1
                            );
                          }}
                          onMouseLeave={(e) => this.handleMouseHover(e, 0)}
                          key={index}
                          onContextMenu={(e) =>
                            this.onContextMenuChange(
                              e,
                              decision.decisionId,
                              decision.coordinatorId,
                              decision.decisionStatus,
                              decision.approverId,
                              +decision.approvalSent,
                              +decision.isMember,
                              +decision.creatorId,
                              0
                            )
                          }
                        >
                          <td onClick={() => this.handleRowClick(decision)}>
                            {decision.title}
                          </td>
                          <td
                            className="td-valign-center table-td-padding-0 "
                            onClick={(e) =>
                              this.onContextMenuChange(
                                e,
                                decision.decisionId,
                                decision.coordinatorId,
                                decision.decisionStatus,
                                decision.approverId,
                                +decision.approvalSent,
                                +decision.isMember,
                                +decision.creatorId,
                                0
                              )
                            }
                          >
                            {(parseInt(decision.coordinatorId) !==
                              parseInt(this.props.employeeId) &&
                              parseInt(decision.creatorId) !==
                                parseInt(this.props.employeeId)) ||
                            decision.decisionStatus === "Finalized"
                              ? null
                              : ((decision.decisionStatus !== "Finalized" &&
                                  decision.decisionStatus !== "Cancelled") ||
                                  decision.decisionStatus === "Draft") && (
                                  <span
                                    className={
                                      this.state.rowHovered ===
                                      decision.decisionId
                                        ? "context-label black-color"
                                        : "context-label white-color"
                                    }
                                  >
                                    {contextLabel}
                                  </span>
                                )}
                          </td>
                          <td>{decision.departmentName}</td>
                          <td>
                            <Badge color={"info"} className={classes}>
                              {decision.decisionStatus}
                            </Badge>
                          </td>
                          <td>{formatedDate(decision.meetingDate)}</td>
                          <td>{formatedDate(decision.dueDate)}</td>
                          <td className="td-valign-center">
                            {decisionPriority.map((value, index) => {
                              if (
                                parseInt(value.priorityId) ===
                                parseInt(decision.priority)
                              ) {
                                return value.name;
                              } else {
                                return "";
                              }
                            })}

                            <span>
                              {parseInt(decision.isApproved) === 0 &&
                              parseInt(decision.approvalSent) === 1 &&
                              parseInt(decision.approverId) ===
                                parseInt(this.props.employeeId) ? (
                                <React.Fragment>
                                  <img
                                    className="exclamation-icon1"
                                    src={"/assets/warning.svg"}
                                    alt="exclamation"
                                    id={"exclamation1_" + decision.decisionId}
                                  />{" "}
                                  <UncontrolledTooltip
                                    placement="top"
                                    target={
                                      "exclamation1_" + decision.decisionId
                                    }
                                  >
                                    Pending Approval
                                  </UncontrolledTooltip>
                                </React.Fragment>
                              ) : (
                                " "
                              )}
                            </span>
                            <span>
                              {isAfterDate(
                                decision.updatedOn,
                                decision.dueDate
                              ) ? (
                                <React.Fragment>
                                  <img
                                    className="exclamation-icon"
                                    src={"/assets/exclamation-mark.svg"}
                                    alt="exclamation"
                                    id={"exclamation_" + decision.decisionId}
                                  />
                                  <UncontrolledTooltip
                                    placement="top"
                                    target={
                                      "exclamation_" + decision.decisionId
                                    }
                                  >
                                    Decision Delayed
                                  </UncontrolledTooltip>
                                </React.Fragment>
                              ) : (
                                " "
                              )}
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
              <Context
                {...this.state.popup}
                deleteDecision={this.deleteDecision}
                redirectTo={this.redirectTo}
              />
            </Row>
          </InfiniteScroll>
          <br />
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    decisions: state.decisions,
    workspaceId: state.auth.workspaceId,
    employeeId: state.auth.employeeId,
    token: state.auth.token,
    isValidate: state.auth.isValidate,
    currentPlan: state.account.currentPlan,
    VideoDisplayed: state.account.profile.isVideoDisplayed,
    featureLoaded: state.account.featureLoaded,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadDecisionsCount: (data) => {
      return dispatch(loadDecisionsCount(data));
    },

    loadDecisions: (data) => {
      return dispatch(loadDecisions(data));
    },
    searchDecisions: (data) => {
      return dispatch(searchDecisions(data));
    },
    loadSearchDecisions: (data) => {
      return dispatch(loadSearchDecisions(data));
    },
    updateOnboarding: (data) => {
      return dispatch(updateOnboarding(data));
    },
    errorAlert: (data) => {
      return dispatch(errorAlert(data));
    },
    successAlert: (data) => {
      return dispatch(successAlert(data));
    },
    warningAlert: (data) => {
      return dispatch(warningAlert(data));
    },
    getProfile: (data) => {
      return dispatch(getProfile(data));
    },
    loadFeatures: (data) => {
      return dispatch(getFeatureFlags(data));
    },
    deleteDecisionTemplate: (data) => {
      return dispatch(deleteDecisionTemplate(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListDecisions);
