import {
  SUCCESS_ALERT,
  ERROR_ALERT,
  RESET_ALERT,
  WARNING_ALERT
} from "../action-types";
import { resetAlert } from "../alertActions";
import store from "../../../../store";

const initialState = {
  text: "",
  color: "",
  visible: false
};

const alert = (state = initialState, action) => {
  switch (action.type) {
    case SUCCESS_ALERT:
      return showAlert(action.msg, "success");
    case WARNING_ALERT:
      return showAlert(action.msg, "warning");
    case ERROR_ALERT:
      return showAlert(action.msg, "danger");
    case RESET_ALERT:
      return initialState;
    default:
      return state;
  }
};

const showAlert = (msg, color) => {
  setTimeout(() => {
    return store.dispatch(resetAlert());
  }, 5000);
  return {
    text: msg,
    color: color,
    visible: true
  };
};

export default alert;
