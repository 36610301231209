import { LOAD_EMPLOYEES } from "./action-types";
import { mergeObject } from "../../../utilities/helpers";

const initialState = [];

const reducer = (state = initialState, { type, payload = null }) => {
  switch (type) {
    case LOAD_EMPLOYEES:
      return loadEmployees(state, payload);
    default:
      return state;
  }
};

const loadEmployees = (state, payload) => {
  return Object.assign([], mergeObject(state, payload.employees, "employeeId"));
};

export default reducer;
