import {
  REGISTER_EMAIL,
  CREATE_WORKSPACE,
  VALIDATE_WORKSPACE,
  VALIDATE_LOGIN,
  SET_DATA_TO_LOCALSTORAGE,
  LOGOUT,
} from "./action-types";
import {
  GET_PROFILE,
  UPLOAD_PROFILE_PIC,
} from "../../account/store/action-types";

const initialState = {
  isAuthenticated: localStorage.getItem("token") ? true : false,
  email: localStorage.getItem("emailAddress") || "",
  token: localStorage.getItem("token") || "",
  workspaceName: localStorage.getItem("workspaceName") || "",
  originalWorkspaceName: "",
  employeeId: localStorage.getItem("employeeId") || 0,
  workspaceId: localStorage.getItem("workspaceId") || 0,
  photo: localStorage.getItem("photoUrl") || "",
  isValidate: false,
  loginWith: localStorage.getItem("loginWith") || 0,
};

const reducer = (state = initialState, { type, payload = null }) => {
  switch (type) {
    case REGISTER_EMAIL:
      return registerEmail(state, payload);
    case CREATE_WORKSPACE:
      return createWorkspace(state, payload);
    case VALIDATE_WORKSPACE:
      return validateWorkspace(state, payload);
    case VALIDATE_LOGIN:
      return validateLogin(state, payload);
    case SET_DATA_TO_LOCALSTORAGE:
      return welcomeDashboard(state);
    case LOGOUT:
      return logout(state);
    case GET_PROFILE:
      return updateProfilePic(state, payload);
    case UPLOAD_PROFILE_PIC:
      return updateProfilePic(state, payload);
    default:
      return state;
  }
};

const registerEmail = (state, payload) => {
  return {
    ...state,
    employeeId: payload.employeeId,
    token: payload.token,
    email: payload.email,
  };
};

const createWorkspace = (state, payload) => {
  return {
    ...state,
    workspaceId: payload.workspaceId,
    workspaceName: payload.name,
    originalWorkspaceName: payload.workspaceName,
  };
};

const validateWorkspace = (state, payload) => {
  return {
    ...state,
    workspaceId: payload.workspaceId,
    originalWorkspaceName: payload.workspaceName,
    isValidate: true,
    workspaceName: payload.name,
  };
};

const validateLogin = (state, payload) => {
  localStorage.setItem("token", payload.token);
  return {
    ...state,
    token: payload.token,
    employeeId: payload.employeeId,
    email: payload.emailAddress,
    isAuthenticated: true,
  };
};

const updateProfilePic = (state, payload) => {
  if (payload.photoUrl) {
    localStorage.setItem("photoUrl", payload.photoUrl);
  }
  return {
    ...state,
    photo: payload.photoUrl,
  };
};

const welcomeDashboard = (state) => {
  return {
    ...state,
    isAuthenticated: true,
    email: localStorage.getItem("emailAddress") || "",
    token: localStorage.getItem("token") || "",
    workspaceName: localStorage.getItem("workspaceName") || "",
    employeeId: localStorage.getItem("employeeId") || 0,
    workspaceId: localStorage.getItem("workspaceId") || 0,
  };
};

const logout = (state) => {
  return {
    ...state,
    isAuthenticated: false,
    email: "",
    token: "",
    workspaceName: "",
    employeeId: 0,
    workspaceId: 0,
    photo: "",
  };
};

export default reducer;
