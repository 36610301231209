import {
  GET_PROFILE,
  UPLOAD_PROFILE_PIC,
  GET_NOTIFICATIONS,
  GET_ALL_INTEGRATIONS,
  GET_FEATURE_FLAGS,
  FEATURE_LOADED,
  GET_WORKSPACE_PLANS,
  UPGRADE_WORKSPACE_PLAN,
  CANCEL_SUBSCRIPTION,
  UPDATE_ONBOARDING
} from "./action-types";

const initialState = {
  profile: {
    photoUrl: localStorage.getItem("photoUrl") || ""
  },
  notifications: [],
  integrations: [],
  features: [],
  featureLoaded: false,
  plans: [],
  currentPlan: localStorage.getItem("currentPlan")
    ? atob(localStorage.getItem("currentPlan"))
    : 0
};

const reducer = (state = initialState, { type, payload = null }) => {
  switch (type) {
    case GET_PROFILE:
      return loadProfile(state, payload);
    case UPLOAD_PROFILE_PIC:
      return updateProfilePic(state, payload);
    case GET_NOTIFICATIONS:
      return loadNotifications(state, payload);
    case GET_ALL_INTEGRATIONS:
      return setIntegrations(state, payload);
    case GET_FEATURE_FLAGS:
      return setFeatureFlags(state, payload);
    case GET_WORKSPACE_PLANS:
      return setWorkspacePlan(state, payload);
    case UPGRADE_WORKSPACE_PLAN:
      return updateCurrentPlan(state, 0);
    case CANCEL_SUBSCRIPTION:
      return updateCurrentPlan(state, -1);
    case UPDATE_ONBOARDING:
      return updateOnboarding(state, payload);
    case FEATURE_LOADED:
      return featureLoaded(state);
    default:
      return state;
  }
};

const loadProfile = (state, payload) => {
  if (payload.photoUrl) {
    localStorage.setItem("photoUrl", payload.photoUrl);
  }
  if (payload.planType) {
    localStorage.setItem("currentPlan", btoa(payload.planType));
  }
  return {
    ...state,
    profile: payload,
    currentPlan: payload.planType
  };
};

const updateProfilePic = (state, payload) => {
  let profile = state.profile;
  profile.photoUrl = payload.photoUrl;
  if (payload.photoUrl) {
    localStorage.setItem("photoUrl", payload.photoUrl);
  }
  return {
    ...state,
    profile: profile
  };
};

const loadNotifications = (state, payload) => {
  if (payload.offset !== "0") {
    return {
      ...state,
      notifications: [...state.notifications, ...payload.activities]
    };
  } else {
    return {
      ...state,
      notifications: payload.activities
    };
  }
};

const setIntegrations = (state, payload) => {
  return {
    ...state,
    integrations: payload.integrations
  };
};

const setFeatureFlags = (state, payload) => {
  return {
    ...state,
    features: payload.features
  };
};

const featureLoaded = (state) => {
  return {
    ...state,
    featureLoaded: true
  };
};

const setWorkspacePlan = (state, payload) => {
  return {
    ...state,
    plans: payload.plans
  };
};

const updateCurrentPlan = (state, pid) => {
  localStorage.setItem("currentPlan", btoa(pid));
  return {
    ...state,
    currentPlan: pid
  };
};

const updateOnboarding = (state, payload) => {
  let profile = state.profile;
  profile.isVideoDisplayed = 1;

  return {
    ...state,
    profile: profile
  };
};
export default reducer;
