import { combineReducers } from "redux";
import auth from "../modules/auth/store/reducer";
import alert from "../modules/web/store/reducers/alert";
import loader from "../modules/web/store/reducers/loader";
import decisions from "../modules/decisions/store/reducer";
import departments from "../modules/departments/store/reducer";
import employees from "../modules/employees/store/reducer";
import account from "../modules/account/store/reducer";
import analytics from "../modules/analytics/store/reducer";

export default combineReducers({
  auth: auth,
  alert: alert,
  loader: loader,
  decisions: decisions,
  departments: departments,
  employees: employees,
  account: account,
  analytics: analytics
});
