import React from "react";
import { connect } from "react-redux";
import { Alert } from "reactstrap";

const AlertComponent = props => {
  return (
    <Alert className='alert-custom' color={props.alert.color} isOpen={props.alert.visible}>
      {props.alert.text}
    </Alert>
  );
};

const mapStateToProps = state => {
  return {
    alert: state.alert
  };
};

export default connect(mapStateToProps)(AlertComponent);
