export const apiHost = "https://api.consolve.com/consolve/rest/";

export const serverUrl = "https://api.consolve.com";

export const websiteUrl = "https://consolve.com";

export const httpHost = "https://";

export const clientHost = "consolve.com";

export const slackConnectUrl =
  "https://slack.com/oauth/v2/authorize?scope=incoming-webhook&client_id=683906814500.787823368662";

export const zoomConnectUrl =
  "https://zoom.us/oauth/authorize?response_type=code&client_id=3oSTDSkSaawtrcaAhh9UA&redirect_uri=https://api.consolve.com/consolve/rest/getZoomRedirect.php";

export const defaultClientUrl = "https://app.consolve.com";

export const stripeApiKey = "pk_live_5WZeLfTS8qrIG6QYNFHD7ToR00WBtoIlUc";

export const debug = false;
