import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Row,
  Label,
  Col,
  FormGroup,
  Input,
  Button,
  Form,
  Spinner,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import Editor from "../../../common/editor";
import { CopyToClipboard } from "react-copy-to-clipboard";
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import ReeValidate from "ree-validate";
import { loadEmployees } from "../../employees/store/employeeActions";
import { loadDepartments } from "../../departments/store/departmentActions";
import {
  errorAlert,
  successAlert,
  warningAlert,
} from "../../web/store/alertActions";
import SelectRow from "../../../common/select";
import "react-datepicker/dist/react-datepicker.css";
import {
  datePickerFormate,
  decisionPriority,
  timePickerFormate,
  timePickerInterval,
  notIntegratedResponseStatus,
  expirePlanType,
  expirePlanAlertMessage,
  expirePlanStatus,
} from "../../../constants";
import {
  createDecisionTemplate,
  createZoomMeeting,
} from "../store/decisionActions";
import {
  formatedTimestamp,
  mergeAndFormateTimestamp,
  sortBy,
} from "../../../utilities/helpers";
import moment from "moment";
import Tooltip from "react-simple-tooltip";
import {css} from "styled-components";

class CreateDecisionTemplate extends Component {
  constructor(props) {
    super(props);

    this.validator = new ReeValidate({
      title: "required",
      members: "required",
      department: "required",
      coordinator: "required",
      approvers: "required",
      meetingDate: "required",
      dueDate: "required",
      meetingTime: "",
      meetingEndTime: "",
      description: "",
      anonymousFeedback: "",
      allowpoints: "",
      column0: "required",
      priority: "required",
      tooltipOpen: "",
      hasCollabNote: "",
      visibleToDepartment: "",
    });

    this.state = {
      dueDisabled: true,
      departmentOptions: [],
      employeeOptions: [],
      department: null,
      coordinator: null,
      members: [],
      approvers: [],
      discussionColumns: [""],
      errorDiscussion: false,
      isSubmiting: false,
      copyText: "Copy",
      form: {
        title: "",
        description: "",
        department: "",
        members: "",
        approvers: "",
        coordinator: "",
        approver: "",
        meetingDate: "",
        dueDate: "",
        meetingTime: new Date(),
        meetingEndTime: new Date(),
        anonymousFeedback: false,
        allowpoints: false,
        hasCollabNote: true,
        visibleToDepartment: true,
        calendarInvite: false,
        zoomMeeting: false,
        column0: "",
        priority: "",
        zoomUrl: "",
        tooltipOpen: false,
      },
      errors: this.validator.errors,
      columnsErrors: "",
    };
  }

  componentDidMount() {
    if (this.props.token) {
      if (this.props.isValidate) this.loadDepartmentsAndEmployees();
    } else {
      this.props.history.push("/");
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.updateCurrentState(props);
}

updateCurrentState = async (props) => {
  let defaultApprover = await props.employees.filter((employee) => {
    return (employee.employeeId == this.props.employeeId);
  });


  this.setState({
    approvers: defaultApprover,
    coordinator: defaultApprover[0],
    members: defaultApprover,
  });

  this.setState({
    form: {
      ...this.state.form,
      approvers:  defaultApprover.map(
        (defaultApprover) => defaultApprover.employeeId
      ),
      coordinator: defaultApprover[0].employeeId,
      members:defaultApprover.map(
        (defaultApprover) => defaultApprover.employeeId
      ),
    },
  });
};


  loadDepartmentsAndEmployees = () => {
    const requestData = new FormData();
    requestData.append("workspaceId", this.props.workspaceId);
    requestData.append("employeeId", this.props.employeeId);
    requestData.append("token", this.props.token);
    this.props.loadEmployees(requestData);
    this.props.loadDepartments(requestData);
  };

  onSelectChange = async (name, value, valueKey, isMulti) => {
    if (isMulti) {
      let values = [];
      if (value) {
        await value.map((item) => {
          return values.push(item[valueKey]);
        });
      }
      await this.setState({
        form: { ...this.state.form, [name]: values },
        [name]: value,
      });
      this.validateField(name, values);
    } else {
      let val = "";
      if (value) {
        val = value[valueKey];
      }
      await this.setState({
        form: { ...this.state.form, [name]: val },
        [name]: value,
      });
      this.validateField(name, val);
    }
  };

  onDateChange = async (name, value) => {
    await this.setState({
      form: { ...this.state.form, [name]: value },
    });
    if (name === "meetingDate") {
      this.setState({ dueDisabled: false });
      await this.setState({
        form: { ...this.state.form, ["dueDate"]: "" },
      });
    }
    this.validateField(name, value);
  };

  handleChange = async (e) => {
    const name = e.target.name;
    const value = e.target.value;
    await this.setState({
      form: { ...this.state.form, [name]: value },
    });
    if (name === "title") {
      let discussionColumns = this.state.discussionColumns;
      discussionColumns[0] = value;
      this.setState({
        form: { ...this.state.form, column0: value },
        discussionColumns,
      });
    }
    this.validateField(name, value);
  };

  tooltipOpenHandle = () => {
    this.setState({ tooltipOpen: true });
  };
  tooltipCloseHandle = () => {
    this.setState({ tooltipOpen: false });
  };

  handleEditorChange = (description, name) => {
    this.setState({
      form: { ...this.state.form, [name]: description },
    });
    this.validateField("description", description);
  };

  add = () => {
    this.setState((prevState) => ({
      discussionColumns: [...prevState.discussionColumns, ""],
    }));
  };

  remove = async (index) => {
    let discussionColumns = [...this.state.discussionColumns];
    discussionColumns.splice(index, 1);
    this.setState({ discussionColumns });
  };

  onMutipleChange = async (index, e) => {
    const name = e.target.name;
    const value = e.target.value;

    if (name === "column0") {
      await this.setState({
        form: { ...this.state.form, [name]: value },
      });
      this.validateField(name, value);
    }
    this.setState({ errorDiscussion: false });
    let discussionColumns = [...this.state.discussionColumns];

    discussionColumns[index] = value;
    this.setState({ discussionColumns });
  };

  validateField = (name, value) => {
    this.validator.validate(name, value).then(() => {
      const { errors } = this.validator;
      this.setState({ errors });
    });
  };

  handleCheckboxChange = (name) => {
    this.setState({
      form: { ...this.state.form, [name]: !this.state.form[name] },
    });
    if (
      !this.state.form[name] &&
      name === "zoomMeeting" &&
      !this.state.form.zoomUrl
    ) {
      this.createZoomMeeting();
    }
  };

  createZoomMeeting = () => {
    const payloadData = new FormData();
    payloadData.append("token", this.props.token);
    payloadData.append("employeeId", this.props.employeeId);
    payloadData.append("workspaceId", this.props.workspaceId);
    payloadData.append("title", this.state.form.title);
    this.props
      .createZoomMeeting(payloadData)
      .then((success) => {
        this.setState({
          form: { ...this.state.form, zoomUrl: success.payload.url },
        });
      })
      .catch((err) => {
        if (parseInt(err.data.status) === notIntegratedResponseStatus) {
          this.props.warningAlert(
            "Please connect Zoom account using Integrations!"
          );
        } else {
          this.props.errorAlert(
            "Something went wrong with create zoom meeting!"
          );
        }
      });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { form } = this.state;
    const { errors } = this.validator;
    this.validator.validateAll(form).then((success) => {
      if (success) {
        this.storeDecisionTemplate();
      } else {
        this.setState({ errors });
      }
    });
  };

  storeDecisionTemplate = () => {
    if (parseInt(this.props.currentPlan) === expirePlanType) {
      this.props.warningAlert(expirePlanAlertMessage);
      return true;
    }
    let newArray = this.state.discussionColumns.filter(
      (element) => element !== ""
    );

    if (parseInt(newArray.length) < 1) {
      this.setState({ errorDiscussion: true });
    } else {
      this.setState({ errorDiscussion: false, isSubmiting: true });
      const payloadData = new FormData();
      payloadData.append("token", this.props.token);
      payloadData.append("employeeId", this.props.employeeId);
      payloadData.append("creatorId", this.props.employeeId);
      payloadData.append("workspaceId", this.props.workspaceId);
      payloadData.append("departmentId", this.state.form.department);
      payloadData.append("coordinatorId", this.state.form.coordinator);
      payloadData.append("title", this.state.form.title);
      payloadData.append("description", this.state.form.description);
      if (this.state.form.zoomMeeting) {
        payloadData.append("zoomUrl", this.state.form.zoomUrl);
      }
      if (this.state.form.calendarInvite) {
        payloadData.append(
          "meetingDate",
          mergeAndFormateTimestamp(
            this.state.form.meetingDate,
            this.state.form.meetingTime
          )
        );
        payloadData.append(
          "meetingTime",
          mergeAndFormateTimestamp(
            this.state.form.meetingDate,
            this.state.form.meetingTime
          )
        );
        payloadData.append(
          "meetingEndTime",
          mergeAndFormateTimestamp(
            this.state.form.meetingDate,
            this.state.form.meetingEndTime
          )
        );
      } else {
        payloadData.append(
          "meetingDate",
          formatedTimestamp(this.state.form.meetingDate)
        );
      }
      payloadData.append("dueDate", formatedTimestamp(this.state.form.dueDate));

      payloadData.append(
        "hasCollabNote",
        this.state.form.hasCollabNote ? 1 : 0
      );

      payloadData.append(
        "visibleToDepartment",
        this.state.form.visibleToDepartment ? 1 : 0
      );

      payloadData.append(
        "anonymousFeedback",
        this.state.form.anonymousFeedback ? 1 : 0
      );
      payloadData.append("allowpoints", this.state.form.allowpoints ? 1 : 0);
      payloadData.append(
        "calendarInvite",
        this.state.form.calendarInvite ? 1 : 0
      );
      payloadData.append(
        "decisionColumns",
        JSON.stringify(this.state.discussionColumns)
      );
      payloadData.append("members", JSON.stringify(this.state.form.members));
      payloadData.append(
        "approvers",
        JSON.stringify(this.state.form.approvers)
      );
      payloadData.append("priority", this.state.form.priority);
      payloadData.append("isDiscussion", 1);

      this.props
        .createDecisionTemplate(payloadData)
        .then(() => {
          this.props.successAlert("Decision template created successfully !");
          this.props.history.push("/dashboard");
          // window.location.reload();
        })
        .catch((err) => {
          this.setState({ isSubmiting: false });
          if (parseInt(err.data.status) === expirePlanStatus) {
            this.props.warningAlert(expirePlanAlertMessage);
          } else {
            this.props.errorAlert("Something went wrong, please try again!");
          }
        });
    }
  };

  handleCopy = () => {
    this.setState({
      copyText: "Copied",
    });
    setTimeout(() => {
      this.setState({
        copyText: "Copy",
      });
    }, 2000);
  };

  render() {
    return (
      <main className="create-main">
        <br />
        <div className="create-decision container">
          <br />
          <h5 className="page-title">Create a Discussion Template</h5>
          <br />
          <Form className="form-custom" onSubmit={this.handleSubmit}>
            <Row>
              <Col md={5}>
                <FormGroup>
                  <Label>Title</Label>
                  <Input
                    type="text"
                    name="title"
                    onChange={this.handleChange}
                    value={this.state.form.title}
                  />
                  {this.state.errors.has("title") && (
                    <label id="name-error" className="error" htmlFor="title">
                      {this.state.errors.first("title")}
                    </label>
                  )}
                </FormGroup>
              </Col>
              <Col md={5}>
                <FormGroup>
                  <Label>Department</Label>
                  <SelectRow
                    name="department"
                    classes="z-index-select"
                    items={this.props.departments}
                    nameField={"name"}
                    valueField={"departmentId"}
                    value={this.state.department || null}
                    onChange={this.onSelectChange}
                  />
                  {this.state.errors.has("department") && (
                    <label
                      id="name-error"
                      className="error"
                      htmlFor="department"
                    >
                      {this.state.errors.first("department")}
                    </label>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={10}>
                <FormGroup>
                  <Label>Description</Label>
                  <Editor
                    name="description"
                    onChange={this.handleEditorChange}
                  />
                  {this.state.errors.has("description") && (
                    <label
                      id="name-error"
                      className="error"
                      htmlFor="description"
                    >
                      {this.state.errors.first("description")}
                    </label>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={5}>
                <FormGroup>
                  <Label>Meeting Start Date</Label>
                  <DatePicker
                    className="form-control"
                    name="meetingDate"
                    autoComplete="off"
                    minDate={new Date()}
                    dateFormat={datePickerFormate}
                    selected={this.state.form.meetingDate}
                    onChange={(date) => this.onDateChange("meetingDate", date)}
                  />
                  {this.state.errors.has("meetingDate") && (
                    <label
                      id="name-error"
                      className="error"
                      htmlFor="meetingDate"
                    >
                      {this.state.errors.first("meetingDate")}
                    </label>
                  )}
                </FormGroup>
              </Col>
              <Col md={5}>
                <FormGroup>
                  <Label>Due Date</Label>
                  <DatePicker
                    className="form-control"
                    name="dueDate"
                    autoComplete="off"
                    dateFormat={datePickerFormate}
                    minDate={this.state.form.meetingDate}
                    selected={this.state.form.dueDate}
                    onChange={(date) => this.onDateChange("dueDate", date)}
                    disabled={this.state.dueDisabled}
                  />
                  {this.state.errors.has("dueDate") && (
                    <label id="name-error" className="error" htmlFor="dueDate">
                      {this.state.errors.first("dueDate")}
                    </label>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={5}>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      name={"calendarInvite"}
                      onChange={() =>
                        this.handleCheckboxChange("calendarInvite")
                      }
                      checked={this.state.form.calendarInvite}
                      value={this.state.form.calendarInvite}
                    />{" "}
                    Send Calendar Invite
                  </Label>
                </FormGroup>
              </Col>
              {this.state.form.calendarInvite && (
                <Col md={5}>
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        name={"zoomMeeting"}
                        onChange={() =>
                          this.handleCheckboxChange("zoomMeeting")
                        }
                        checked={this.state.form.zoomMeeting}
                        value={this.state.form.zoomMeeting}
                      />{" "}
                      Add zoom meeting
                    </Label>
                  </FormGroup>
                </Col>
              )}
            </Row>
            {this.state.form.calendarInvite && (
              <React.Fragment>
                <Row>
                  <Col md={2}>
                    <FormGroup>
                      <Label>Meeting Start Time</Label>
                      <DatePicker
                        className="form-control"
                        name="meetingTime"
                        autoComplete="off"
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={timePickerInterval}
                        timeCaption="Start"
                        dateFormat={timePickerFormate}
                        selected={this.state.form.meetingTime}
                        onChange={(date) =>
                          this.onDateChange("meetingTime", date)
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col md={2}>
                    <FormGroup>
                      <Label>Meeting End Time</Label>
                      <DatePicker
                        className="form-control"
                        name="meetingEndTime"
                        autoComplete="off"
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={timePickerInterval}
                        minTime={this.state.form.meetingTime}
                        maxTime={moment().endOf("day").toDate()}
                        timeCaption="End"
                        timeFormat={timePickerFormate}
                        dateFormat={timePickerFormate}
                        selected={this.state.form.meetingEndTime}
                        onChange={(date) =>
                          this.onDateChange("meetingEndTime", date)
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col md={1} />
                  {this.state.form.zoomMeeting && this.state.form.zoomUrl && (
                    <Col md={5}>
                      <FormGroup>
                        <Label>&nbsp;</Label>
                        <InputGroup onClick={this.handleCopy}>
                          <CopyToClipboard text={this.state.form.zoomUrl}>
                            <React.Fragment>
                              <Input
                                type="text"
                                onFocus={(event) => event.target.select()}
                                onClick={(event) => event.target.select()}
                                value={this.state.form.zoomUrl}
                              />
                              <InputGroupAddon addonType="append">
                                <InputGroupText className="copy-text">
                                  {this.state.copyText}
                                </InputGroupText>
                              </InputGroupAddon>
                            </React.Fragment>
                          </CopyToClipboard>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  )}
                </Row>
              </React.Fragment>
            )}
            <br />
            <Row>
              <Col md={5}>
                <FormGroup>
                  <Label>Driver</Label>
                  <span>&nbsp;</span>
                    <Tooltip
                      content="Driver. The one person responsible for corralling stakeholders, collating all the necessary information and getting a decision made by the agreed date."
                      placement="right"
                      background="#2c4a7c"
                      border="#2c4a7c"
                      fontFamily="inherit"
                      fontSize="inherit"
                      padding="8"
                      arrow="10"
                      customCss={css`
                      white-space: nowrap;
                    `}
                    >
                    <img
                      src="/assets/questions.svg"
                      id={"tool_que"}
                      alt="question"
                      className="cursor-pointer question-icon mrm-4"
                      width="17px"
                    ></img>
                  </Tooltip>
                  <SelectRow
                    name="coordinator"
                    items={this.props.employees.sort(
                      sortBy("displayName", "ASC")
                    )}
                    avatar={true}
                    nameField={"displayName"}
                    valueField={"employeeId"}
                    value={this.state.coordinator || null}
                    onChange={this.onSelectChange}
                  />
                  {this.state.errors.has("coordinator") && (
                    <label
                      id="name-error"
                      className="error"
                      htmlFor="coordinator"
                    >
                      {this.state.errors.first("coordinator")}
                    </label>
                  )}
                </FormGroup>
              </Col>

              <Col md={5}>
                <FormGroup>
                  <Label>Approvers</Label>
                  <span>&nbsp;</span>
                    <Tooltip
                      content="Approver. The one person who makes the decision."
                      placement="right"
                      background="#2c4a7c"
                      border="#2c4a7c"
                      fontFamily="inherit"
                      fontSize="inherit"
                      padding="8"
                      arrow="10"
                      customCss={css`
                      white-space: nowrap;
                    `}
                    >
                    <img
                      src="/assets/questions.svg"
                      id={"tool_que"}
                      alt="question"
                      className="cursor-pointer question-icon mrm-4"
                      width="17px"
                    ></img>
                  </Tooltip>
                  <SelectRow
                    name="approvers"
                    items={this.props.employees.sort(
                      sortBy("displayName", "ASC")
                    )}
                    avatar={true}
                    multi={true}
                    nameField={"displayName"}
                    valueField={"employeeId"}
                    value={this.state.approvers || null}
                    onChange={this.onSelectChange}
                  />
                  {this.state.errors.has("approvers") && (
                    <label
                      id="name-error"
                      className="error"
                      htmlFor="approvers"
                    >
                      {this.state.errors.first("approvers")}
                    </label>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={5}>
                <FormGroup>
                  <Label>Contributors</Label>
                  <span>&nbsp;</span>
                    <Tooltip
                      content="Contributors. They have knowledge or expertise that may influence the decision."
                      placement="right"
                      background="#2c4a7c"
                      border="#2c4a7c"
                      fontFamily="inherit"
                      fontSize="inherit"
                      padding="8"
                      arrow="10"
                      customCss={css`
                      white-space: nowrap;
                    `}
                    >
                    <img
                      src="/assets/questions.svg"
                      id={"tool_que"}
                      alt="question"
                      className="cursor-pointer question-icon mrm-4"
                      width="17px"
                    ></img>
                  </Tooltip>
                  <SelectRow
                    name="members"
                    items={this.props.employees.sort(
                      sortBy("displayName", "ASC")
                    )}
                    avatar={true}
                    nameField={"displayName"}
                    valueField={"employeeId"}
                    multi={true}
                    value={this.state.members}
                    onChange={this.onSelectChange}
                  />
                  {this.state.errors.has("members") && (
                    <label id="name-error" className="error" htmlFor="members">
                      {this.state.errors.first("members")}
                    </label>
                  )}
                </FormGroup>
              </Col>
              <Col md={5}>
                <FormGroup>
                  <Label>Priority</Label>
                  <SelectRow
                    name="priority"
                    items={decisionPriority}
                    nameField={"name"}
                    valueField={"priorityId"}
                    value={this.state.priority || null}
                    onChange={this.onSelectChange}
                  />
                  {this.state.errors.has("priority") && (
                    <label id="name-error" className="error" htmlFor="priority">
                      {this.state.errors.first("priority")}
                    </label>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Label>Discussion Columns</Label>
            <span>&nbsp;</span>
            <Tooltip
              content="Comparision columns useful for data aggrigation from members."
              placement="right"
              background="#2c4a7c"
              border="#2c4a7c"
              fontFamily="inherit"
              fontSize="inherit"
              padding="8"
              arrow="10"
              customCss={css`
              white-space: nowrap;
            `}
            >
            <img
              src="/assets/questions.svg"
              id={"tool_que"}
              alt="question"
              className="cursor-pointer question-icon mrm-4"
              width="17px"
            ></img>
           </Tooltip>
            {this.state.errorDiscussion ? (
              <p id="name-error" className="error" htmlFor={"column1"}>
                Discussion column field is required.
              </p>
            ) : null}
            {this.state.discussionColumns.map((column, index) => {
              return (
                <Row key={index}>
                  <Col md={5}>
                    <FormGroup>
                      <Input
                        type="text"
                        onChange={(e) => this.onMutipleChange(index, e)}
                        value={column}
                        name={"column" + index}
                      />{" "}
                      {this.state.errors.has("column" + index) && (
                        <label
                          id="name-error"
                          className="error"
                          htmlFor={"column" + index}
                        >
                          Discussion column field is required.
                        </label>
                      )}
                    </FormGroup>
                  </Col>
                  <Col
                    md={6}
                    className={
                      this.state.discussionColumns.length - 1 !== index
                        ? "close-cust"
                        : ""
                    }
                  >
                    {this.state.discussionColumns.length - 1 === index ? (
                      <React.Fragment>
                        {this.state.discussionColumns.length > 2 && (
                          <button
                            className="link-button btn-custom btn-cust-x"
                            href="#"
                            type="button"
                            onClick={() => this.remove(index)}
                          >
                            <img src={"/assets/cross.svg"} alt="X" />
                          </button>
                        )}{" "}
                        <Button
                          color="primary"
                          className="btn-custom btn-custom-create btn-custom-width-add"
                          onClick={this.add}
                        >
                          + &nbsp; Add
                        </Button>
                      </React.Fragment>
                    ) : (
                      <button
                        className="link-button btn-custom btn-cust"
                        href="#"
                        type="button"
                        onClick={() => this.remove(index)}
                      >
                        <img src={"/assets/cross.svg"} alt="X" />
                      </button>
                    )}
                  </Col>
                </Row>
              );
            })}
{/*            <Row>
              <Col>
                <FormGroup check>
                  <Label check className="pr-2">
                    <Input
                      type="checkbox"
                      name={"hasCollabNote"}
                      onChange={() =>
                        this.handleCheckboxChange("hasCollabNote")
                      }
                      checked={this.state.form.hasCollabNote}
                      value={this.state.form.hasCollabNote}
                    />{" "}
                    Collab Note
                  </Label>
                  <Tooltip
                      content="Collaborative real-time editable note for discussions and note taking."
                      placement="right"
                      background="#2c4a7c"
                      border="#2c4a7c"
                      fontFamily="inherit"
                      fontSize="inherit"
                      padding="8"
                      arrow="10"
                      customCss={css`
                      white-space: nowrap;
                    `}
                    >
                    <img
                      src="/assets/questions.svg"
                      id={"tool_que"}
                      alt="question"
                      className="cursor-pointer question-icon mrm-4"
                      width="17px"
                    ></img>
                  </Tooltip>
                </FormGroup>
              </Col>
            </Row> */}
            <Row>
              <Col>
                <FormGroup check>
                  <Label check className="pr-2">
                    <Input
                      type="checkbox"
                      name={"visibleToDepartment"}
                      onChange={() =>
                        this.handleCheckboxChange("visibleToDepartment")
                      }
                      checked={this.state.form.visibleToDepartment}
                      value={this.state.form.visibleToDepartment}
                    />{" "}
                    Visible To Department
                  </Label>
                  <Tooltip
                      content="Decision will be visible to all employees part of your department."
                      placement="right"
                      background="#2c4a7c"
                      border="#2c4a7c"
                      fontFamily="inherit"
                      fontSize="inherit"
                      padding="8"
                      arrow="10"
                      customCss={css`
                      white-space: nowrap;
                    `}
                    >
                    <img
                      src="/assets/questions.svg"
                      id={"tool_que"}
                      alt="question"
                      class="cursor-pointer question-icon mrm-4"
                      width="17px"
                    ></img>
                  </Tooltip>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      name={"anonymousFeedback"}
                      onChange={() =>
                        this.handleCheckboxChange("anonymousFeedback")
                      }
                      checked={this.state.form.anonymousFeedback}
                      value={this.state.form.anonymousFeedback}
                    />{" "}
                    Anonymous Feedback
                  </Label>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      name={"allowpoints"}
                      onChange={() => this.handleCheckboxChange("allowpoints")}
                      checked={this.state.form.allowpoints}
                      value={this.state.form.allowpoints}
                    />{" "}
                    Allow Points
                  </Label>
                </FormGroup>
              </Col>
            </Row>
            <br />
            <Row>
              <Col md={8}></Col>
              <Col md={2}>
                <FormGroup>
                  <Button
                    className="btn-custom btn-custom-create btn-custom-width"
                    disabled={this.state.isSubmiting ? true : false}
                  >
                    Create {this.state.isSubmiting && <Spinner size="sm" />}
                  </Button>
                </FormGroup>
              </Col>
              <Col md={2}>
                <FormGroup>
                  <Link to={"/dashboard"}>
                    <Button className="btn-custom btn-custom-create btn-custom-width">
                      Cancel
                    </Button>
                  </Link>
                </FormGroup>
              </Col>
            </Row>
            <br />
          </Form>
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    workspaceId: state.auth.workspaceId,
    employeeId: state.auth.employeeId,
    token: state.auth.token,
    departments: state.departments,
    employees: state.employees,
    isValidate: state.auth.isValidate,
    currentPlan: state.account.currentPlan,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadDepartments: (data) => {
      return dispatch(loadDepartments(data));
    },
    loadEmployees: (data) => {
      return dispatch(loadEmployees(data));
    },
    errorAlert: (data) => {
      return dispatch(errorAlert(data));
    },
    successAlert: (data) => {
      return dispatch(successAlert(data));
    },
    warningAlert: (data) => {
      return dispatch(warningAlert(data));
    },
    createDecisionTemplate: (data) => {
      return dispatch(createDecisionTemplate(data));
    },
    createZoomMeeting: (data) => {
      return dispatch(createZoomMeeting(data));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateDecisionTemplate);
