import {
  VALIDATE_WORKSPACE,
  VALIDATE_LOGIN,
  FORGET_PASSWORD,
  RESET_PASSWORD,
  FIND_WORKSPACE,
  RESET_PASSWORD_FROM_INVITE,
  LOGOUT,
  GOOGLE_LOGOUT,
} from "./action-types";
import * as LoginService from "../../../services/LoginService";
import { successAction } from "../../../utilities/helpers";

export const validateWorkspace = (data) => {
  return (dispatch) => {
    return LoginService.validateWorkspace(data).then((response) => {
      return dispatch(successAction(response.data, VALIDATE_WORKSPACE));
    });
  };
};

export const login = (data) => {
  return (dispatch) => {
    return LoginService.login(data).then((response) => {
      return dispatch(successAction(response.data, VALIDATE_LOGIN));
    });
  };
};

export const googleLogout = (data) => {
  return (dispatch) => {
    return LoginService.googleLogout(data).then((response) => {
      return dispatch(successAction(response.data, GOOGLE_LOGOUT));
    });
  };
};

export const forgetPassword = (data) => {
  return (dispatch) => {
    return LoginService.forgetPassword(data).then((response) => {
      return dispatch(successAction(response.data, FORGET_PASSWORD));
    });
  };
};

export const resetPassword = (data) => {
  return (dispatch) => {
    return LoginService.resetPassword(data).then((response) => {
      return dispatch(successAction(response.data, RESET_PASSWORD));
    });
  };
};

export const findWorkspace = (data) => {
  return (dispatch) => {
    return LoginService.findWorkspace(data).then((response) => {
      return dispatch(successAction(response.data, FIND_WORKSPACE));
    });
  };
};

export const resetPasswordFromInvite = (data) => {
  return (dispatch) => {
    return LoginService.resetPasswordFromInvite(data).then((response) => {
      return dispatch(successAction(response.data, RESET_PASSWORD_FROM_INVITE));
    });
  };
};

export const logout = () => {
  return (dispatch) => {
    localStorage.removeItem("emailAddress");
    localStorage.removeItem("employeeId");
    localStorage.removeItem("workspaceId");
    localStorage.removeItem("workspaceName");
    localStorage.removeItem("token");
    localStorage.removeItem("photoUrl");
    return dispatch(successAction({}, LOGOUT));
  };
};
