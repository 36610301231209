import { LOAD_EMPLOYEES } from "./action-types";
import * as EmployeeService from "../../../services/EmployeeService";
import { successAction } from "../../../utilities/helpers";

export const loadEmployees = data => {
  return dispatch => {
    return EmployeeService.loadEmployees(data).then(response => {
      return dispatch(successAction(response.data, LOAD_EMPLOYEES));
    });
  };
};
