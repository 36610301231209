import React from "react";
import { Route, Switch } from "react-router-dom";
import PageNotFound from "../common/page-not-found/index";
import SignupStart from "../modules/auth/views/signup/SignupStart";
import RegisterEmail from "../modules/auth/views/signup/RegisterEmail";
import ValidateVerificationCode from "../modules/auth/views/signup/ValidateVerificationCode";
import CreateWorkspace from "../modules/auth/views/signup/CreateWorkspace";
import InviteMembersForWorkspace from "../modules/auth/views/signup/InviteMembersForWorkspace";
import WelcomeSignup from "../modules/auth/views/signup/WelcomeSignup";
import ValidateWorkspace from "../modules/auth/views/login/ValidateWorkspace";
import LoginValidate from "../modules/auth/views/login/LoginValidate";
import ForgetPassword from "../modules/auth/views/login/ForgetPassword";
import ResetPassword from "../modules/auth/views/login/ResetPassword";
import FindWorkspace from "../modules/auth/views/login/FindWorkspace";
import ResetPasswordFromInvite from "../modules/auth/views/login/ResetPasswordFromInvite";
import ListDecisions from "../modules/decisions/views/ListDecisions";
import CreateDecisionTemplate from "../modules/decisions/views/CreateDecisionTemplate";
import EditDecisionTemplate from "../modules/decisions/views/EditDecisionTemplate";
import CreateDecisionDetailsMember from "../modules/decisions/views/CreateDecisionDetailsMember";
import CreateDecisionDetailsCoordinator from "../modules/decisions/views/CreateDecisionDetailsCoordinator";
import ViewDecisionDetails from "../modules/decisions/views/ViewDecisionDetails";
import Profile from "../modules/account/views/Profile";
import ChangePassword from "../modules/account/views/ChangePassword";
import InviteMembers from "../modules/account/views/InviteMembers";
import OrganizationSetttings from "../modules/account/views/organization";
import Notifications from "../modules/account/views/Notifications";
import Integrations from "../modules/account/views/Integrations";
import SlackConnect from "../modules/account/views/SlackConnect";
import Analytics from "../modules/analytics/views/Analytics";
import Plans from "../modules/account/views/payment/Plans";
import Payment from "../modules/account/views/payment/Payment";
import CreateDiscussionTemplate from "../modules/decisions/views/CreateDiscussionTemplate";
import TestTemplate from "../modules/decisions/views/TestTemplate";
const Routes = () => {
  return (
    <Switch>
      <Route path="/" exact component={ValidateWorkspace} />
      <Route path="/login" exact component={LoginValidate} />
      <Route path="/forget-password" exact component={ForgetPassword} />
      <Route path="/find-workspace" exact component={FindWorkspace} />
      <Route
        path="/invite-member/:token"
        exact
        component={ResetPasswordFromInvite}
      />
      <Route path="/reset-password/:token" component={ResetPassword} />
      <Route path="/get-started" exact component={SignupStart} />
      <Route path="/register-email" exact component={RegisterEmail} />
      <Route
        path="/validate-verification-code"
        exact
        component={ValidateVerificationCode}
      />
      <Route path="/create-workspace" exact component={CreateWorkspace} />
      <Route
        path="/invite-members-for-workspace"
        exact
        component={InviteMembersForWorkspace}
      />
      <Route path="/welcome-dashboard" exact component={WelcomeSignup} />

      <Route path="/dashboard" exact component={ListDecisions} />
      <Route path="/decision/create" exact component={CreateDecisionTemplate} />
      <Route
        path="/discussion/create"
        exact
        component={CreateDiscussionTemplate}
      />
      <Route
        path="/decision/update/:id"
        exact
        component={EditDecisionTemplate}
      />
      <Route
        path="/decision/member/:id"
        exact
        component={CreateDecisionDetailsMember}
      />
      <Route
        path="/decision/coordinator/:id"
        exact
        component={CreateDecisionDetailsCoordinator}
      />
      <Route path="/decision/view/:id" exact component={ViewDecisionDetails} />
      <Route path="/account/profile" exact component={Profile} />
      <Route path="/account/change-password" exact component={ChangePassword} />
      <Route path="/account/invite-members" exact component={InviteMembers} />
      <Route path="/account/notifications" exact component={Notifications} />
      <Route path="/account/integrations" exact component={Integrations} />
      <Route path="/account/slack-connect" exact component={SlackConnect} />
      <Route path="/account/upgrade-plan" exact component={Plans} />
      <Route path="/account/payment/:id" exact component={Payment} />
      <Route path="/analytics" exact component={Analytics} />
      <Route path="/test" exact component={TestTemplate} />

      <Route
        path="/account/organization-settting"
        exact
        component={OrganizationSetttings}
      />
      <Route path="**" component={PageNotFound} />
    </Switch>
  );
};

export default Routes;
