import React, { Component } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  Table,
  NavItem,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
} from "reactstrap";
import { Redirect, Link } from "react-router-dom";
import DropdownLink from "../dropdown-link/index";
import { connect } from "react-redux";
import {
  logout,
  validateWorkspace,
  googleLogout,
} from "../../modules/auth/store/loginActions";
import { defaultClientUrl } from "../../config/config";
import {
  scrollDataLimit,
  loginExceptRoutes,
  integrationFeatureType,
  analyticsFeatureType,
} from "../../constants";
import {
  getNotifications,
  getProfile,
  getFeatureFlags,
} from "../../modules/account/store/accountActions";
import {
  notifiedOn,
  objectToQueryParameters,
  splitHostname,
  formateTimeUTCToLocal,
} from "../../utilities/helpers";
import { startLoader, stopLoader } from "../../modules/web/store/loaderActions";
import { setDataToLocalstorage } from "../../modules/auth/store/signupActions";
import { warningAlert } from "../../modules/web/store/alertActions";
import "intro.js/introjs.css";

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      notificationOpen: false,
      dropdownOpen: false,
      elements: "",
    };
  }

  async componentDidMount() {
    this.props.startLoader();
    let params = new URL(window.location).searchParams;
    let emailAddress = params.get("emailAddress");
    let employeeId = params.get("employeeId");
    let workspaceId = params.get("workspaceId");
    let workspaceName = params.get("workspaceName");
    let token = params.get("token");
    if (emailAddress && employeeId && workspaceId && workspaceName && token) {
      let data = {
        token: token,
        emailAddress: emailAddress,
        workspaceName: workspaceName,
        employeeId: employeeId,
        workspaceId: workspaceId,
      };
      this.props.setDataToLocalstorage(data);
      setTimeout(async () => {
        await this.loadProfile();
        await this.loadFeatures();
        this.props.stopLoader();
        this.validateWorkspace();
        this.setState({
          elements: <Redirect key="dashboard" to={"/dashboard"} />,
        });
      }, 500);
    } else {
      if (this.props.token) {
        this.loadProfile();
        this.loadFeatures();
      }
      this.validateWorkspace();
    }
  }

  loadNotifications = () => {
    const payloadData = new FormData();
    payloadData.append("token", this.props.token);
    payloadData.append("employeeId", this.props.employeeId);
    payloadData.append("workspaceId", this.props.workspaceId);
    payloadData.append("offset", 0);
    payloadData.append("limit", scrollDataLimit);
    this.props.getNotifications(payloadData);
  };

  loadProfile = () => {
    let data = objectToQueryParameters({
      workspaceId: this.props.workspaceId,
      employeeId: this.props.employeeId,
      token: this.props.token,
    });
    this.props.getProfile(data);
  };

  loadFeatures = () => {
    const payloadData = new FormData();
    payloadData.append("token", this.props.token);
    payloadData.append("employeeId", this.props.employeeId);
    payloadData.append("workspaceId", this.props.workspaceId);
    this.props.loadFeatures(payloadData);
  };

  validateWorkspace = () => {
    let location = splitHostname();
    if (location.subdomain && window.location.origin !== defaultClientUrl) {
      this.props.startLoader();
      const payloadData = new FormData();
      payloadData.append("name", location.subdomain.split(".")[0]);
      this.props
        .validateWorkspace(payloadData)
        .then(async () => {
          let path = window.location.pathname;
          let routes = true;
          await loginExceptRoutes.map((str) => {
            if (path.indexOf(str) !== -1) {
              routes = false;
            }
            return true;
          });
          if (
            this.props.workspaceId !== localStorage.getItem("workspaceId") &&
            routes
          ) {
            this.setState({
              elements: <Redirect key="login" to={"/login"} />,
            });
          }
          this.props.stopLoader();
        })
        .catch((err) => {
          this.props.stopLoader();
          window.location = defaultClientUrl;
        });
    } else {
      this.props.stopLoader();
    }
  };

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  toggleDropdown = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  };

  toggleNotification = () => {
    if (!this.state.notificationOpen) {
      this.loadNotifications();
    }
    this.setState({
      notificationOpen: !this.state.notificationOpen,
    });
  };

  upgradeNotify = () => {
    this.props.warningAlert("Upgrade your plan to use this feature !");
  };

  logout = () => {
    this.props.logout();
    const payloadData = new FormData();
    payloadData.append("employeeId", this.props.employeeId);
    this.props.googleLogout(payloadData);
    window.location.href = "/";
  };

  onExit = () => {
    this.setState(() => ({ stepsEnabled: false }));
  };

  render() {
    let params = new URL(window.location).searchParams;
    let connects = params.get("connects");

    let integrationFeature = false;
    let analyticsFeature = false;
    this.props.features.map((feature) => {
      if (
        parseInt(feature.featureType) === integrationFeatureType &&
        parseInt(feature.isEnabled)
      ) {
        integrationFeature = true;
      }
      if (
        parseInt(feature.featureType) === analyticsFeatureType &&
        parseInt(feature.isEnabled)
      ) {
        analyticsFeature = true;
      }
      return true;
    });

    return (
      <React.Fragment>
        {this.state.elements}
        {this.props.isAuthenticated && !connects && (
          <header className="container custom-header">
            <Navbar light expand="md">
              <div className="navbar-brand">
                <a href={"/dashboard"} className="card-links">
                  <img
                    src={"/assets/consolve-logo.png"}
                    className="header-logo-img"
                    alt="consolve"
                  />
                </a>
              </div>
              <NavbarToggler onClick={this.toggle} />
              <Collapse isOpen={this.state.isOpen} navbar>
                <Nav className="ml-auto" navbar>
                  <NavItem className="notification-icon-li bellintro">
                    <Dropdown
                      className="notification-dropdown"
                      isOpen={this.state.notificationOpen}
                      toggle={this.toggleNotification}
                    >
                      <DropdownToggle tag="span">
                        <img
                          src={"/assets/notification.svg"}
                          alt="notification"
                          className="cursor-pointer notification-icon"
                          style={{display:'none'}}
                        />
                      </DropdownToggle>
                      <DropdownMenu className="profile-dropdown" right>
                        <DropdownItem header>
                          <Link
                            onClick={this.toggleNotification}
                            className="notification-title dropdown-links"
                            to="/account/notifications"
                          >
                            Notifications
                          </Link>
                        </DropdownItem>
                        <Table className="cursor-pointer" hover>
                          <tbody>
                            {this.props.notifications.map(
                              (notification, index) => {
                                return (
                                  <tr key={index}>
                                    <td>
                                      <Link
                                        onClick={this.toggleNotification}
                                        to={
                                          notification.decisionStatus ===
                                            "Finalized" ||
                                          notification.decisionStatus ===
                                            "Cancelled"
                                            ? "/decision/view/" +
                                              notification.decisionId
                                            : notification.decisionCoordinatorId ===
                                              this.props.employeeId
                                            ? "/decision/coordinator/" +
                                              notification.decisionId
                                            : notification.decisionIsApprover ===
                                                this.props.employeeId &&
                                              +notification.decisionApprovalSent
                                            ? "/decision/coordinator/" +
                                              notification.decisionId
                                            : notification.decisionIsMember ===
                                              this.props.employeeId
                                            ? "/decision/member/" +
                                              notification.decisionId
                                            : "/decision/view/" +
                                              notification.decisionId
                                        }
                                        className="dropdown-links"
                                      >
                                        <Row>
                                          <Col md={2}>
                                            <div
                                              style={{
                                                backgroundImage: `url(${
                                                  notification.photoUrl
                                                    ? notification.photoUrl
                                                    : "/assets/no-image.png"
                                                })`,
                                              }}
                                              className="not-img-cover"
                                            ></div>
                                          </Col>
                                          <Col md={10} className="not-div">
                                            <strong className="not-username">
                                              {
                                                notification.activityEmployeeName
                                              }
                                            </strong>{" "}
                                            {notification.activityString}
                                            <br />
                                            <span className="creator-text">
                                              {notifiedOn(
                                                formateTimeUTCToLocal(
                                                  notification.activityCreatedOn
                                                )
                                              )}
                                            </span>
                                          </Col>
                                        </Row>
                                      </Link>
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                          </tbody>
                        </Table>
                        <DropdownItem divider />
                        <DropdownItem header>
                          <Link
                            onClick={this.toggleNotification}
                            className="dropdown-links"
                            to="/account/notifications"
                          >
                            See all
                          </Link>
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </NavItem>
                  <NavItem className="w-50">
                    <Dropdown
                      className="user-accout"
                      isOpen={this.state.dropdownOpen}
                      toggle={this.toggleDropdown}
                    >
                      <DropdownToggle tag="span">
                        <img
                          className={
                            this.props.photo
                              ? "header-profile-image"
                              : "header-profile-image-default"
                          }
                          src={
                            this.props.photo
                              ? this.props.photo
                              : "/assets/no-image.png"
                          }
                          alt="user"
                        />
                        <img
                          className="float-right bottom-arrow"
                          src="/assets/arrow-down-1.svg"
                          alt="arrow"
                        />
                      </DropdownToggle>
                      <DropdownMenu className="profile-dropdown" right>
                        <DropdownLink
                          className="dropdown-links"
                          to="/account/profile"
                          onClick={this.toggleDropdown}
                        >
                          My Profile Settings
                        </DropdownLink>
                        {parseInt(this.props.profile.isAdmin) === 1 && (
                          <React.Fragment>
                            <DropdownLink
                              className="dropdown-links"
                              to="/account/organization-settting"
                              onClick={this.toggleDropdown}
                            >
                              Organization Settings
                            </DropdownLink>
                          </React.Fragment>
                        )}
                        {integrationFeature ? (
                          <DropdownLink
                            className="dropdown-links"
                            to="/account/integrations"
                            onClick={this.toggleDropdown}
                          >
                            Integrations
                          </DropdownLink>
                        ) : (
                          <span
                            className="dropdown-item disable-custom"
                            onClick={this.upgradeNotify}
                          >
                            Integrations
                          </span>
                        )}
                        {analyticsFeature ? (
                          <DropdownLink
                            className="dropdown-links"
                            to="/analytics"
                            onClick={this.toggleDropdown}
                          >
                            Analytics
                          </DropdownLink>
                        ) : (
                          <span
                            className="dropdown-item disable-custom"
                            onClick={this.upgradeNotify}
                          >
                            Analytics
                          </span>
                        )}
                        <DropdownLink
                          className="dropdown-links"
                          to="/account/invite-members"
                          onClick={this.toggleDropdown}
                        >
                          Invite Members
                        </DropdownLink>
                        {parseInt(this.props.profile.isAdmin) === 1 && (
                          <DropdownLink
                            className="dropdown-links"
                            to="/account/upgrade-plan"
                            onClick={this.toggleDropdown}
                          >
                            Upgrade Plan
                          </DropdownLink>
                        )}
                        <DropdownLink
                          className="dropdown-links"
                          to="/account/change-password"
                          onClick={this.toggleDropdown}
                        >
                          Change Password
                        </DropdownLink>

                        <DropdownItem onClick={this.logout}>
                          Logout
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </NavItem>
                </Nav>
              </Collapse>
            </Navbar>
          </header>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    photo: state.auth.photo,
    notifications: state.account.notifications,
    workspaceId: state.auth.workspaceId,
    employeeId: state.auth.employeeId,
    profile: state.account.profile,
    token: state.auth.token,
    features: state.account.features,
    loginWith: state.auth.loginWith,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    googleLogout: (data) => {
      return dispatch(googleLogout(data));
    },
    logout: (data) => {
      return dispatch(logout(data));
    },
    getNotifications: (data) => {
      return dispatch(getNotifications(data));
    },
    getProfile: (data) => {
      return dispatch(getProfile(data));
    },
    loadFeatures: (data) => {
      return dispatch(getFeatureFlags(data));
    },
    validateWorkspace: (data) => {
      return dispatch(validateWorkspace(data));
    },
    startLoader: () => {
      return dispatch(startLoader());
    },
    stopLoader: () => {
      return dispatch(stopLoader());
    },
    setDataToLocalstorage: (data) => {
      return dispatch(setDataToLocalstorage(data));
    },
    warningAlert: (data) => {
      return dispatch(warningAlert(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
