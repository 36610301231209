import {
  LOAD_DEPARTMENTS,
  GET_DEPARTMENTS_AND_EMPLOYEES,
  CREATE_DEPARTMENTS,
  ADD_EMPLOYEES_TO_DEPARTMENT,
  DELETE_EMPLOYEES_FROM_DEPARTMENT,
  DELETE_DEPARTMENTS
} from "./action-types";
import * as DepartmentService from "../../../services/DepartmentService";
import { successAction } from "../../../utilities/helpers";

export const loadDepartments = data => {
  return dispatch => {
    return DepartmentService.loadDepartments(data).then(response => {
      return dispatch(successAction(response.data, LOAD_DEPARTMENTS));
    });
  };
};

export const getDepartmentsAndEmployees = data => {
  return dispatch => {
    return DepartmentService.getDepartmentsAndEmployees(data).then(response => {
      return dispatch(
        successAction(response.data, GET_DEPARTMENTS_AND_EMPLOYEES)
      );
    });
  };
};

export const createDepartment = data => {
  return dispatch => {
    return DepartmentService.createDepartment(data).then(response => {
      return dispatch(successAction(response.data, CREATE_DEPARTMENTS));
    });
  };
};

export const deleteDepartment = data => {
  return dispatch => {
    return DepartmentService.deleteDepartment(data).then(response => {
      return dispatch(successAction(response.data, DELETE_DEPARTMENTS));
    });
  };
};

export const addEmployeesToDepartment = data => {
  return dispatch => {
    return DepartmentService.addEmployeesToDepartment(data).then(response => {
      return dispatch(
        successAction(response.data, ADD_EMPLOYEES_TO_DEPARTMENT)
      );
    });
  };
};

export const deleteEmployeesFromDepartment = data => {
  return dispatch => {
    return DepartmentService.deleteEmployeesFromDepartment(data).then(
      response => {
        return dispatch(
          successAction(response.data, DELETE_EMPLOYEES_FROM_DEPARTMENT)
        );
      }
    );
  };
};
