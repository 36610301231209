import { START_LOADING, STOP_LOADING } from "../action-types";

const initialState = false;

const loader = (state = initialState, action) => {
  switch (action.type) {
    case START_LOADING:
      return true;
    case STOP_LOADING:
      return false;
    default:
      return state;
  }
};

export default loader;
