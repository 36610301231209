import {
  SUCCESS_ALERT,
  ERROR_ALERT,
  RESET_ALERT,
  WARNING_ALERT
} from "./action-types";

export const successAlert = msg => ({
  type: SUCCESS_ALERT,
  msg
});

export const warningAlert = msg => ({
  type: WARNING_ALERT,
  msg
});

export const errorAlert = msg => ({
  type: ERROR_ALERT,
  msg
});

export const resetAlert = () => ({
  type: RESET_ALERT
});
