import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, FormGroup, Input, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { errorAlert, successAlert } from "../../web/store/alertActions";
import { inviteMembersForWorkspace } from "../../auth/store/signupActions";

class InviteMembers extends Component {
  constructor(props) {
    super(props);

    this.timeout = null;
    this.state = {
      emails: ["", "", ""],
      errors: [],
      currentInput: "",
    };
  }

  componentDidMount() {
    if (!this.props.workspaceId) {
      this.props.history.push("/");
    }
  }

  handleChange = async (e, index) => {
    let emails = [...this.state.emails];
    const name = e.target.name;
    const value = e.target.value;
    emails[index] = value;
    this.setState({ emails });
    this.setState({ currentInput: name });
    let errors = [...this.state.errors];
    errors[index] = "";
    this.setState({ errors });
  };

  add = () => {
    this.setState((prevState) => ({ emails: [...prevState.emails, ""] }));
  };

  remove = async (index) => {
    let emails = [...this.state.emails];
    emails.splice(index, 1);
    this.setState({ emails });

    let errors = [...this.state.errors];
    errors.splice(index, 1);
    await this.setState({ errors });

    await this.resetErrors(index);
  };

  setErrors = async (value, index) => {
    let errors = this.state.errors;
    if (value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
      if (errors[index]) errors.splice(index, 1);
    } else if (value === "") {
      errors[index] = "The email field is required.";
    } else {
      errors[index] = "The email field must be a valid email.";
    }
    await this.setState({ errors });
  };

  resetErrors = async (index) => {
    let errors = this.state.errors;
    await errors.map((error, eindex) => {
      if (eindex > index) {
        return this.moveErrors(errors, eindex, eindex - 1);
      }
      return error;
    });
  };

  moveErrors = async (errors, fromIndex, toIndex) => {
    let element = errors[fromIndex];
    errors.splice(fromIndex, 1);
    errors.splice(toIndex, 0, element);
    await this.setState({ errors });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    let validate = true;
    let emails = this.state.emails;
    let errors = this.state.errors;
    let payloadEmails = [];
    await emails.map((email, index) => {
      if (email === "") {
        if (emails.length > 1) {
          if (errors[index]) errors.splice(index, 1);
        } else {
          errors[index] = "The email field is required.";
          validate = false;
        }
      } else if (!email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
        errors[index] = "The email field must be a valid email.";
        validate = false;
      } else {
        payloadEmails.push(email);
        if (errors[index]) errors.splice(index, 1);
      }
      return errors;
    });
    await this.setState({ errors });

    if (payloadEmails.length && validate) {
      const payloadData = new FormData();
      payloadData.append("emailAddress", this.props.emailAddress);
      payloadData.append("employeeId", this.props.employeeId);
      payloadData.append("workspaceId", this.props.workspaceId);
      payloadData.append("workspaceName", this.props.workspaceName);
      payloadData.append("members", JSON.stringify(payloadEmails));
      this.props
        .inviteMembersForWorkspace(payloadData)
        .then(() => {
          this.props.successAlert("Members invited successfully.");
          this.props.history.push("/dashboard");
        })
        .catch((err) => {
          this.props.errorAlert("Something went wrong, please try again!");
        });
    }
  };

  createUI() {
    return this.state.emails.map((email, index) => {
      return (
        <Row key={index}>
          <Col md={6}>
            <FormGroup>
              <Input
                className={
                  this.state.emails.length === 1 ? "min-width-input" : ""
                }
                type="text"
                name={"email" + index}
                value={email}
                id={"email" + index}
                onChange={(e) => this.handleChange(e, index)}
                placeholder="name@example.com"
              />
              {this.state.errors[index] && (
                <label
                  id="name-error"
                  className="error"
                  htmlFor={"email" + index}
                >
                  {this.state.errors[index]}
                </label>
              )}
            </FormGroup>
          </Col>
          <Col
            md={6}
            className={
              this.state.emails.length - 1 !== index ? "close-cust" : ""
            }
          >
            {this.state.emails.length - 1 === index ? (
              <Button
                className="btn-custom btn-custom-create btn-custom-width-add"
                color="primary"
                onClick={this.add}
              >
                + &nbsp; Add
              </Button>
            ) : (
              <button
                className="link-button btn-custom btn-cust"
                href="#"
                type="button"
                onClick={() => this.remove(index)}
              >
                <img src={"/assets/cross.svg"} alt="X" />
              </button>
            )}
          </Col>
        </Row>
      );
    });
  }

  render() {
    return (
      <main className="create-main">
        <br />
        <div className="create-decision container">
          <br />
          <h5 className="page-title">Invite Members</h5>
          <br />
          <form onSubmit={this.handleSubmit}>
            {this.createUI()}
            <br />
            <Row>
              <Col md={4}></Col>
              <Col md={2}>
                <FormGroup>
                  <Button className="btn-custom btn-custom-create btn-custom-width">
                    Invite
                  </Button>
                </FormGroup>
              </Col>
              <Col md={2}>
                <FormGroup>
                  <Link to={"/dashboard"}>
                    <Button className="btn-custom btn-custom-create btn-custom-width">
                      Cancel
                    </Button>
                  </Link>
                </FormGroup>
              </Col>
            </Row>
            <br />
          </form>
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    emailAddress: state.auth.email,
    employeeId: state.auth.employeeId,
    workspaceId: state.auth.workspaceId,
    workspaceName: state.auth.workspaceName,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    inviteMembersForWorkspace: (data) => {
      return dispatch(inviteMembersForWorkspace(data));
    },
    successAlert: (data) => {
      return dispatch(successAlert(data));
    },
    errorAlert: (data) => {
      return dispatch(errorAlert(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InviteMembers);
