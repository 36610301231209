import React from "react";
import PropTypes from "prop-types";
import CKEditor from "@ckeditor/ckeditor5-react";
import InlineEditor from "@ckeditor/ckeditor5-build-inline";

class Editor extends React.PureComponent {
  static propTypes = {
    value: PropTypes.string,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    customProps: PropTypes.array,
  };

  static defaultProps = {
    value: "",
    customProps: [],
  };

  render() {
    return (
      <CKEditor
        data={this.props.value}
        editor={InlineEditor}
        onChange={(event, editor) =>
          this.props.onChange(
            editor.getData(),
            this.props.name,
            ...this.props.customProps
          )
        }
      />
    );
  }
}

export default Editor;
