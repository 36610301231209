import { postRequest } from "./RequestService";

export const validateWorkspace = (data) => {
  return postRequest("validateWorkspace.php", data);
};

export const login = (data) => {
  return postRequest("loginValidate.php", data);
};

export const googleLogout = (data) => {
  return postRequest("googleLogout.php", data);
};

export const forgetPassword = (data) => {
  return postRequest("forgetPassword.php", data);
};

export const resetPassword = (data) => {
  return postRequest("resetPassword.php", data);
};

export const findWorkspace = (data) => {
  return postRequest("sendWorkspacesByEmailId.php", data);
};

export const resetPasswordFromInvite = (data) => {
  return postRequest("resetPasswordFromInvite.php", data);
};
