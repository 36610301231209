import React from "react";
import PropTypes from "prop-types";

class HtmlRendor extends React.PureComponent {
  static propTypes = {
    value: PropTypes.string,
  };

  render() {
    return (
        <div dangerouslySetInnerHTML={{__html: this.props.value}}></div>
    );
  }
}

export default HtmlRendor;
