import { postRequest } from "./RequestService";

export const registerEmail = data => {
  return postRequest("registerEmail.php", data);
};

export const validateVerificationCode = data => {
  return postRequest("validateVerificationCode.php", data);
};

export const createWorkspace = data => {
  return postRequest("createWorkspace.php", data);
};

export const inviteMembersForWorkspace = data => {
  return postRequest("inviteMembersForWorkspace.php", data);
};
