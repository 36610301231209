import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Button,
  FormGroup,
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Input,
} from "reactstrap";
import { Link } from "react-router-dom";
import { inviteMembersForWorkspace } from "../../store/signupActions";
import { successAlert, errorAlert } from "../../../web/store/alertActions";
import { websiteUrl } from "../../../../config/config";

class InviteMembersForWorkspace extends Component {
  constructor(props) {
    super(props);

    this.timeout = null;
    this.state = {
      emails: ["", "", ""],
      errors: [],
      currentInput: "",
      isDisable: true,
    };
  }

  componentDidMount() {
    if (!this.props.employeeId) {
      this.props.history.push("/register-email");
    }
    if (this.props.isAuthenticated) {
      this.props.history.push("/dashboard");
    }
  }

  handleChange = async (e, index) => {
    let emails = [...this.state.emails];
    const name = e.target.name;
    const value = e.target.value;
    emails[index] = value;
    this.setState({ emails });
    this.setState({ currentInput: name });
    let errors = [...this.state.errors];
    errors[index] = "";
    this.setState({ errors });
  };

  add = () => {
    this.setState((prevState) => ({ emails: [...prevState.emails, ""] }));
    this.setDisabled();
  };

  remove = async (index) => {
    let emails = [...this.state.emails];
    emails.splice(index, 1);
    this.setState({ emails });

    let errors = [...this.state.errors];
    errors.splice(index, 1);
    await this.setState({ errors });

    await this.resetErrors(index);
    this.setDisabled();
  };

  setErrors = async (value, index) => {
    let errors = this.state.errors;
    if (value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
      if (errors[index]) errors.splice(index, 1);
    } else if (value === "") {
      errors[index] = "The email field is required.";
    } else {
      errors[index] = "The email field must be a valid email.";
    }
    await this.setState({ errors });
    this.setDisabled();
  };

  resetErrors = async (index) => {
    let errors = this.state.errors;
    await errors.map((error, eindex) => {
      if (eindex > index) {
        return this.moveErrors(errors, eindex, eindex - 1);
      }
      return error;
    });
  };

  moveErrors = async (errors, fromIndex, toIndex) => {
    let element = errors[fromIndex];
    errors.splice(fromIndex, 1);
    errors.splice(toIndex, 0, element);
    await this.setState({ errors });
    this.setDisabled();
  };

  setDisabled = async () => {
    await this.setState({ isDisable: this.state.errors.length > 0 });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    let validate = true;
    let emails = this.state.emails;
    let errors = this.state.errors;
    let payloadEmails = [];
    await emails.map((email, index) => {
      if (email === "") {
        if (emails.length > 1) {
          if (errors[index]) errors.splice(index, 1);
        } else {
          errors[index] = "The email field is required.";
          validate = false;
        }
      } else if (!email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
        errors[index] = "The email field must be a valid email.";
        validate = false;
      } else {
        payloadEmails.push(email);
        if (errors[index]) errors.splice(index, 1);
      }
      return errors;
    });
    await this.setState({ errors });
    if (payloadEmails.length && validate) {
      const payloadData = new FormData();
      payloadData.append("emailAddress", this.props.emailAddress);
      payloadData.append("employeeId", this.props.employeeId);
      payloadData.append("workspaceId", this.props.workspaceId);
      payloadData.append("workspaceName", this.props.workspaceName);
      payloadData.append("members", JSON.stringify(payloadEmails));
      this.props
        .inviteMembersForWorkspace(payloadData)
        .then(() => {
          this.props.history.push("/welcome-dashboard");
        })
        .catch((err) => {
          this.props.errorAlert("Something went wrong, please try again!");
        });
    }
  };

  createUI() {
    return this.state.emails.map((email, index) => {
      return (
        <FormGroup key={index}>
          <div className="divclass">
            <Input
              className={
                this.state.emails.length === 1 ? "min-width-input" : ""
              }
              type="text"
              name={"email" + index}
              value={email}
              id={"email" + index}
              onChange={(e) => this.handleChange(e, index)}
              placeholder="name@example.com"
            />
            {this.state.emails.length !== 1 && (
              <a
                className="btn-custom btn-cust"
                onClick={() => this.remove(index)}
                href="javascipt:"
              >
                <img src={"/assets/cross.svg"} alt="X" />
              </a>
            )}
          </div>

          {this.state.errors[index] && (
            <label id="name-error" className="error" htmlFor={"email" + index}>
              {this.state.errors[index]}
            </label>
          )}
        </FormGroup>
      );
    });
  }

  render() {
    return (
      <main>
        <Row className="split-screen"></Row>
        <Row>
          <Col sm="4"></Col>
          <Col sm="4" className="pd-10">
            <Row>
              <Col sm="4"></Col>
              <Col sm="4">
                <a href={websiteUrl} alt="website">
                  <img
                    src={"/assets/consolve-icon.png"}
                    alt="workspace"
                    className="logo-img w-50"
                  />
                </a>
              </Col>
              <Col sm="4"></Col>
            </Row>
          </Col>
          <Col sm="4"></Col>
        </Row>
        <br />
        <Row>
          <Col sm="4"></Col>
          <Col sm="4" className="pd-10">
            <Card className="color-bg">
              <CardBody>
                <CardTitle>Who else is working on this project?</CardTitle>
                <div className="main-card1">
                  <form onSubmit={this.handleSubmit}>
                    {this.createUI()}
                    <Button
                      className="btn-custom btn-another"
                      color="primary"
                      size="lg"
                      onClick={this.add}
                    >
                      + Add Another
                    </Button>
                    <Button
                      className="btn-custom btn-template"
                      color="primary"
                      size="lg"
                      block
                    >
                      Add Teammates
                    </Button>
                    <div className="links-div">
                      <Link
                        to={"/welcome-dashboard"}
                        className="card-links-second"
                      >
                        Skip for now
                      </Link>
                    </div>
                  </form>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col sm="4"></Col>
        </Row>
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    emailAddress: state.auth.email,
    employeeId: state.auth.employeeId,
    workspaceId: state.auth.workspaceId,
    workspaceName: state.auth.workspaceName,
    isAuthenticated: state.auth.isAuthenticated,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    inviteMembersForWorkspace: (data) => {
      return dispatch(inviteMembersForWorkspace(data));
    },
    successAlert: (data) => {
      return dispatch(successAlert(data));
    },
    errorAlert: (data) => {
      return dispatch(errorAlert(data));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InviteMembersForWorkspace);
