import React, { Component } from "react";
import './Editor.css'; 
import PropTypes from "prop-types";

class Editor extends Component {

  constructor(props) {
    super(props);
  }
  static propTypes = {
    decisionId: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    customProps: PropTypes.array,
  };
  
  componentDidMount() {
    //// Initialize Firebase.
    //// TODO: replace with your Firebase project configuration.
    var config = {
      apiKey: 'AIzaSyBYvOlMI_ANJMwLwEKyRKhhruGycv9O2vA',
      authDomain: "consolve-d7ac4.firebaseapp.com",
      databaseURL: "https://consolve-d7ac4.firebaseio.com"
    };
    window.firebase.initializeApp(config);
    //// Get Firebase Database reference.
    var decisionId = this.props.decisionId
    var firepadRef = this.getExampleRef(decisionId);
    //// Create CodeMirror (with lineWrapping on).
    var codeMirror = window.CodeMirror(document.getElementById('firepad-container'), { lineWrapping: true });
    //// Create Firepad (with rich text toolbar and shortcuts enabled).
    var firepad = window.Firepad.fromCodeMirror(firepadRef, codeMirror,
        { richTextToolbar: true, richTextShortcuts: true });
    //// Initialize contents.
    firepad.on('ready', function() { 
    });
    
    var self = this;
    firepad.on('synced', function(isSynced) {
      // isSynced will be false immediately after the user edits the pad,
      // and true when their edit has been saved to Firebase.
      if(isSynced) {
        self.handleOnChange(firepad);
      }
    });
  }

  handleOnChange = (firepadInput) => {
    this.props.onChange(
      firepadInput.getHtml(), 
      this.props.name
    )
  }
  // Helper to get hash from end of URL or generate a random one.
  getExampleRef(decisionId) {
    var ref = window.firebase.database().ref("decisions/" + decisionId);
    return ref;
  }

  render() {
    return (
        <div>
            <div id="firepad-container"></div>
        </div>
        
    );
  }
}
export default Editor;