import React from "react";
import PropTypes from "prop-types";
import Select, { components } from 'react-select';
import AvatarLabel from "../avatar-label";

class SelectRow extends React.Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired,
    valueField: PropTypes.string,
    nameField: PropTypes.string,
    defaultMenuHide: PropTypes.bool,
    avatar: PropTypes.bool,
    classes: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.object,
      PropTypes.array
    ]),
    multi: PropTypes.bool
  };

  static defaultProps = {
    nameField: "name",
    valueField: "id",
    classes: "",
    touched: false,
    multi: false,
    defaultMenuHide: false,
    avatar: false
  };

  constructor(props) {
    super(props);

    this.state = {
      openMenu: false
    };
  }

  handleChange = value => {
    this.setState({ openMenu: false });
    if (this.props.onChange) {
      this.props.onChange(
        this.props.name,
        value,
        this.props.valueField,
        this.props.multi
      );
    }
  };

  handleInputChange = (query, { action }) => {
    if (action === "input-change") {
      if (query !== "") {
        this.setState({ openMenu: true });
      } else {
        this.setState({ openMenu: false });
      }
    }
  };

  hideMenu = () => {
    this.setState({ openMenu: false });
  };

  render() {
    let IconOption = ''
    let ValueOption = ''
    let MultiValueOption = ''

    if (this.props.avatar) {
      const { Option, SingleValue, MultiValue } = components;
      IconOption = (props) => {
        return (
          <Option {...props}>
            <AvatarLabel avatar={props.data.photoUrl} label={props.children} />
          </Option>
        )
      };

      ValueOption = (props) => {
        return (
          <SingleValue {...props}>
            <AvatarLabel avatar={props.data.photoUrl} label={props.children} />
          </SingleValue> 
        )
      };

      MultiValueOption = (props) => {
        return (
          <MultiValue {...props}> 
            <AvatarLabel avatar={props.data.photoUrl} label={props.children} />
          </MultiValue> 
        )
      };
    }

    let defaultOptions = {}
    if (this.props.defaultMenuHide) {
      defaultOptions = {
        menuIsOpen: this.state.openMenu,
        onBlur: this.hideMenu
      }
    }

    return (
      <Select
        components={this.props.avatar ? { Option: IconOption, SingleValue: ValueOption, MultiValue: MultiValueOption } : ""}
        onBlurResetsInput={true}
        onSelectResetsInput={true}
        closeOnSelect={true}
        options={this.props.items}
        value={this.props.value}
        onChange={this.handleChange}
        getOptionLabel={option => `${option[this.props.nameField]}`}
        getOptionValue={option => `${option[this.props.valueField]}`}
        isClearable={true}
        simpleValue={true}
        isMulti={this.props.multi}
        onInputChange={this.handleInputChange}
        placeholder={""}
        className={this.props.classes}
        {...defaultOptions}
      />
    );
  }
}

export default SelectRow;
