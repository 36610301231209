import React from "react";
import PropTypes from "prop-types";

class AvatarLabel extends React.PureComponent {
  static propTypes = {
    label: PropTypes.string,
    avatar: PropTypes.string,
    labelIcon: PropTypes.string
  };

  static defaultProps = {
    label: "",
    avatar: "",
    labelIcon: ""
  };

  render() {
    return (
      <React.Fragment>
        <img
            className={"dropdown-avatar-image"}
            src={
            this.props.avatar
                ? this.props.avatar
                : "/assets/no-image-new.png"
            }
            alt={"user"}
        />
        {this.props.label}
        {
          this.props.labelIcon !== "" &&
          <img
            className={"label-icon-image"}
            src={"/assets/" + this.props.labelIcon}
            alt={"icon"}
          />
        }
      </React.Fragment>
    );
  }
}

export default AvatarLabel;
