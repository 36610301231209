import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Col,
  Table,
  Button,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import ReeValidate from "ree-validate";
import Employee from "./employees";
import { createDepartment } from "../../../departments/store/departmentActions";
import { successAlert, errorAlert } from "../../../web/store/alertActions";
import { contextLabel, defaultDepartment } from "../../../../constants";

class Department extends Component {
  constructor(props) {
    super(props);

    this.validator = new ReeValidate({
      name: "required",
    });

    this.state = {
      currentDepartment: 0,
      createModal: false,
      isLoaded: false,
      form: {
        name: "",
      },
      errors: this.validator.errors,
    };
  }

  componentDidMount() {
    this.props.onRef(this);
  }

  componentWillUnmount() {
    this.props.onRef(undefined);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setCurrentDepartment(props);
  }

  setCurrentDepartment = async (props) => {
    if (props.departments.length) {
      let checkCurrent = await props.departments.filter((depart) => {
        return depart.departmentId === this.state.currentDepartment;
      });
      if (!this.state.isLoaded || !checkCurrent.length) {
        this.updateCurrentSelection(props.departments[0].departmentId);
        this.setState({
          isLoaded: true,
        });
      }
    }
  };

  updateCurrentSelection = (id) => {
    this.setState({
      currentDepartment: id,
    });
  };

  createToggle = () => {
    this.setState({
      createModal: !this.state.createModal,
      form: {
        name: "",
      },
    });
    this.validator.reset();
  };

  handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    this.setState({
      form: { ...this.state.form, [name]: value },
    });
    this.validator.validate(name, value).then(() => {
      const { errors } = this.validator;
      this.setState({ errors });
    });
  };

  handleSubmit = () => {
    const { form } = this.state;
    const { errors } = this.validator;
    this.validator.validateAll(form).then((success) => {
      if (success) {
        const payloadData = new FormData();
        payloadData.append("token", this.props.token);
        payloadData.append("employeeId", this.props.employeeId);
        payloadData.append("workspaceId", this.props.workspaceId);
        payloadData.append("title", this.state.form.name);
        this.props
          .createDepartment(payloadData)
          .then((success) => {
            this.props.successAlert("Department created successfully.");
            this.createToggle();
            this.props.getDepartmentsAndEmployees();
          })
          .catch((e) => {
            this.props.errorAlert("Something went wrong, please try again!");
          });
      } else {
        this.setState({ errors });
      }
    });
  };

  render() {
    return (
      <React.Fragment>
        <Row>
          <Col md={6}>
            <h6 className="page-sub-title">Departments</h6>
            <div className="settings-div">
              <Table className="table-custom" responsive hover>
                <tbody>
                  {this.props.departments.map((department) => {
                    return (
                      <tr
                        className={
                          this.state.currentDepartment ===
                          department.departmentId
                            ? "active-table-row"
                            : ""
                        }
                        key={department.departmentId}
                      >
                        <td
                          onClick={() =>
                            this.updateCurrentSelection(department.departmentId)
                          }
                        >
                          {department.name}
                          {department.name !== defaultDepartment && (
                            <span
                              className="cursor-pointer float-right"
                              onClick={(e) =>
                                this.props.onContextMenuChange(
                                  e,
                                  department.departmentId,
                                  0,
                                  true
                                )
                              }
                            >
                              {contextLabel}
                            </span>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <br />
              <Row>
                <Col md={4}></Col>
                <Col md={5}>
                  <Button
                    onClick={this.createToggle}
                    className="btn-custom btn-custom-create btn-custom-width"
                  >
                    Create Department
                  </Button>
                </Col>
                <Col md={3}></Col>
              </Row>
              <br />
            </div>
          </Col>
          <Employee
            departmentId={this.state.currentDepartment}
            getDepartmentsAndEmployees={this.props.getDepartmentsAndEmployees}
            onContextMenuChange={this.props.onContextMenuChange}
          />
        </Row>
        <Modal isOpen={this.state.createModal} toggle={this.createToggle}>
          <ModalHeader toggle={this.createToggle}>
            Create Department
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label>Department Name</Label>
              <Input
                name="name"
                onChange={this.handleChange}
                value={this.state.form.name}
                type="text"
              />
              {this.state.errors.has("name") && (
                <label id="name-error" className="error" htmlFor="name">
                  {this.state.errors.first("name")}
                </label>
              )}
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              className="btn-custom btn-custom-create"
              onClick={this.handleSubmit}
            >
              Create
            </Button>{" "}
            <Button
              className="btn-custom btn-custom-create"
              onClick={this.createToggle}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    employeeId: state.auth.employeeId,
    workspaceId: state.auth.workspaceId,
    departments: state.departments,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createDepartment: (data) => {
      return dispatch(createDepartment(data));
    },
    successAlert: (data) => {
      return dispatch(successAlert(data));
    },
    errorAlert: (data) => {
      return dispatch(errorAlert(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Department);
