export const LOAD_DECISIONS = "LOAD_DECISIONS";
export const SEARCH_DECISIONS = "SEARCH_DECISIONS";
export const CREATE_DECISION_TEMPLATE = "CREATE_DECISION_TEMPLATE";
export const DELETE_DECISION_TEMPLATE = "DELETE_DECISION_TEMPLATE";
export const GET_DECISION_DETAILS = "GET_DECISION_DETAILS";
export const GET_DECISION = "GET_DECISION";
export const UPDATE_DECISION_DETAILS_MEMBER = "UPDATE_DECISION_DETAILS_MEMBER";
export const GET_COORDINATOR_DECISION_DETAILS =
  "GET_COORDINATOR_DECISION_DETAILS";
export const UPDATE_DECISION_DETAILS_COORDINATOR =
  "UPDATE_DECISION_DETAILS_COORDINATOR";
export const UPDATE_DECISION_DETAILS_APPROVER =
  "UPDATE_DECISION_DETAILS_APPROVER";

export const UPDATE_DECISION_TEMPLATE = "UPDATE_DECISION_TEMPLATE";
export const GET_COMMENTS_BY_ROW = "GET_COMMENTS_BY_ROW";
export const ADD_COMMENT = "ADD_COMMENT";
export const DELETE_COMMENT = "DELETE_COMMENT";
export const ADD_DECISION_ROW = "ADD_DECISION_ROW";
export const ADD_VOTE = "ADD_VOTE";
export const UPDATE_DECISION_ROW = "UPDATE_DECISION_ROW";
export const DELTE_DECISION_ROW = "DELTE_DECISION_ROW";
export const CREATE_ZOOM_MEETING = "CREATE_ZOOM_MEETING";

export const UPDATE_ONBOARDING = "UPDATE_ONBOARDING";
export const REJECT_DECISION = "REJECT_DECISION";
export const LOAD_DECISIONS_COUNT = "LOAD_DECISIONS_COUNT";
export const SWIPE_ROW_DECISION = "SWIPE_ROW_DECISION";
