import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Button,
  Table,
  FormGroup,
  Label,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Alert,
} from "reactstrap";
import { Link } from "react-router-dom";
import {
  getCoordinatorDecisionDetails,
  getCommentsByRow,
} from "../store/decisionActions";
import { errorAlert, successAlert } from "../../web/store/alertActions";
import {
  findElementByKey,
  formatedDate,
  generatePdfUrl,
  getStatusIdByName,
} from "../../../utilities/helpers";
import {
  decisionStatus,
  decisionRiskTypes,
  commentedFormate,
} from "../../../constants";
import moment from "moment";
import HtmlRendor from "../../../common/html-rendor";
import AvatarLabel from "../../../common/avatar-label";
import Avatar from "react-avatar";
import Tooltip from "react-simple-tooltip";

class ViewDecisionDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowComment: 0,
      exportOpen: false,
      tooltipOpen: "",
      quetooltipopen: false,
    };
  }

  componentDidMount() {
    if (this.props.token) {
      if (this.props.isValidate) this.getDecisionDetails();
    } else {
      this.props.history.push("/");
    }
  }

  getDecisionDetails = () => {
    const requestData = new FormData();
    requestData.append("decisionId", this.props.match.params.id);
    requestData.append("workspaceId", this.props.workspaceId);
    requestData.append("employeeId", this.props.employeeId);
    requestData.append("token", this.props.token);
    this.props.getDecisionDetails(requestData);
  };

  viewComment = (id) => {
    if (id === this.state.rowComment) {
      return true;
    }

    this.setState({
      rowComment: id,
    });

    this.getComments(id);
  };

  getComments = (id) => {
    this.setState({
      rowComment: id,
    });

    const payloadData = new FormData();
    payloadData.append("token", this.props.token);
    payloadData.append("employeeId", this.props.employeeId);
    payloadData.append("workspaceId", this.props.workspaceId);
    payloadData.append("rowNo", id);
    payloadData.append("decisionId", this.props.decision.decisionId);
    this.props.getCommentsByRow(payloadData);
  };

  exportToggleDropdown = () => {
    this.setState({
      exportOpen: !this.state.exportOpen,
    });
  };

  exportPdf = () => {
    var a = document.createElement("a");
    a.download = "decision-" + this.props.decision.decisionId + ".pdf";
    a.href = generatePdfUrl({
      decisionId: this.props.decision.decisionId,
      workspaceId: this.props.workspaceId,
      employeeId: this.props.employeeId,
    });
    a.target = "_blank";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  exportJson = () => {
    let filename = "decision-" + this.props.decision.decisionId + ".json";
    let contentType = "application/json;charset=utf-8;";

    var a = document.createElement("a");
    a.download = filename;
    a.href =
      "data:" +
      contentType +
      "," +
      encodeURIComponent(JSON.stringify(this.props.decision));
    a.target = "_blank";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  createTable = () => {
    let array = [];
    let table = [];
    let totalPoints = [];

    this.props.decision.decisionColumnDetails.map((detail) => {
      if (typeof totalPoints[detail.decisionColumnId] !== "undefined") {
        totalPoints[detail.decisionColumnId] += parseInt(detail.points);
      } else {
        totalPoints[detail.decisionColumnId] = parseInt(detail.points);
      }
      if (typeof array[detail.rowNo] !== "undefined") {
        return array[detail.rowNo].push({
          text: detail.detailText,
          points: detail.points,
          rowNo: detail.rowNo,
          columnDetailId: detail.columnDetailId,
          columnId: detail.decisionColumnId,
          employeeName: detail.employeeName,
          employeePhoto: detail.employeePhoto,
          vote: detail.vote,
          votername: detail.voterName,
        });
      } else {
        return (array[detail.rowNo] = [
          {
            text: detail.detailText,
            points: detail.points,
            rowNo: detail.rowNo,
            columnDetailId: detail.columnDetailId,
            columnId: detail.decisionColumnId,
            employeeName: detail.employeeName,
            employeePhoto: detail.employeePhoto,
            vote: detail.vote,
            votername: detail.voterName,
          },
        ]);
      }
    });

    array.map((detail, pindex) => {
      let children = [];
      let rowNo = 0;
      let employeeName = "";
      let vote = 0;
      let votername = "";
      let employeePhoto = "";
      detail.map((text, index) => {
        rowNo = text.rowNo;
        employeeName = text.employeeName;
        employeePhoto = text.employeePhoto;
        votername = text.votername;
        votername = votername.split(",");
        vote = text.vote;
        return children.push(
          <React.Fragment key={index}>
            <td>
              <label className="break-line-cust">
                <HtmlRendor value={text.text} />
              </label>
            </td>
            {this.props.decision.allowpoints === "1" ? (
              <td>
                <label>{text.points}</label>
              </td>
            ) : (
              " "
            )}
          </React.Fragment>
        );
      });
      let votertooltip = [];

      votername.map((name, index) => {
        let string =
          "<p key=" + index + ' className="voter-name" >' + name + "</p>";
        return votertooltip.push(string);
      });
      children.push(
        <td key={pindex + 210} className="action-td" align="right">
          <div className="colnew" onClick={() => this.viewComment(rowNo)}>
            <img
              className="comment-icon"
              src={
                this.props.decision.hasRowComments[rowNo]
                  ? "/assets/comment-dot.svg"
                  : "/assets/comment.svg"
              }
              alt="comment"
            />
          </div>
          <Tooltip
            content={this.stripHtml(votertooltip.join())}
            placement="left"
            background="#2c4a7c"
            border="#2c4a7c"
            fontSize="10px"
            padding="8"
          >
            <div className="colnew">
              <span className="pl-1">{vote > 0 && vote}</span>
            </div>
          </Tooltip>
          <Tooltip
            content={employeeName}
            placement="left"
            background="#2c4a7c"
            border="#2c4a7c"
            fontSize="10px"
            padding="8"
          >
            <div className="colnew" data-tip data-for={"tool_" + rowNo}>
              {employeePhoto.trim() !== "" && (
                <div className="avtar-image">
                  <AvatarLabel avatar={employeePhoto} />
                </div>
              )}
              {employeePhoto.trim() === "" && (
                <Avatar
                  name={employeeName}
                  size="20"
                  round="20px"
                  maxInitials={1}
                  color="#2c4a7c"
                  textSizeRatio={1.75}
                />
              )}
            </div>
          </Tooltip>
        </td>
      );
      return table.push(
        <React.Fragment key={pindex}>
          <tr
            className={
              this.state.rowComment === rowNo ? "active-table-row" : ""
            }
          >
            {children}
          </tr>
        </React.Fragment>
      );
    });

    table.push(
      <tr key={table.length + 500}>
        {this.props.decision.decisionColumns.map((column, index) => {
          return (
            <React.Fragment key={index}>
              {this.props.decision.allowpoints === "1" ? (
                <th>Total points</th>
              ) : (
                " "
              )}

              {this.props.decision.allowpoints === "1" ? (
                <th>{totalPoints[column.decisionColumnId]}</th>
              ) : (
                " "
              )}
            </React.Fragment>
          );
        })}
        {this.props.decision.allowpoints === "1" ? <th></th> : " "}
      </tr>
    );
    return table;
  };
  tooltipOpenHandle = (id = "") => {
    this.setState({ tooltipOpen: id });
  };

  quetooltipOpenHandle = () => {
    this.setState({ quetooltipopen: true });
  };

  quetooltipCloseHandle = () => {
    this.setState({ quetooltipopen: false });
  };

  stripHtml = (html) => {
    let temporalDivElement = document.createElement("div");
    // Set the HTML content with the providen
    temporalDivElement.innerHTML = html;
    // Retrieve the text property of the element (cross-browser support)
    return temporalDivElement.textContent || temporalDivElement.innerText || "";
  };

  createVoteTable = () => {
    let table = "";
    table = this.props.decision.decisionColumns.map((column, index) => {
      let voteCount = 0;
      return (
        <Table key={index} className="table-custom" responsive>
          <thead>
            <tr>
              <th>{column.title}</th>
            </tr>
          </thead>
          <tbody>
            {this.props.decision.membersVotes.map((vote, pindex) => {
              if (vote.columnId === column.decisionColumnId) {
                voteCount++;
                return (
                  <tr key={pindex + 200}>
                    <td>
                      <AvatarLabel
                        label={vote.displayName}
                        avatar={vote.photoUrl}
                      />
                    </td>
                  </tr>
                );
              } else {
                return false;
              }
            })}
            <tr>
              <td>
                <b className="float-left">Count: {voteCount}</b>
              </td>
            </tr>
          </tbody>
        </Table>
      );
    });
    return table;
  };

  render() {
    if (!this.props.decision || !this.props.decision.decisionColumns) {
      return (
        <main className="create-main">
          <div className="loader"></div>
        </main>
      );
    }

    return (
      <main className="create-main">
        <br />
        <div className="container">
          {parseInt(this.props.decision.decisionStatusId) !==
            getStatusIdByName("Finalized") &&
            this.props.decision.isDiscussion !== "1" &&
            parseInt(this.props.decision.decisionStatusId) !==
              getStatusIdByName("Cancelled") &&
            +this.props.decision.approverId === +this.props.employeeId && (
              <Alert color="success" style={{ textAlign: "center" }}>
                This decision is pending for send approval.
              </Alert>
            )}

          {parseInt(this.props.decision.decisionStatusId) !==
            getStatusIdByName("Finalized") &&
            this.props.decision.isDiscussion === "1" &&
            parseInt(this.props.decision.decisionStatusId) !==
              getStatusIdByName("Cancelled") &&
            +this.props.decision.approverId === +this.props.employeeId && (
              <Alert color="success" style={{ textAlign: "center" }}>
                This discussion is pending for send approval.
              </Alert>
            )}

          <div className="decision-detail-heading">
            <h5>{this.props.decision.title}</h5>
            <span>Deadline - {formatedDate(this.props.decision.dueDate)}  |  Finalized - {formatedDate(this.props.decision.updatedOn)}
            </span>
            <hr />
            <span>Template created by: {this.props.decision.creatorName}</span>
          </div>
          <hr />
          <Row className="decision-detail-first">
            <Col md={6}>
              <div className="decision-detail-heading decision-detail-heading-row">
                <h5>Driver</h5>
                <span>
                  <AvatarLabel
                    label={this.props.decision.coordinatorName}
                    avatar={this.props.decision.coordinatorPhoto}
                  />
                </span>
              </div>
            </Col>
            <Col md={6}>
              <div className="decision-detail-heading decision-detail-heading-row">
                <h5>Approvers</h5>
                <table>
                  <tbody>
                    {this.props.decision.approvers.map((approver, index) => {
                      let icon =
                        approver.approvalStatus !== "0"
                          ? approver.approvalStatus === "1"
                            ? "accept.svg"
                            : "reject.svg"
                          : "";
                      return (
                        <tr key={index}>
                          <td>
                            <AvatarLabel
                              label={approver.approverName}
                              labelIcon={icon}
                              avatar={approver.approverPhoto}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </Col>
          </Row>
          <Row className="custom-row members-line div-white-back">
            <Col md={12}>
              <h5 className="default-color">Meeting members</h5>
            </Col>
            {this.props.decision.members.map((member, index) => {
              if (!parseInt(member.isCoordinator)) {
                let text = "";
                if (index !== 0) {
                  text += ", ";
                }
                text += member.displayName;
                return text;
              } else {
                return "";
              }
            })}{" "}
          </Row>
          <br />
          {this.props.decision.description && (
            <Row className="decision-detail-anonymous custom-row div-white-back">
              <Col md={5}>
                {this.props.decision.isDiscussion === "1" && (
                  <h5 className="default-color"> Description</h5>
                )}
                {this.props.decision.isDiscussion !== "1" && (
                  <h5 className="default-color">Description</h5>
                )}
              </Col>
              <Col md={12}>
                <Label className="color-black">
                  <HtmlRendor value={this.props.decision.description} />
                </Label>
              </Col>
            </Row>
          )}
          <br />
          {this.props.decision.note && (
            <Row className="decision-detail-anonymous custom-row div-white-back">
              <Col md={5}>
                <h5 className="default-color">Collab note</h5>
              </Col>
              <Col md={12}>
                <Label className="color-black">
                  <HtmlRendor value={this.props.decision.note} />
                </Label>
              </Col>
            </Row>
          )}
          <Row className="custom-row">
            <h5 className="default-color pr-1">Findings</h5>
            {this.props.decision.allowpoints === "1" ? (
              <React.Fragment>
                <Tooltip
                  content={"Points range(0-10)"}
                  placement="right"
                  background="#2c4a7c"
                  border="#2c4a7c"
                  fontSize="10px"
                  padding="8"
                >
                  <img
                    src="/assets/questions.svg"
                    id={"tool_que"}
                    alt="question"
                    class="cursor-pointer question-icon mrm-4"
                    width="17px"
                  ></img>
                </Tooltip>
              </React.Fragment>
            ) : (
              " "
            )}
            <Col md={4}></Col>
          </Row>
          <Row className="decision-coord-detail-table">
            <Col md={this.state.rowComment ? 8 : 12} className="no-padding">
              <Table
                className="table-custom table-custom-details"
                responsive
                hover
              >
                <thead>
                  <tr>
                    {this.props.decision.decisionColumns.map(
                      (column, index) => {
                        return (
                          <React.Fragment key={index}>
                            <th>{column.title}</th>
                            {this.props.decision.allowpoints === "1" ? (
                              <th>Points</th>
                            ) : (
                              " "
                            )}
                          </React.Fragment>
                        );
                      }
                    )}
                    <th className="col-150px-fixed"></th>
                  </tr>
                </thead>
                <tbody>{this.createTable()}</tbody>
              </Table>
            </Col>
            {this.state.rowComment ? (
              <Col md={4} className="left-10-padding scroll-y">
                <Table className="table-custom table-custom-details" responsive>
                  <thead>
                    <tr>
                      <td>
                        Comments
                        <button
                          className="link-button btn-custom btn-cust float-right"
                          type="button"
                          onClick={() => this.viewComment(0)}
                        >
                          <img src={"/assets/cross.svg"} alt="X" />
                        </button>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.decision.comments &&
                      this.props.decision.comments.map((comment, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <span className="comment-text">
                                <HtmlRendor value={comment.comment} />
                              </span>
                              <br />
                              <i className="float-right creator-text">
                                {comment.displayName} -{" "}
                                {moment(comment.commentedOn).format(
                                  commentedFormate
                                )}
                              </i>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </Col>
            ) : (
              ""
            )}
          </Row>

          <br />
          {this.props.decision.allowTeamVote > 0 && (
            <React.Fragment>
              <Row className="custom-row">
                <Col md={12}>
                  <h5 className="default-color">Team member votes</h5>
                  <div className="vote-table">{this.createVoteTable()}</div>
                </Col>
              </Row>
            </React.Fragment>
          )}
          <br />
          {this.props.decision.anonymousFeedback > 0 && (
            <Row className="custom-row div-white-back">
              <Col md={5}>
                <h5 className="default-color">Team Anonymous Feedback</h5>
              </Col>
              <Col className="white-back members-line" md={12}>
                <Table className="table-custom" responsive>
                  <tbody>
                    {this.props.decision.feedbacks.map((feedback, index) => {
                      if (feedback.feedback) {
                        return (
                          <tr key={index}>
                            <td>
                              <HtmlRendor value={feedback.feedback} />
                            </td>
                          </tr>
                        );
                      } else {
                        return false;
                      }
                    })}
                  </tbody>
                </Table>
              </Col>
            </Row>
          )}
          <br />
          <Row className="decision-detail-anonymous custom-row div-white-back">
            <Col md={5}>
              {this.props.decision.isDiscussion === "1" && (
                <h5 className="default-color">Final Discussion</h5>
              )}
              {this.props.decision.isDiscussion !== "1" && (
                <h5 className="default-color">Final Decision</h5>
              )}
            </Col>
            <Col md={12}>
              <Label className="color-black">
                <HtmlRendor value={this.props.decision.finalDecisionText} />
              </Label>
            </Col>
          </Row>

          <Row className="decision-detail-anonymous div-white-back custom-row">
            <Col md={5}>
              {this.props.decision.isDiscussion === "1" && (
                <h5 className="default-color">Reason for Discussion/Notes</h5>
              )}
              {this.props.decision.isDiscussion !== "1" && (
                <h5 className="default-color">Reason for Decision/Notes</h5>
              )}
            </Col>
            <Col md={12}>
              <Label className="color-black">
                <HtmlRendor value={this.props.decision.finalDecisionReason} />
              </Label>
            </Col>
          </Row>

          <Row className="decision-detail-anonymous div-white-back custom-row">
            {this.props.decision.isDiscussion !== "1" && (
              <Col md={2}>
                  <h5 className="default-color">Decision Type</h5>
                <FormGroup>
                  <Label className="color-black">
                    {this.props.decision.decisionTypeString}
                  </Label>
                </FormGroup>
              </Col>
                )}
            {this.props.decision.isDiscussion !== "1" && (
              <Col md={1}></Col>
            )}
            <Col md={2}>
              {this.props.decision.isDiscussion === "1" && (
                <h5 className="default-color">Discussion Status</h5>
              )}
              {this.props.decision.isDiscussion !== "1" && (
                <h5 className="default-color">Decision Status</h5>
              )}
              <FormGroup>
                <Label className="color-black">
                  {decisionStatus.map((type) => {
                    let text = "";
                    if (
                      parseInt(this.props.decision.decisionStatusId) === type.id
                    ) {
                      text += type.name;
                    }
                    return text;
                  })}
                </Label>
              </FormGroup>
            </Col>
            <Col md={1}></Col>
            {this.props.decision.isDiscussion !== "1" && (
              <Col md={2}>
                <h5 className="default-color">Risk Analysis</h5>
                <FormGroup>
                  <Label className="color-black">
                    {decisionRiskTypes.map((type) => {
                      let text = "";
                      if (
                        parseInt(this.props.decision.decisionRiskTypeId) ===
                        type.id
                      ) {
                        text += type.name;
                      }
                      return text;
                    })}
                  </Label>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row className="decision-detail-button custom-row">
            <Col md={10}></Col>
            {parseInt(this.props.decision.decisionStatusId) ===
            getStatusIdByName("Finalized") ? (
              <Col md={1} className="left-btn-bottom">
                <FormGroup>
                  <Dropdown
                    isOpen={this.state.exportOpen}
                    toggle={this.exportToggleDropdown}
                  >
                    <DropdownToggle tag="span">
                      <Button className="btn-custom btn-custom-create btn-custom-width">
                        Export
                      </Button>
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem onClick={this.exportPdf}>
                        As Pdf
                      </DropdownItem>
                      <DropdownItem onClick={this.exportJson}>
                        As Json
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </FormGroup>
              </Col>
            ) : (
              <Col md={2}></Col>
            )}
            <Col md={1} className="right-btn-bottom">
              <FormGroup>
                <Link to={"/dashboard"}>
                  <Button className="btn-custom btn-custom-create btn-custom-width">
                    Close
                  </Button>
                </Link>
              </FormGroup>
            </Col>
          </Row>
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    decision: findElementByKey(
      state.decisions,
      "decisionId",
      ownProps.match.params.id
    ),
    workspaceId: state.auth.workspaceId,
    employeeId: state.auth.employeeId,
    token: state.auth.token,
    isValidate: state.auth.isValidate,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getDecisionDetails: (data) => {
      return dispatch(getCoordinatorDecisionDetails(data));
    },
    getCommentsByRow: (data) => {
      return dispatch(getCommentsByRow(data));
    },
    errorAlert: (data) => {
      return dispatch(errorAlert(data));
    },
    successAlert: (data) => {
      return dispatch(successAlert(data));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewDecisionDetails);
