import React, { Component } from "react";
import { Button, FormGroup, Table, Col } from "reactstrap";
import moment from "moment";
import { commentedFormate } from "../../../constants";
import Editor from "../../../common/editor";
import HtmlRendor from "../../../common/html-rendor";

class Comments extends Component {
  commentToggle = async () => {
    await this.props.commentToggle();
    setTimeout(() => {
      let element = document.getElementById("scrolled");
      element.scrollTop += element.offsetHeight;
    }, 50);
  };

  render() {
    return (
      <Col md={4} className="left-10-padding scroll-y scrolled">
        <div className="table-responsive" id="scrolled">
          <Table className="table-custom table-custom-details">
            <thead>
              <tr>
                <td>
                  Comments
                  <button
                    className="link-button btn-custom btn-cust float-right"
                    type="button"
                    onClick={() => this.props.viewComment(0)}
                  >
                    <img src={"/assets/cross.svg"} alt="X" />
                  </button>
                </td>
              </tr>
            </thead>
            <tbody>
              {this.props.decision.comments &&
                this.props.decision.comments.map((comment, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <span className="comment-text">
                          <HtmlRendor value={comment.comment} />
                        </span>
                        <br />
                        {comment.commentBy === this.props.employeeId && (
                          <button
                            className="link-button btn-custom btn-cust float-right"
                            type="button"
                            onClick={() =>
                              this.props.deleteComment(comment.commentId)
                            }
                          >
                            <img src={"/assets/cross.svg"} alt="X" />
                          </button>
                        )}
                        <i className="float-right creator-text">
                          {comment.displayName} -{" "}
                          {moment(comment.commentedOn).format(commentedFormate)}
                        </i>
                      </td>
                    </tr>
                  );
                })}
              <tr>
                <td>
                  {this.props.commentModal ? (
                    <React.Fragment>
                      <FormGroup>
                        <Editor
                          name={"comment"}
                          onChange={this.props.handleChange}
                          enableToolbar={false}
                        />
                        {this.props.errors.has("comment") && (
                          <label className="error" htmlFor="comment">
                            {this.props.errors.first("comment")}
                          </label>
                        )}
                      </FormGroup>
                      <FormGroup>
                        <Button
                          disabled={this.props.commentAddbtn}
                          className="btn-custom btn-custom-create"
                          onClick={this.props.addComment}
                        >
                          Add
                        </Button>{" "}
                        <Button
                          className="btn-custom btn-custom-create"
                          onClick={this.props.commentToggle}
                        >
                          Cancel
                        </Button>
                      </FormGroup>
                    </React.Fragment>
                  ) : (
                    <Button
                      className="btn-custom btn-circle btn-md add-comment-btn"
                      onClick={() => this.commentToggle()}
                    >
                      +
                    </Button>
                  )}
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </Col>
    );
  }
}

export default Comments;
