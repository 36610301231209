import {
  GET_DECISION_STATUS_COUNT,
  GET_DECISION_COUNT_BY_MONTH
} from "./action-types";

const initialState = {
  decisionStatusCount: [],
  decisionCountByMonth: []
};

const reducer = (state = initialState, { type, payload = null }) => {
  switch (type) {
    case GET_DECISION_STATUS_COUNT:
      return setDecisionStatusCount(state, payload);
    case GET_DECISION_COUNT_BY_MONTH:
      return setDecisionCountByMonth(state, payload);
    default:
      return state;
  }
};

const setDecisionStatusCount = (state, payload) => {
  return {
    ...state,
    decisionStatusCount: payload.data
  };
};

const setDecisionCountByMonth = (state, payload) => {
  return {
    ...state,
    decisionCountByMonth: payload.data
  };
};

export default reducer;
