export const GET_PROFILE = "GET_PROFILE";
export const SAVE_PROFILE = "SAVE_PROFILE";
export const GET_DEPARTMENTS_AND_EMPLOYEES = "GET_DEPARTMENTS_AND_EMPLOYEES";
export const UPLOAD_PROFILE_PIC = "UPLOAD_PROFILE_PIC";
export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const GET_ALL_INTEGRATIONS = "GET_ALL_INTEGRATIONS";
export const DISCONNECT_FROM_SLACK = "DISCONNECT_FROM_SLACK";
export const DISCONNECT_FROM_ZOOM = "DISCONNECT_FROM_ZOOM";
export const ASSIGN_ADMIN = "ASSIGN_ADMIN";
export const REMOVE_ADMIN = "REMOVE_ADMIN";
export const GET_FEATURE_FLAGS = "GET_FEATURE_FLAGS";
export const GET_WORKSPACE_PLANS = "GET_WORKSPACE_PLANS";
export const UPGRADE_WORKSPACE_PLAN = "UPGRADE_WORKSPACE_PLAN";
export const CANCEL_SUBSCRIPTION = "CANCEL_SUBSCRIPTION";
export const EXPORT_DATA = "EXPORT_DATA";
export const UPDATE_ONBOARDING = "UPDATE_ONBOARDING";
export const FEATURE_LOADED = "FEATURE_LOADED";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const GOOGLE_URL = "GOOGLE_URL";
export const EMP_STATUS_CHANGE = "EMP_STATUS_CHANGE";
